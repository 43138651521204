export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateInterval: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptAppointment = {
  __typename?: 'AcceptAppointment';
  /** Expiration date for accepting an appointment */
  acceptTill: Scalars['Date']['output'];
  /** Appointment comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** The date when the appointment has been created */
  createdAt: Scalars['Date']['output'];
  /** Appointment date */
  date?: Maybe<Scalars['Date']['output']>;
  /** ID of the appointment */
  id: Scalars['ID']['output'];
  /** True if appointment accepted */
  isAccepted: Scalars['Boolean']['output'];
  /** Appointment date */
  type: AppointmentType;
  /** The date when the appointment has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type AcceptLegalAgreement = {
  /** Whether is accepted */
  isAccepted: Scalars['Boolean']['input'];
  /** Type of the legal agreement */
  type: LegalAgreementType;
};

export type AcceptedRepairOfferResult = {
  __typename?: 'AcceptedRepairOfferResult';
  /** Bodyshop assigned to repair offer */
  bodyshop?: Maybe<Bodyshop>;
  /** Original repair price */
  offerPrice?: Maybe<Scalars['Float']['output']>;
  /** Current repair price */
  repairPrice?: Maybe<Scalars['Float']['output']>;
};

export enum AccessControlActionType {
  ADJUST = 'ADJUST',
  APPROVE = 'APPROVE',
  ASSIGN = 'ASSIGN',
  CANCEL = 'CANCEL',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CHANGE_VISIBILITY = 'CHANGE_VISIBILITY',
  CLOSE = 'CLOSE',
  COMPLETE = 'COMPLETE',
  CONFIRM = 'CONFIRM',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  EG_PROCESSING = 'EG_PROCESSING',
  EXPORT = 'EXPORT',
  INVOKE = 'INVOKE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  MANAGE = 'MANAGE',
  REPAIR = 'REPAIR',
  REQUEST = 'REQUEST',
  SEND = 'SEND',
  SEND_TO_DMS = 'SEND_TO_DMS',
  START = 'START',
  SYNC_TO_CARISMA = 'SYNC_TO_CARISMA',
  UPDATE = 'UPDATE',
  UPLOAD = 'UPLOAD',
  VERIFY = 'VERIFY',
  VIEW = 'VIEW'
}

export enum AccessControlResourceType {
  ACTIVITY = 'ACTIVITY',
  API_TOKEN = 'API_TOKEN',
  APPOINTMENT = 'APPOINTMENT',
  ASSESSMENT = 'ASSESSMENT',
  ASSESSMENT_REPORT = 'ASSESSMENT_REPORT',
  ASSESSOR = 'ASSESSOR',
  ASSESSOR_ORGANISATION = 'ASSESSOR_ORGANISATION',
  ASSESSOR_ORGANISATION_SUGGESTED = 'ASSESSOR_ORGANISATION_SUGGESTED',
  ASSIGNEE = 'ASSIGNEE',
  ASSIGNEE_LIST = 'ASSIGNEE_LIST',
  AUTH_LINK = 'AUTH_LINK',
  BODYSHOP = 'BODYSHOP',
  BODYSHOP_APP_APPOINTMENT = 'BODYSHOP_APP_APPOINTMENT',
  BODYSHOP_DETAILS = 'BODYSHOP_DETAILS',
  BODYSHOP_LIST = 'BODYSHOP_LIST',
  BODYSHOP_SUGGESTED = 'BODYSHOP_SUGGESTED',
  CAR = 'CAR',
  CAR_ACTIVE_STATE = 'CAR_ACTIVE_STATE',
  CAR_AVAILABILITY = 'CAR_AVAILABILITY',
  CAR_LIST = 'CAR_LIST',
  CAR_OVERVIEW_DAMAGE_LIST = 'CAR_OVERVIEW_DAMAGE_LIST',
  CHECKLIST_REPORT = 'CHECKLIST_REPORT',
  CHECKLIST_REPORT_LIST = 'CHECKLIST_REPORT_LIST',
  CHECKLIST_TEMPLATE = 'CHECKLIST_TEMPLATE',
  CHILD_BODYSHOP_LIST = 'CHILD_BODYSHOP_LIST',
  COMMENT = 'COMMENT',
  COST = 'COST',
  COST_LIST = 'COST_LIST',
  DAMAGE = 'DAMAGE',
  DAMAGE_CATEGORIES = 'DAMAGE_CATEGORIES',
  DAMAGE_DOCUMENT = 'DAMAGE_DOCUMENT',
  DAMAGE_IMAGE = 'DAMAGE_IMAGE',
  DAMAGE_IMAGE_LIST = 'DAMAGE_IMAGE_LIST',
  DAMAGE_LIST = 'DAMAGE_LIST',
  DAMAGE_REPORT_ACTION = 'DAMAGE_REPORT_ACTION',
  DAMAGE_REPORT_COMMENT = 'DAMAGE_REPORT_COMMENT',
  DAMAGE_REPORT_EXTERNAL_ID = 'DAMAGE_REPORT_EXTERNAL_ID',
  DAMAGE_REPORT_FLEET_REPAIR_PRICE = 'DAMAGE_REPORT_FLEET_REPAIR_PRICE',
  DAMAGE_REPORT_SHARED_LINK = 'DAMAGE_REPORT_SHARED_LINK',
  DAMAGE_REPORT_TYPE_SETTLED = 'DAMAGE_REPORT_TYPE_SETTLED',
  DASHBOARD = 'DASHBOARD',
  DEFAULT_MANAGER = 'DEFAULT_MANAGER',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  ESTIMATION = 'ESTIMATION',
  FLEET = 'FLEET',
  INSPECTION_ON_SITE = 'INSPECTION_ON_SITE',
  INSURANCE = 'INSURANCE',
  INSURANCE_LIST = 'INSURANCE_LIST',
  INVOICE = 'INVOICE',
  INVOICE_TYPES = 'INVOICE_TYPES',
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATION_APPOINTMENTS = 'NOTIFICATION_APPOINTMENTS',
  NOTIFICATION_CHECKLIST_REPORT = 'NOTIFICATION_CHECKLIST_REPORT',
  NOTIFICATION_DAMAGE_REPORT = 'NOTIFICATION_DAMAGE_REPORT',
  NOTIFICATION_INVOICE = 'NOTIFICATION_INVOICE',
  NOTIFICATION_OTHERS = 'NOTIFICATION_OTHERS',
  NOTIFICATION_REPAIR_OFFER = 'NOTIFICATION_REPAIR_OFFER',
  PDF_REPORT = 'PDF_REPORT',
  PORTAL = 'PORTAL',
  PRE_INSPECTION_DAMAGE_REPORT = 'PRE_INSPECTION_DAMAGE_REPORT',
  PRE_INSPECTION_LINK = 'PRE_INSPECTION_LINK',
  RELATED_DAMAGES = 'RELATED_DAMAGES',
  REPAIR_OFFER = 'REPAIR_OFFER',
  REPAIR_OFFER_ADJUSTMENT = 'REPAIR_OFFER_ADJUSTMENT',
  REPAIR_OFFER_APPOINTMENT = 'REPAIR_OFFER_APPOINTMENT',
  REPAIR_OFFER_MECHANICAL = 'REPAIR_OFFER_MECHANICAL',
  SERVICE = 'SERVICE',
  SERVICE_APPOINTMENT = 'SERVICE_APPOINTMENT',
  SERVICE_CAR = 'SERVICE_CAR',
  SERVICE_INVOICE = 'SERVICE_INVOICE',
  SERVICE_LIST = 'SERVICE_LIST',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  STEERER = 'STEERER',
  STEERER_LIST = 'STEERER_LIST',
  TAG = 'TAG',
  TASK = 'TASK',
  THEME = 'THEME',
  USER = 'USER',
  USER_LIST = 'USER_LIST'
}

/** Type of the accident damage reason */
export enum AccidentDamageReasons {
  ANIMAL_COLLISION = 'ANIMAL_COLLISION',
  DRIVING_OFF_THE_ROAD = 'DRIVING_OFF_THE_ROAD',
  ENVIRONMENT = 'ENVIRONMENT',
  FIXED_OBJECT_COLLISION = 'FIXED_OBJECT_COLLISION',
  OTHER = 'OTHER',
  OVERTAKING_LANE_CHANGE = 'OVERTAKING_LANE_CHANGE',
  PARKED_CAR_COLLISION = 'PARKED_CAR_COLLISION',
  PERSON_COLLISION = 'PERSON_COLLISION',
  RED_LIGHT_STOP_SIGN = 'RED_LIGHT_STOP_SIGN',
  REVERSING_MANEUVERING = 'REVERSING_MANEUVERING',
  RUNNING_OVER = 'RUNNING_OVER',
  THEFT = 'THEFT',
  TURNING = 'TURNING',
  VANDALISM = 'VANDALISM'
}

export type AccidentDriverInformationConfiguration = {
  __typename?: 'AccidentDriverInformationConfiguration';
  /** Language messages */
  ar?: Maybe<Scalars['JSONObject']['output']>;
};

export type AccidentDriverInformationConfigurationInput = {
  /** Defined whether the accident driver information questions enabled or not */
  isQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccidentModuleOverviewScreenConfiguration = {
  __typename?: 'AccidentModuleOverviewScreenConfiguration';
  /** Overview screen translated messages */
  translations?: Maybe<Scalars['JSONObject']['output']>;
};

export type AccidentModuleQuestions = {
  __typename?: 'AccidentModuleQuestions';
  /** Did driver consume alcoholic beverages or intoxicating substances in the last 24 hours before accident? */
  alcoholBeveragesConsumes?: Maybe<Scalars['Boolean']['output']>;
  /** Alcohol concetration in case an alcohol test was taken */
  alcoholConcentration?: Maybe<Scalars['Boolean']['output']>;
  /** Did driver leave the scene of an accident without permission? */
  leftAccidentScene?: Maybe<Scalars['Boolean']['output']>;
  /** A trip type */
  tripPurpose?: Maybe<Scalars['Boolean']['output']>;
  /** Does driver have a valid license? */
  validDriverLicense?: Maybe<Scalars['Boolean']['output']>;
  /** Was an alcohold or drug test performed? */
  wasAlcoholDrugTestPerformed?: Maybe<Scalars['Boolean']['output']>;
  /** Was car transferred to third party? */
  wasCarTransferredToThirdParty?: Maybe<Scalars['Boolean']['output']>;
};

export type AccidentQuestionsAnswers = {
  __typename?: 'AccidentQuestionsAnswers';
  /** Did driver consume alcoholic beverages or intoxicating substances in the last 24 hours before accident? */
  alcoholBeveragesConsumes?: Maybe<Scalars['Boolean']['output']>;
  /** Alcohol concetration in case an alcohol test was taken */
  alcoholConcentration?: Maybe<Scalars['Float']['output']>;
  /** Was a blood sample taken? */
  bloodSampleTaken?: Maybe<Scalars['Boolean']['output']>;
  /** Did driver leave the scene of an accident without permission? */
  leftAccidentScene?: Maybe<Scalars['Boolean']['output']>;
  /** Transfer service provider in case if car was transfered to third party */
  transferServiceProvider?: Maybe<Scalars['String']['output']>;
  /** Business or personal trip? */
  tripPurpose?: Maybe<AccidentQuestionsTripTypeEnum>;
  /** Does driver have a valid license? */
  validDriverLicense?: Maybe<Scalars['Boolean']['output']>;
  /** Was an alcohold or drug test performed? */
  wasAlcoholDrugTestPerformed?: Maybe<Scalars['Boolean']['output']>;
  /** Was car transferred to third party? */
  wasCarTransferredToThirdParty?: Maybe<Scalars['Boolean']['output']>;
};

export enum AccidentQuestionsTripTypeEnum {
  BUSINESS = 'BUSINESS',
  COMMUTE = 'COMMUTE',
  PRIVATE = 'PRIVATE'
}

/** Type of the accident responsibility causer */
export enum AccidentResponsibilityCauser {
  BOTH_CAUSED_ACCIDENT = 'BOTH_CAUSED_ACCIDENT',
  DRIVER_CAUSED_ACCIDENT = 'DRIVER_CAUSED_ACCIDENT',
  OTHER_PARTY_CAUSED_ACCIDENT = 'OTHER_PARTY_CAUSED_ACCIDENT',
  RESPONSIBILITY_IS_NOT_CLEAR = 'RESPONSIBILITY_IS_NOT_CLEAR'
}

export type AccidentScreenConfiguration = {
  __typename?: 'AccidentScreenConfiguration';
  /** Defined whether the accident photo field mandatory or not */
  isMandatoryAccidentPhotoField?: Maybe<Scalars['Boolean']['output']>;
};

export type AccidentScreenConfigurationInput = {
  /** Defined whether the accident photo field mandatory or not */
  isMandatoryAccidentPhotoField?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccidentThirdParty = {
  __typename?: 'AccidentThirdParty';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insurance?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
};

export type AccidentThirdPartyInput = {
  /** Accident third party comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Accident third party insurance number */
  insurance?: InputMaybe<Scalars['String']['input']>;
  /** Accident third party insurance license plate */
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  /** Accident third party name */
  personName?: InputMaybe<Scalars['String']['input']>;
};

export type AccidentWithCarModuleConfiguration = {
  __typename?: 'AccidentWithCarModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Defines if the car prototype flow (page) is enabled */
  isPrototypeFlowEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Overview screen configuration */
  overview?: Maybe<AccidentModuleOverviewScreenConfiguration>;
  /** Defines what questions can be used during accident driver report */
  questions?: Maybe<AccidentModuleQuestions>;
};

export type AccidentWithoutCarModuleConfiguration = {
  __typename?: 'AccidentWithoutCarModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Defines if the car prototype flow (page) is enabled */
  isPrototypeFlowEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Overview screen configuration */
  overview?: Maybe<AccidentModuleOverviewScreenConfiguration>;
  /** Defines what questions can be used during accident driver report */
  questions?: Maybe<AccidentModuleQuestions>;
};

export type ActionResult = {
  __typename?: 'ActionResult';
  /** Action metadata */
  data: Scalars['JSONObject']['output'];
  /** Status of the action */
  status: Scalars['Boolean']['output'];
};

export type ActivityItem = {
  __typename?: 'ActivityItem';
  action: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  entityRefId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isAutomatical: Scalars['Boolean']['output'];
  transition?: Maybe<Scalars['JSONObject']['output']>;
  user?: Maybe<Scalars['JSONObject']['output']>;
};

export type ActivityList = {
  __typename?: 'ActivityList';
  items: Array<ActivityItem>;
  total: Scalars['Int']['output'];
};

export type ActivityPaginationInput = {
  /** Max number of results */
  limit: Scalars['Int']['input'];
  /** Page number */
  page: Scalars['Int']['input'];
};

export enum ActivitySortBy {
  CREATEDAT = 'CreatedAt'
}

export type ActivitySortInput = {
  /** Sort by property */
  sortBy: ActivitySortBy;
  /** Sort order property */
  sortOrder: SortOrder;
};

export type AddBodyshopInput = {
  /** Address city */
  addressCity: Scalars['String']['input'];
  /** Address house */
  addressHouse: Scalars['String']['input'];
  /** Address street */
  addressStreet: Scalars['String']['input'];
  /** Address zip */
  addressZip: Scalars['String']['input'];
  /** Contact person */
  contactPerson: Scalars['String']['input'];
  /** Damage report ID */
  damageReportId: Scalars['ID']['input'];
  /** Email */
  email: Scalars['String']['input'];
  /** Bodyshop partner ID */
  partnerId?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber: Scalars['String']['input'];
  /** Bodyshop title */
  title: Scalars['String']['input'];
  /** Web site URL */
  webSiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AddBodyshopResult = {
  __typename?: 'AddBodyshopResult';
  /** Onboarded bodyshop data */
  bodyshop?: Maybe<Bodyshop>;
  /** Status of onboarded bodyshop */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddCostInput = {
  /** Amount net */
  amountNet: Scalars['Float']['input'];
  /** Cost comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** A damage report id */
  damageReportId: Scalars['String']['input'];
  /** Is VAT included */
  isVatEnabled: Scalars['Boolean']['input'];
  /** Cost type */
  type: Scalars['String']['input'];
};

export type AddInsuranceInput = {
  /** List of insurances to add */
  list: Array<AddInsuranceItem>;
};

export type AddInsuranceItem = {
  /** Address city */
  addressCity?: InputMaybe<Scalars['String']['input']>;
  /** Address house */
  addressHouse?: InputMaybe<Scalars['String']['input']>;
  /** Address street */
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  /** Address zip */
  addressZip?: InputMaybe<Scalars['String']['input']>;
  /** Insurance name */
  name: Scalars['String']['input'];
  /** Insurance title */
  title: Scalars['String']['input'];
};

export type AddInsuranceResult = {
  __typename?: 'AddInsuranceResult';
  /** List of created insurances */
  companies?: Maybe<Array<Insurance>>;
  /** Status of created insurance */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddServiceCarInput = {
  /** Cars VIN */
  cars: Array<ServiceCarInput>;
  /** Service interval. ex. `1 year` */
  interval: Scalars['String']['input'];
  /** Service type */
  service: ServiceCarType;
};

export type AddTagInput = {
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Tag value */
  tags: Array<Scalars['String']['input']>;
};

export type AddThemeInput = {
  /** Tenant assets such as logo for different parts of application. Could be generated from the logo property */
  assets?: InputMaybe<Array<ThemeAssetUpload>>;
  /** Tenant logo. Could be the source for assets */
  logo?: InputMaybe<FileOrUrlUpload>;
  /** Theme properties */
  properties?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Tenant name */
  tenantName: Scalars['String']['input'];
};

export type AdditionalInformation = {
  /** Fleet manager comment */
  fleetComment?: InputMaybe<Scalars['String']['input']>;
  /** Current mileage */
  mileage?: InputMaybe<Scalars['String']['input']>;
  /** Mileage description */
  note?: InputMaybe<Scalars['String']['input']>;
};

export type AdditionalService = {
  __typename?: 'AdditionalService';
  /** Additional service detailed description */
  details?: Maybe<Scalars['String']['output']>;
  /** Additional service id */
  id: Scalars['ID']['output'];
  /** Is TRUE, when the driver has pre-selected this service while communicating with the steerer */
  isPreSelected: Scalars['Boolean']['output'];
  /** Whether an additional service is selected by driver */
  isSelected: Scalars['Boolean']['output'];
  /** Additional service price */
  price: Scalars['Float']['output'];
  /** Addtitional service type */
  type: AdditionalServiceType;
};

export type AdditionalServiceInput = {
  /** Additional service detailed description */
  details?: InputMaybe<Scalars['String']['input']>;
  /** Additional service Id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Additional service price */
  price: Scalars['Float']['input'];
  /** Additional service type */
  type: AdditionalServiceType;
};

export type AdditionalServiceItem = {
  /** Additional service id */
  id: Scalars['ID']['input'];
  /** Whether the additional service is selected */
  isSelected: Scalars['Boolean']['input'];
};

export enum AdditionalServiceType {
  CAR_REPLACEMENT = 'CAR_REPLACEMENT',
  CLEANING = 'CLEANING',
  PICK_UP = 'PICK_UP'
}

export type AdjustRepairOfferInput = {
  /** Adjustment price */
  price: Scalars['Float']['input'];
  /** Adjustment reason */
  reason: Scalars['String']['input'];
  /** Repair Offer ID */
  repairOfferId: Scalars['String']['input'];
};

export type Adjustment = {
  __typename?: 'Adjustment';
  /** The date when the asjusment has been created */
  createdAt: Scalars['Date']['output'];
  document: Scalars['String']['output'];
  /** Adjusted estimation document download url */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /** ID of the adjustment */
  id: Scalars['ID']['output'];
  /** Adjusted price */
  price?: Maybe<Scalars['Float']['output']>;
  /** Adjustment reason */
  reason: Scalars['String']['output'];
  /** Adjustment rejection reason */
  rejectReason?: Maybe<Scalars['String']['output']>;
  /** Adjustment status */
  status: AdjustmentStatus;
  /** The date when the asjusment has been updated */
  updatedAt: Scalars['Date']['output'];
};

export enum AdjustmentStatus {
  ADJUSTMENT_APPROVED = 'ADJUSTMENT_APPROVED',
  ADJUSTMENT_REJECTED = 'ADJUSTMENT_REJECTED',
  NEW = 'NEW'
}

/** Kind of aggregated document */
export enum AggregatedDocumentKind {
  ASSESSMENT_REPORT_DOCUMENT = 'ASSESSMENT_REPORT_DOCUMENT',
  DAMAGE_ACCIDENT_REPORT = 'DAMAGE_ACCIDENT_REPORT',
  DAMAGE_INVOICE = 'DAMAGE_INVOICE',
  DAMAGE_REPAIR_OFFER = 'DAMAGE_REPAIR_OFFER',
  DAMAGE_REPAIR_OFFER_ADJUSTMENT = 'DAMAGE_REPAIR_OFFER_ADJUSTMENT',
  DAMAGE_REPORT_DOCUMENT = 'DAMAGE_REPORT_DOCUMENT'
}

/** Type of all damage reasons */
export enum AllDamageReasons {
  ANIMAL_COLLISION = 'ANIMAL_COLLISION',
  CAR_COLLISION = 'CAR_COLLISION',
  DRIVING_OFF_THE_ROAD = 'DRIVING_OFF_THE_ROAD',
  ENVIRONMENT = 'ENVIRONMENT',
  FIXED_OBJECT_COLLISION = 'FIXED_OBJECT_COLLISION',
  LANE_CHANGE = 'LANE_CHANGE',
  OBJECT_COLLISION = 'OBJECT_COLLISION',
  OTHER = 'OTHER',
  OVERHAUL = 'OVERHAUL',
  OVERTAKING_LANE_CHANGE = 'OVERTAKING_LANE_CHANGE',
  PARKED_CAR_COLLISION = 'PARKED_CAR_COLLISION',
  PARKING_DAMAGE = 'PARKING_DAMAGE',
  PERSON_COLLISION = 'PERSON_COLLISION',
  REAR_END_COLLISION = 'REAR_END_COLLISION',
  RED_LIGHT_STOP_SIGN = 'RED_LIGHT_STOP_SIGN',
  REVERSING_MANEUVERING = 'REVERSING_MANEUVERING',
  RIGHT_OF_WAY_VIOLATION = 'RIGHT_OF_WAY_VIOLATION',
  ROADWAY_AGREEMENT = 'ROADWAY_AGREEMENT',
  RUNNING_OVER = 'RUNNING_OVER',
  STONE_CHIP = 'STONE_CHIP',
  THEFT = 'THEFT',
  TURNING = 'TURNING',
  VANDALISM = 'VANDALISM',
  WEATHER_DAMAGE = 'WEATHER_DAMAGE'
}

export enum AllowedCreateUserRoleType {
  ASSESSOR = 'ASSESSOR',
  BODYSHOP_CUSTOMER_CARE = 'BODYSHOP_CUSTOMER_CARE',
  DRIVER = 'DRIVER',
  FLEET_MANAGER = 'FLEET_MANAGER',
  LOCAL_FLEET_MANAGER = 'LOCAL_FLEET_MANAGER',
  RF_OPERATIONS = 'RF_OPERATIONS'
}

export type Answer = {
  __typename?: 'Answer';
  /** Answer body in Markdown format. */
  body: Scalars['String']['output'];
  /** ID of the answer */
  id: Scalars['String']['output'];
  /** Question of this answer. */
  question: Question;
  /** Answer translate key */
  translateKey: Scalars['String']['output'];
};

export type AppConfigTenant = {
  __typename?: 'AppConfigTenant';
  /** Tenant ID */
  id: Scalars['ID']['output'];
  /** Tenant name */
  name: Scalars['String']['output'];
};

export type AppConfiguration = {
  __typename?: 'AppConfiguration';
  /** Anyline license key */
  anylineToken?: Maybe<Scalars['String']['output']>;
  /** App customer conditions url site url */
  conditionsUrl?: Maybe<Scalars['String']['output']>;
  /** A default country code */
  countryCode: Scalars['String']['output'];
  /** App customer data protection url site url */
  dataProtectionUrl?: Maybe<Scalars['String']['output']>;
  /** Enabled features list for app */
  features: Array<AppFeature>;
  /** App customer impressum url site url */
  impressumUrl?: Maybe<Scalars['String']['output']>;
  /** Demo mode */
  isDemoMode?: Maybe<Scalars['Boolean']['output']>;
  /** App language */
  lang?: Maybe<Scalars['String']['output']>;
  /** App locales */
  locales?: Maybe<Array<Scalars['String']['output']>>;
  /** Custom locize project IDs */
  locizeProjectIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** The request source as defined by the server. Is not necessarily directly mapped to app names. */
  requestSource: DataSource;
  /** Apps screens configuration */
  screens?: Maybe<Array<ScreensOptions>>;
  /** The segment of app users */
  segment: AppSegmentType;
  /** The state of app */
  state: AppStateType;
  /** App tenant details */
  tenant: AppConfigTenant;
  /** Tracking information */
  trackingInfo?: Maybe<TrackingInfo>;
  /** App customer web site url */
  webSiteUrl?: Maybe<Scalars['String']['output']>;
};

export type AppFeature = {
  __typename?: 'AppFeature';
  /** Feature name */
  name: AppFeatureType;
  /** Feature options */
  options?: Maybe<Scalars['JSONObject']['output']>;
};

/** Type of the app feature */
export enum AppFeatureType {
  ACCIDENT_REPORT = 'ACCIDENT_REPORT',
  ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT = 'ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT',
  ADDITIONAL_SERVICES_DYNAMIC = 'ADDITIONAL_SERVICES_DYNAMIC',
  ADDITIONAL_SERVICES_STATIC = 'ADDITIONAL_SERVICES_STATIC',
  CAR_CHECK = 'CAR_CHECK',
  CAR_CONDITION = 'CAR_CONDITION',
  CAR_DOCUMENTS = 'CAR_DOCUMENTS',
  CAR_IDENTIFICATION = 'CAR_IDENTIFICATION',
  CAR_PARTS = 'CAR_PARTS',
  CHECKLIST = 'CHECKLIST',
  CONFIRMATION_PAGE = 'CONFIRMATION_PAGE',
  DAMAGE_PHOTOS = 'DAMAGE_PHOTOS',
  DAMAGE_REASONS = 'DAMAGE_REASONS',
  DRIVER_INFORMATION = 'DRIVER_INFORMATION',
  EXTERIOR_DAMAGE_REPORT = 'EXTERIOR_DAMAGE_REPORT',
  HOTLINE_FLOW = 'HOTLINE_FLOW',
  INTERIOR_DAMAGE_REPORT = 'INTERIOR_DAMAGE_REPORT',
  MANAGER_LOGIN = 'MANAGER_LOGIN',
  MECHANICAL_DAMAGE_REPORT = 'MECHANICAL_DAMAGE_REPORT',
  MILEAGE = 'MILEAGE',
  ONBOARDING = 'ONBOARDING',
  SERVICES = 'SERVICES',
  SERVICE_LIGHTS_REPORT = 'SERVICE_LIGHTS_REPORT',
  SIMPLE_ACCIDENT_REPORT = 'SIMPLE_ACCIDENT_REPORT',
  THIRD_PARTY_REPORT = 'THIRD_PARTY_REPORT'
}

/** App name */
export enum AppName {
  DRIVER_FACING_APP = 'DRIVER_FACING_APP',
  MANAGER_APP = 'MANAGER_APP'
}

/** Type of app users segment */
export enum AppSegmentType {
  BODYSHOPS = 'BODYSHOPS',
  FLEETS = 'FLEETS'
}

export type AppSettings = {
  __typename?: 'AppSettings';
  isHotline?: Maybe<Scalars['Boolean']['output']>;
  /** Fleet default language */
  lang?: Maybe<Scalars['String']['output']>;
  /** Fleet apps language list */
  locales?: Maybe<Array<Scalars['String']['output']>>;
  /** Locize custom project ID */
  locizeProjectId?: Maybe<Array<Scalars['ID']['output']>>;
};

export type AppSettingsInput = {
  isHotline?: InputMaybe<Scalars['Boolean']['input']>;
  /** Fleet default language */
  lang?: InputMaybe<BodyshopLangType>;
  /** Fleet apps language list */
  locales?: InputMaybe<Array<BodyshopLangType>>;
};

/** Type of the app feature */
export enum AppStateType {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DISABLED_APP = 'DISABLED_APP'
}

export type Appointment = {
  __typename?: 'Appointment';
  /** Appointment comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** The date when the appointment has been created */
  createdAt: Scalars['Date']['output'];
  /** Appointment date */
  date?: Maybe<Scalars['Date']['output']>;
  /** ID of the appointment */
  id: Scalars['ID']['output'];
  /** True if appointment accepted */
  isAccepted: Scalars['Boolean']['output'];
  /** Appointment date */
  type: AppointmentType;
  /** The date when the appointment has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type AppointmentSlot = {
  __typename?: 'AppointmentSlot';
  date: Scalars['Date']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  type: AppointmentSlotType;
};

export type AppointmentSlotInput = {
  date: Scalars['Date']['input'];
};

export enum AppointmentSlotType {
  ALTERNATIVE = 'ALTERNATIVE',
  INITIAL = 'INITIAL'
}

export enum AppointmentType {
  CAR_HANDOVER = 'CAR_HANDOVER',
  INSPECTION_ON_SITE = 'INSPECTION_ON_SITE'
}

export type Approval = {
  __typename?: 'Approval';
  /** The date when the approval has been requestapproved */
  approvedAt?: Maybe<Scalars['Date']['output']>;
  /** The date when the approval has been created */
  createdAt: Scalars['Date']['output'];
  /** ID of the repair offer */
  id: Scalars['ID']['output'];
  /** Shows if flow is blocking until approval is received */
  isMandatoryForRepair: Scalars['Boolean']['output'];
  /** The date when the approval has been requested */
  requestedAt?: Maybe<Scalars['Date']['output']>;
  /** Type of the approval */
  type: ApprovalType;
  /** The date when the approval has been updated */
  updatedAt: Scalars['Date']['output'];
};

export enum ApprovalType {
  FLEET = 'FLEET',
  INSURANCE = 'INSURANCE',
  LEASING = 'LEASING'
}

export type ApproveEstimationInput = {
  /** Approval boolean of the repair offer */
  approved: Scalars['Boolean']['input'];
  /** Rejection comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Repair Offer ID */
  id: Scalars['String']['input'];
};

export type AppsBodyshopConfiguration = {
  __typename?: 'AppsBodyshopConfiguration';
  /** Driver facing app configuration */
  DRIVER_FACING_APP?: Maybe<DriverFacingAppConfiguration>;
};

export type AppsBodyshopConfigurationInput = {
  /** Driver facing app configuration */
  DRIVER_FACING_APP?: InputMaybe<DriverFacingAppConfigurationInput>;
};

export type AppsFleetConfiguration = {
  __typename?: 'AppsFleetConfiguration';
  /** Driver facing app configuration */
  DRIVER_FACING_APP?: Maybe<DriverFacingAppConfiguration>;
  /** Fleet manager app configuration */
  MANAGER_APP?: Maybe<ManagerAppConfiguration>;
};

/** Position for the car part on the car. */
export enum Area {
  BACK = 'BACK',
  CABIN = 'CABIN',
  DRIVER_SIDE = 'DRIVER_SIDE',
  FRONT = 'FRONT',
  MECHANICAL = 'MECHANICAL',
  MIDDLE = 'MIDDLE',
  MISSING = 'MISSING',
  OTHER = 'OTHER',
  PASSENGER_SIDE = 'PASSENGER_SIDE',
  WHEEL_LEFT_FRONT = 'WHEEL_LEFT_FRONT',
  WHEEL_LEFT_REAR = 'WHEEL_LEFT_REAR',
  WHEEL_RIGHT_FRONT = 'WHEEL_RIGHT_FRONT',
  WHEEL_RIGHT_REAR = 'WHEEL_RIGHT_REAR'
}

export type Assessment = {
  __typename?: 'Assessment';
  /** Assessment request comment by FM/RF */
  assessor: UserInfoResult;
  /** Comment, which assessor leaves when provides assessment report */
  assessorComment?: Maybe<Scalars['String']['output']>;
  /** Images for the damage report */
  assessorOrganisation: AssessorOrganisation;
  /** Car location by FM/RF */
  carLocation: Scalars['String']['output'];
  /** ID of the assessment report */
  id: Scalars['ID']['output'];
  /** Repair cost estimation, which assessor leaves when provides assessment report */
  repairCostEstimation?: Maybe<Scalars['Float']['output']>;
  /** Assessment status */
  status: AssessmentStatus;
};

/** Status of damage report assessment */
export enum AssessmentStatus {
  APPROVED = 'APPROVED',
  PROVIDED = 'PROVIDED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

export type AssessorOrganisation = {
  __typename?: 'AssessorOrganisation';
  /** Assessor organisation address city */
  addressCity: Scalars['String']['output'];
  /** Assessor organisation address house */
  addressHouse: Scalars['String']['output'];
  /** Assessor organisation address street */
  addressStreet: Scalars['String']['output'];
  /** Assessor organisation address zip */
  addressZip: Scalars['String']['output'];
  /** Assessor organisation title */
  title: Scalars['String']['output'];
};

export type AssignUserInput = {
  /** Assignee action comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** User ID */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type AttachedDamageImage = {
  /** Damage report image id */
  id: Scalars['String']['input'];
  isVisibilityRestricted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum BodyShopServiceType {
  INSPECTION = 'INSPECTION',
  MECHANICAL = 'MECHANICAL',
  REPAIR = 'REPAIR'
}

export enum BodyStyle {
  COMBI = 'COMBI',
  COMPACT = 'COMPACT',
  COMPACT_E = 'COMPACT_E',
  CONVERTIBLE = 'CONVERTIBLE',
  FASTBACK = 'FASTBACK',
  HACKNEY = 'HACKNEY',
  HATCHBACK = 'HATCHBACK',
  LIGHT_TRUCK = 'LIGHT_TRUCK',
  MINIVAN = 'MINIVAN',
  OFF_ROAD = 'OFF_ROAD',
  OTHER = 'OTHER',
  ROADSTER = 'ROADSTER',
  SEDAN = 'SEDAN',
  STATION_WAGON = 'STATION_WAGON',
  SUBCOMPACT = 'SUBCOMPACT',
  SUV = 'SUV',
  TRANSPORTER = 'TRANSPORTER',
  VAN = 'VAN'
}

export type Bodyshop = {
  __typename?: 'Bodyshop';
  /** Bodyshop address city */
  addressCity: Scalars['String']['output'];
  /** Bodyshop address house */
  addressHouse: Scalars['String']['output'];
  /** Bodyshop address street */
  addressStreet: Scalars['String']['output'];
  /** Bodyshop address zip */
  addressZip: Scalars['String']['output'];
  /** Bodyshop configuration */
  configuration?: Maybe<Scalars['JSONObject']['output']>;
  /** Bodyshop contact person */
  contactPerson: Scalars['String']['output'];
  /** The date when the bodyshop has been created */
  createdAt: Scalars['Date']['output'];
  /** Bodyshop email */
  email: Scalars['String']['output'];
  /** Additional features supported by bodyshop */
  features?: Maybe<BodyshopFeatures>;
  /** ID of the bodyshop */
  id: Scalars['ID']['output'];
  /** Is bodyshpo active */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Bodyshop name */
  name: Scalars['String']['output'];
  /** Onboarding date */
  onboardingDate?: Maybe<Scalars['Date']['output']>;
  /** Bodyshop plan */
  package?: Maybe<BodyshopPackageType>;
  /** Parent bodyshop */
  parentBodyshop?: Maybe<Bodyshop>;
  /** Bodyshop partner ID */
  partnerId?: Maybe<Scalars['String']['output']>;
  /** Bodyshop phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** theme */
  theme?: Maybe<Theme>;
  /** Bodyshop title */
  title: Scalars['String']['output'];
  /** The date when the bodyshop has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type BodyshopAppAppointment = {
  __typename?: 'BodyshopAppAppointment';
  id: Scalars['ID']['output'];
  isAlternativeSlotSelected: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  slots: Array<AppointmentSlot>;
};

export type BodyshopConfiguration = {
  __typename?: 'BodyshopConfiguration';
  /** Available apps configuration */
  apps: AppsBodyshopConfiguration;
  /** Carisma related data */
  carismaData?: Maybe<CarismaData>;
  /** Conditions website */
  conditionsUrl?: Maybe<Scalars['String']['output']>;
  /** A default bodyshop country code (can be "auto" or a two-character code) */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** Data protection website */
  dataProtectionUrl?: Maybe<Scalars['String']['output']>;
  /** Dealer Management System */
  dmsSystem?: Maybe<DealerManagementSystem>;
  /** impressum website */
  impressumUrl?: Maybe<Scalars['String']['output']>;
  /** Whether the Digital Reception is enabled */
  isDigitalReception?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the Portal login is enabled */
  isFleetProcessing?: Maybe<Scalars['Boolean']['output']>;
  /** Enables the damage location feature */
  isGeolocationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the bodyshop uses parent domain */
  isParentBodyshopDomain?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the Preinspection flow is enabled */
  isPreinspection?: Maybe<Scalars['Boolean']['output']>;
  /** Default bodyshop language */
  lang?: Maybe<Scalars['String']['output']>;
  /** Bodyshop apps language list */
  locales?: Maybe<Array<Scalars['String']['output']>>;
  /** Custom locize project ID */
  locizeProjectId?: Maybe<Scalars['String']['output']>;
  /** Modules specific configuration for bodyshop */
  modules: BodyshopModulesConfiguration;
  /** Process Manager Auth */
  pmAuth?: Maybe<PmAuth>;
  /** Bodyshop website */
  webSiteUrl?: Maybe<Scalars['String']['output']>;
};

export type BodyshopConfigurationInput = {
  /** Available apps configuration */
  apps?: InputMaybe<AppsBodyshopConfigurationInput>;
  /** Carisma related data */
  carismaData?: InputMaybe<CarismaDataInput>;
  /** Conditions website */
  conditionsUrl?: InputMaybe<Scalars['String']['input']>;
  /** Data protection website */
  dataProtectionUrl?: InputMaybe<Scalars['String']['input']>;
  /** Dealer Management System */
  dmsSystem?: InputMaybe<DealerManagementSystem>;
  /** impressum website */
  impressumUrl?: InputMaybe<Scalars['String']['input']>;
  /** Whether the Digital Reception is enabled */
  isDigitalReception?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the Portal login is enabled */
  isFleetProcessing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the bodyshop uses parent domain */
  isParentBodyshopDomain?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the Preinspection flow is enabled */
  isPreinspection?: InputMaybe<Scalars['Boolean']['input']>;
  /** Bodyshop desired language */
  lang?: InputMaybe<BodyshopLangType>;
  /** Bodyshop apps language list */
  locales?: InputMaybe<Array<BodyshopLangType>>;
  /** Modules specific configuration for bodyshop */
  modules?: InputMaybe<BodyshopModulesConfigurationInput>;
  /** Process Manager Auth */
  pmAuth?: InputMaybe<ProcessManagerAuthInput>;
  /** Bodyshop email address */
  webSiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BodyshopDetails = {
  __typename?: 'BodyshopDetails';
  /** Bodyshop address city */
  addressCity: Scalars['String']['output'];
  /** Bodyshop address house */
  addressHouse: Scalars['String']['output'];
  /** Bodyshop address street */
  addressStreet: Scalars['String']['output'];
  /** Bodyshop address zip */
  addressZip: Scalars['String']['output'];
  /** Configuration */
  configuration?: Maybe<BodyshopConfiguration>;
  /** Bodyshop contact person */
  contactPerson: Scalars['String']['output'];
  /** The date when the bodyshop has been created */
  createdAt: Scalars['Date']['output'];
  /** Bodyshop email */
  email: Scalars['String']['output'];
  /** Additional features supported by bodyshop */
  features?: Maybe<BodyshopFeatures>;
  /** ID of the bodyshop */
  id: Scalars['ID']['output'];
  /** Is bodyshpo active */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Bodyshop name */
  name: Scalars['String']['output'];
  /** Onboarding date */
  onboardingDate?: Maybe<Scalars['Date']['output']>;
  /** Bodyshop plan */
  package?: Maybe<BodyshopPackageType>;
  /** Parent bodyshop */
  parentBodyshop?: Maybe<BodyshopDetails>;
  /** Bodyshop phone number */
  phoneNumber: Scalars['String']['output'];
  /** Static link */
  staticUrl?: Maybe<Scalars['String']['output']>;
  /** The steerers */
  steerers: Array<Steerer>;
  /** theme */
  theme?: Maybe<Theme>;
  /** Bodyshop title */
  title: Scalars['String']['output'];
  /** The date when the bodyshop has been updated */
  updatedAt: Scalars['Date']['output'];
  /** User info */
  users?: Maybe<Array<UserInfoResult>>;
};

export type BodyshopEmailAvailable = {
  __typename?: 'BodyshopEmailAvailable';
  /** Is email Available */
  emailAvailable: Scalars['Boolean']['output'];
};

export type BodyshopFeatures = {
  __typename?: 'BodyshopFeatures';
  hasSteerers: Scalars['Boolean']['output'];
  isFleetProcessing: Scalars['Boolean']['output'];
  isFromDPN: Scalars['Boolean']['output'];
  isSendDocumentsModuleEnabled: Scalars['Boolean']['output'];
};

export type BodyshopItem = {
  /** Bodyshop name */
  bodyshopName: Scalars['String']['input'];
  /** Location name (city) */
  location: Scalars['String']['input'];
  /** Service type */
  serviceType?: BodyShopServiceType;
};

export enum BodyshopLangType {
  DE = 'DE',
  EN = 'EN'
}

export type BodyshopList = {
  __typename?: 'BodyshopList';
  /** List of bodyshops */
  bodyshops: Array<Bodyshop>;
  /** Bodyshop count */
  total: Scalars['Int']['output'];
};

export type BodyshopModulesConfiguration = {
  __typename?: 'BodyshopModulesConfiguration';
  /** Send documents module configuration */
  SEND_DOCUMENTS: CommonBodyshopModuleConfiguration;
  /** Car services module configuration */
  SERVICES: CommonBodyshopModuleConfiguration;
};

export type BodyshopModulesConfigurationInput = {
  /** Send documents module configuration */
  SEND_DOCUMENTS?: InputMaybe<CommonBodyshopModuleConfigurationInput>;
  /** Car services module configuration */
  SERVICES?: InputMaybe<CommonBodyshopModuleConfigurationInput>;
};

export enum BodyshopPackageType {
  BASIC = 'BASIC',
  PLUS = 'PLUS',
  PREMIUM = 'PREMIUM'
}

export type BodyshopUser = {
  /** User email */
  email: Scalars['String']['input'];
  /** User first name */
  firstName: Scalars['String']['input'];
  /** User ID */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Is notifications enabled */
  isNotificationsEnabled?: Scalars['Boolean']['input'];
  /** User last name */
  lastName: Scalars['String']['input'];
  /** User password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** User phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** User role */
  role: Scalars['String']['input'];
  /** Trigger invitation email */
  triggerInvitationEmail?: Scalars['Boolean']['input'];
  /** User visibility scope */
  visibilityScope?: InputMaybe<Array<UserVisibilityScope>>;
};

export type BodyshopsCountResult = {
  __typename?: 'BodyshopsCountResult';
  /** The number of active bodyshops */
  active: Scalars['Int']['output'];
  /** The number of bodyshops */
  all: Scalars['Int']['output'];
  /** The number of disabled bodyshops */
  disabled: Scalars['Int']['output'];
};

export type CancelRepairOfferInput = {
  /** Repair Offer ID */
  id: Scalars['String']['input'];
  /** Cancellation reason */
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type Car = {
  __typename?: 'Car';
  /** Car body style */
  bodyStyle?: Maybe<BodyStyle>;
  /** Damage reports for the car which has **only NEW** status. */
  damageReports: Array<DamageReport>;
  /** First registration. */
  firstRegistration?: Maybe<Scalars['String']['output']>;
  /** ID of the car. */
  id: Scalars['ID']['output'];
  /** Is car pool. */
  isPool?: Maybe<Scalars['Boolean']['output']>;
  /** License plate number of the car. */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Current location. */
  location?: Maybe<Scalars['String']['output']>;
  /** Make name. */
  make: Scalars['String']['output'];
  /** Model name. */
  model: Scalars['String']['output'];
};

export enum CarAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  PARTIALLY_AVAILABLE = 'PARTIALLY_AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum CarByFleetSearchBy {
  LICENSEPLATE = 'LicensePlate',
  VIN = 'VIN'
}

export type CarDocument = {
  __typename?: 'CarDocument';
  /** The date that the car document has been created. */
  createdAt: Scalars['Date']['output'];
  /** Car document type */
  type: CarDocumentType;
  /** Car document url */
  url: Scalars['String']['output'];
};

export enum CarDocumentType {
  CAR_CHECK_REPORT = 'CAR_CHECK_REPORT',
  ESTIMATION_REPORT = 'ESTIMATION_REPORT',
  RECOGNITION_REPORT = 'RECOGNITION_REPORT'
}

export type CarIdentificationConfiguration = {
  __typename?: 'CarIdentificationConfiguration';
  /** Defines whether a car's identification step OCR enabled or not */
  isOCREnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type CarIdentificationConfigurationInput = {
  /** Defines whether a car's identification step OCR enabled or not */
  isOCREnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CarIdentificationModuleConfiguration = {
  __typename?: 'CarIdentificationModuleConfiguration';
  /** Defines whether a car's identification step OCR enabled or not */
  identificationMethods: Array<IdentificationMethod>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** A license plate Anyline license key */
  licensePlateAnylineToken?: Maybe<Scalars['String']['output']>;
  /** A VIN Anyline license key */
  vinAnylineToken?: Maybe<Scalars['String']['output']>;
};

export type CarInfo = {
  __typename?: 'CarInfo';
  /** Car body style */
  bodyStyle?: Maybe<BodyStyle>;
  /** First registration. */
  firstRegistration?: Maybe<Scalars['String']['output']>;
  /** ID of the car. */
  id: Scalars['ID']['output'];
  /** Is car pool. */
  isPool?: Maybe<Scalars['Boolean']['output']>;
  /** License plate number of the car. */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Current location. */
  location?: Maybe<Scalars['String']['output']>;
  /** Make name. */
  make: Scalars['String']['output'];
  /** Model name. */
  model: Scalars['String']['output'];
};

export type CarInfoInput = {
  bodyType: BodyStyle;
  carRegistrationCompany?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  insuranceCompany: Scalars['String']['input'];
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>;
  licensePlate: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type CarInsuranceInput = {
  /** Const center */
  costCenter?: InputMaybe<Scalars['String']['input']>;
  /** Deductible TK */
  deductibleTk: Scalars['Float']['input'];
  /** Deductible VK */
  deductibleVk: Scalars['Float']['input'];
  /** Brocker name */
  insuranceBroker?: InputMaybe<Scalars['String']['input']>;
  /** Insurance company name */
  insuranceCompany: Scalars['String']['input'];
  /** Insurance number */
  insuranceNumber: Scalars['String']['input'];
  /** Insuer */
  insurer?: InputMaybe<Scalars['String']['input']>;
};

export type CarLeasingInput = {
  /** Leasing comany name */
  leasingCompany?: InputMaybe<Scalars['String']['input']>;
  /** Leasing repair shop regulation */
  leasingRepairShopRegulation?: InputMaybe<Scalars['String']['input']>;
};

export type CarList = {
  __typename?: 'CarList';
  cars: Array<PortalCar>;
  /** total number of cars */
  total: Scalars['Float']['output'];
};

export type CarListFilterArgs = {
  /** Car location */
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car manufacturer name */
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car model name */
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by car properties */
  q?: InputMaybe<Scalars['String']['input']>;
};

export type CarOverviewDamageReport = {
  __typename?: 'CarOverviewDamageReport';
  /** The damage report car parts */
  carParts?: Maybe<Array<CarPart>>;
  /** The date the damage report has been created */
  createdAt: Scalars['Date']['output'];
  /** Damage number */
  damageNumber: Scalars['String']['output'];
  /** A damage report damage type */
  damageType?: Maybe<Array<Scalars['String']['output']>>;
  /** filteringStatuses */
  filteringStatuses?: Maybe<Array<Scalars['String']['output']>>;
  /** ID of the damage report */
  id: Scalars['ID']['output'];
  /** Type of the damage report */
  type: DamageReportType;
};

export type CarPart = {
  __typename?: 'CarPart';
  /** Area of the car part */
  area: Area;
  /** Category of the car part */
  category: Category;
  /** Damage reports for the given car part **for current car.** */
  damageReports: Array<DamageReport>;
  /** ID of the car part */
  id: Scalars['String']['output'];
  /** Name of the car part */
  name: Scalars['String']['output'];
  /** Type of the car part */
  type: CarPartType;
};

/** Type of the car part. */
export enum CarPartType {
  AIR_CONDITIONING_AND_HEATING = 'AIR_CONDITIONING_AND_HEATING',
  A_PILLAR_LEFT = 'A_PILLAR_LEFT',
  A_PILLAR_LEFT_1 = 'A_PILLAR_LEFT_1',
  A_PILLAR_LEFT_2 = 'A_PILLAR_LEFT_2',
  A_PILLAR_RIGHT = 'A_PILLAR_RIGHT',
  A_PILLAR_RIGHT_1 = 'A_PILLAR_RIGHT_1',
  A_PILLAR_RIGHT_2 = 'A_PILLAR_RIGHT_2',
  BODY_SILL_LEFT_FRONT = 'BODY_SILL_LEFT_FRONT',
  BODY_SILL_LEFT_REAR = 'BODY_SILL_LEFT_REAR',
  BODY_SILL_RIGHT_FRONT = 'BODY_SILL_RIGHT_FRONT',
  BODY_SILL_RIGHT_REAR = 'BODY_SILL_RIGHT_REAR',
  BRAKES = 'BRAKES',
  BRAKE_LIGHT_LEFT_REAR = 'BRAKE_LIGHT_LEFT_REAR',
  BRAKE_LIGHT_RIGHT_REAR = 'BRAKE_LIGHT_RIGHT_REAR',
  BUMPER_CENTER_FRONT = 'BUMPER_CENTER_FRONT',
  BUMPER_CENTER_REAR = 'BUMPER_CENTER_REAR',
  BUMPER_LEFT_FRONT = 'BUMPER_LEFT_FRONT',
  BUMPER_LEFT_REAR = 'BUMPER_LEFT_REAR',
  BUMPER_REAR_1 = 'BUMPER_REAR_1',
  BUMPER_REAR_2 = 'BUMPER_REAR_2',
  BUMPER_REAR_3 = 'BUMPER_REAR_3',
  BUMPER_REAR_5 = 'BUMPER_REAR_5',
  BUMPER_RIGHT_FRONT = 'BUMPER_RIGHT_FRONT',
  BUMPER_RIGHT_REAR = 'BUMPER_RIGHT_REAR',
  BUMPER_SIDE_LEFT_FRONT = 'BUMPER_SIDE_LEFT_FRONT',
  BUMPER_SIDE_LEFT_REAR = 'BUMPER_SIDE_LEFT_REAR',
  BUMPER_SIDE_RIGHT_FRONT = 'BUMPER_SIDE_RIGHT_FRONT',
  BUMPER_SIDE_RIGHT_REAR = 'BUMPER_SIDE_RIGHT_REAR',
  B_PILLAR_LEFT = 'B_PILLAR_LEFT',
  B_PILLAR_LEFT_1 = 'B_PILLAR_LEFT_1',
  B_PILLAR_LEFT_2 = 'B_PILLAR_LEFT_2',
  B_PILLAR_RIGHT = 'B_PILLAR_RIGHT',
  B_PILLAR_RIGHT_1 = 'B_PILLAR_RIGHT_1',
  B_PILLAR_RIGHT_2 = 'B_PILLAR_RIGHT_2',
  CARGO_BOX_SIDE_LEFT = 'CARGO_BOX_SIDE_LEFT',
  CARGO_BOX_SIDE_RIGHT = 'CARGO_BOX_SIDE_RIGHT',
  CARGO_LEFT_REAR_1 = 'CARGO_LEFT_REAR_1',
  CARGO_LEFT_REAR_2 = 'CARGO_LEFT_REAR_2',
  CARGO_LEFT_REAR_3 = 'CARGO_LEFT_REAR_3',
  CARGO_LEFT_REAR_4 = 'CARGO_LEFT_REAR_4',
  CARGO_RIGHT_REAR_1 = 'CARGO_RIGHT_REAR_1',
  CARGO_RIGHT_REAR_2 = 'CARGO_RIGHT_REAR_2',
  CARGO_RIGHT_REAR_3 = 'CARGO_RIGHT_REAR_3',
  CARGO_RIGHT_REAR_4 = 'CARGO_RIGHT_REAR_4',
  CHASSIS_AND_STEERING = 'CHASSIS_AND_STEERING',
  CONDENSER = 'CONDENSER',
  CONSOLE = 'CONSOLE',
  C_PILLAR_LEFT = 'C_PILLAR_LEFT',
  C_PILLAR_RIGHT = 'C_PILLAR_RIGHT',
  DOOR = 'DOOR',
  DOOR_HANDLE_LEFT_FRONT = 'DOOR_HANDLE_LEFT_FRONT',
  DOOR_HANDLE_LEFT_REAR = 'DOOR_HANDLE_LEFT_REAR',
  DOOR_HANDLE_REAR = 'DOOR_HANDLE_REAR',
  DOOR_HANDLE_RIGHT_FRONT = 'DOOR_HANDLE_RIGHT_FRONT',
  DOOR_HANDLE_RIGHT_REAR = 'DOOR_HANDLE_RIGHT_REAR',
  DOOR_LEFT_FRONT = 'DOOR_LEFT_FRONT',
  DOOR_LEFT_FRONT_1 = 'DOOR_LEFT_FRONT_1',
  DOOR_LEFT_FRONT_2 = 'DOOR_LEFT_FRONT_2',
  DOOR_LEFT_FRONT_3 = 'DOOR_LEFT_FRONT_3',
  DOOR_LEFT_FRONT_4 = 'DOOR_LEFT_FRONT_4',
  DOOR_LEFT_REAR = 'DOOR_LEFT_REAR',
  DOOR_LEFT_REAR_1 = 'DOOR_LEFT_REAR_1',
  DOOR_LEFT_REAR_2 = 'DOOR_LEFT_REAR_2',
  DOOR_LEFT_REAR_3 = 'DOOR_LEFT_REAR_3',
  DOOR_LEFT_REAR_4 = 'DOOR_LEFT_REAR_4',
  DOOR_QUARTER_LEFT_FRONT = 'DOOR_QUARTER_LEFT_FRONT',
  DOOR_QUARTER_LEFT_REAR = 'DOOR_QUARTER_LEFT_REAR',
  DOOR_QUARTER_LEFT_REAR_1 = 'DOOR_QUARTER_LEFT_REAR_1',
  DOOR_QUARTER_LEFT_REAR_2 = 'DOOR_QUARTER_LEFT_REAR_2',
  DOOR_QUARTER_RIGHT_FRONT = 'DOOR_QUARTER_RIGHT_FRONT',
  DOOR_QUARTER_RIGHT_REAR = 'DOOR_QUARTER_RIGHT_REAR',
  DOOR_QUARTER_RIGHT_REAR_1 = 'DOOR_QUARTER_RIGHT_REAR_1',
  DOOR_QUARTER_RIGHT_REAR_2 = 'DOOR_QUARTER_RIGHT_REAR_2',
  DOOR_RIGHT_FRONT = 'DOOR_RIGHT_FRONT',
  DOOR_RIGHT_FRONT_1 = 'DOOR_RIGHT_FRONT_1',
  DOOR_RIGHT_FRONT_2 = 'DOOR_RIGHT_FRONT_2',
  DOOR_RIGHT_FRONT_3 = 'DOOR_RIGHT_FRONT_3',
  DOOR_RIGHT_FRONT_4 = 'DOOR_RIGHT_FRONT_4',
  DOOR_RIGHT_REAR = 'DOOR_RIGHT_REAR',
  DOOR_RIGHT_REAR_1 = 'DOOR_RIGHT_REAR_1',
  DOOR_RIGHT_REAR_2 = 'DOOR_RIGHT_REAR_2',
  DOOR_RIGHT_REAR_3 = 'DOOR_RIGHT_REAR_3',
  DOOR_RIGHT_REAR_4 = 'DOOR_RIGHT_REAR_4',
  D_PILLAR_LEFT = 'D_PILLAR_LEFT',
  D_PILLAR_RIGHT = 'D_PILLAR_RIGHT',
  ELECTRONICS = 'ELECTRONICS',
  ENGINE = 'ENGINE',
  FENDER_LEFT_FRONT = 'FENDER_LEFT_FRONT',
  FENDER_LEFT_FRONT_1 = 'FENDER_LEFT_FRONT_1',
  FENDER_LEFT_FRONT_2 = 'FENDER_LEFT_FRONT_2',
  FENDER_LEFT_REAR = 'FENDER_LEFT_REAR',
  FENDER_LEFT_REAR_1 = 'FENDER_LEFT_REAR_1',
  FENDER_LEFT_REAR_2 = 'FENDER_LEFT_REAR_2',
  FENDER_LEFT_REAR_3 = 'FENDER_LEFT_REAR_3',
  FENDER_LEFT_REAR_4 = 'FENDER_LEFT_REAR_4',
  FENDER_RIGHT_FRONT = 'FENDER_RIGHT_FRONT',
  FENDER_RIGHT_FRONT_1 = 'FENDER_RIGHT_FRONT_1',
  FENDER_RIGHT_FRONT_2 = 'FENDER_RIGHT_FRONT_2',
  FENDER_RIGHT_REAR = 'FENDER_RIGHT_REAR',
  FENDER_RIGHT_REAR_1 = 'FENDER_RIGHT_REAR_1',
  FENDER_RIGHT_REAR_2 = 'FENDER_RIGHT_REAR_2',
  FENDER_RIGHT_REAR_3 = 'FENDER_RIGHT_REAR_3',
  FENDER_RIGHT_REAR_4 = 'FENDER_RIGHT_REAR_4',
  FLOOR = 'FLOOR',
  FOG_LIGHT_LEFT_FRONT = 'FOG_LIGHT_LEFT_FRONT',
  FOG_LIGHT_RIGHT_FRONT = 'FOG_LIGHT_RIGHT_FRONT',
  FRONT_PANEL = 'FRONT_PANEL',
  FUEL_FILTER_CAP_COVER = 'FUEL_FILTER_CAP_COVER',
  HEADLIGHTS_LEFT = 'HEADLIGHTS_LEFT',
  HEADLIGHTS_RIGHT = 'HEADLIGHTS_RIGHT',
  HOOD = 'HOOD',
  HOOD_1 = 'HOOD_1',
  HOOD_2 = 'HOOD_2',
  HOOD_3 = 'HOOD_3',
  HOOD_4 = 'HOOD_4',
  LICENSE_PLATE_HOLDER_FRONT = 'LICENSE_PLATE_HOLDER_FRONT',
  LICENSE_PLATE_HOLDER_REAR = 'LICENSE_PLATE_HOLDER_REAR',
  LOGO_FRONT = 'LOGO_FRONT',
  LOGO_REAR = 'LOGO_REAR',
  MAINTENANCE = 'MAINTENANCE',
  MIRROR_LEFT = 'MIRROR_LEFT',
  MIRROR_RIGHT = 'MIRROR_RIGHT',
  MISSING = 'MISSING',
  OTHER = 'OTHER',
  PANELS_AND_COVERS = 'PANELS_AND_COVERS',
  PANEL_LEFT_REAR = 'PANEL_LEFT_REAR',
  PANEL_RIGHT_REAR = 'PANEL_RIGHT_REAR',
  QUARTER_PANEL_LEFT_REAR = 'QUARTER_PANEL_LEFT_REAR',
  QUARTER_PANEL_RIGHT_REAR = 'QUARTER_PANEL_RIGHT_REAR',
  RADIATOR_GRILLE_FRONT = 'RADIATOR_GRILLE_FRONT',
  RIM_LEFT_FRONT = 'RIM_LEFT_FRONT',
  RIM_LEFT_REAR = 'RIM_LEFT_REAR',
  RIM_RIGHT_FRONT = 'RIM_RIGHT_FRONT',
  RIM_RIGHT_REAR = 'RIM_RIGHT_REAR',
  ROOF = 'ROOF',
  ROOF_1 = 'ROOF_1',
  ROOF_2 = 'ROOF_2',
  ROOF_3 = 'ROOF_3',
  ROOF_4 = 'ROOF_4',
  ROOF_5 = 'ROOF_5',
  ROOF_ANTENNA = 'ROOF_ANTENNA',
  ROOF_RAIL_LEFT = 'ROOF_RAIL_LEFT',
  ROOF_RAIL_RIGHT = 'ROOF_RAIL_RIGHT',
  SEATS = 'SEATS',
  SIDE_MARKER_LIGHTS_LEFT = 'SIDE_MARKER_LIGHTS_LEFT',
  SIDE_MARKER_LIGHTS_RIGHT = 'SIDE_MARKER_LIGHTS_RIGHT',
  STEP_REAR = 'STEP_REAR',
  SUNROOF = 'SUNROOF',
  SUNROOF_1 = 'SUNROOF_1',
  SUNROOF_2 = 'SUNROOF_2',
  TAILGATE = 'TAILGATE',
  TAILGATE_1 = 'TAILGATE_1',
  TAILGATE_2 = 'TAILGATE_2',
  TAILGATE_3 = 'TAILGATE_3',
  TAILGATE_4 = 'TAILGATE_4',
  TAILGATE_DOOR_HANDLE_REAR = 'TAILGATE_DOOR_HANDLE_REAR',
  TAILLIGHTS_LEFT = 'TAILLIGHTS_LEFT',
  TAILLIGHTS_RIGHT = 'TAILLIGHTS_RIGHT',
  TIRE_LEFT_FRONT = 'TIRE_LEFT_FRONT',
  TIRE_LEFT_REAR = 'TIRE_LEFT_REAR',
  TIRE_RIGHT_FRONT = 'TIRE_RIGHT_FRONT',
  TIRE_RIGHT_REAR = 'TIRE_RIGHT_REAR',
  TRUNK = 'TRUNK',
  TRUNK_LEFT = 'TRUNK_LEFT',
  TRUNK_RIGHT = 'TRUNK_RIGHT',
  TYRES = 'TYRES',
  WHEEL_LEFT_FRONT = 'WHEEL_LEFT_FRONT',
  WHEEL_LEFT_REAR = 'WHEEL_LEFT_REAR',
  WHEEL_RIGHT_FRONT = 'WHEEL_RIGHT_FRONT',
  WHEEL_RIGHT_REAR = 'WHEEL_RIGHT_REAR',
  WINDOW_LEFT_FRONT = 'WINDOW_LEFT_FRONT',
  WINDOW_LEFT_REAR = 'WINDOW_LEFT_REAR',
  WINDOW_REAR = 'WINDOW_REAR',
  WINDOW_REAR_1 = 'WINDOW_REAR_1',
  WINDOW_REAR_2 = 'WINDOW_REAR_2',
  WINDOW_RIGHT_FRONT = 'WINDOW_RIGHT_FRONT',
  WINDOW_RIGHT_REAR = 'WINDOW_RIGHT_REAR',
  WINDSHIELD_FRONT = 'WINDSHIELD_FRONT',
  WINDSHIELD_FRONT_1 = 'WINDSHIELD_FRONT_1',
  WINDSHIELD_FRONT_2 = 'WINDSHIELD_FRONT_2',
  WINDSHIELD_FRONT_3 = 'WINDSHIELD_FRONT_3',
  WINDSHIELD_FRONT_4 = 'WINDSHIELD_FRONT_4'
}

export enum CarServiceOptions {
  CLASS_A_TO_EQUIVALENT = 'CLASS_A_TO_EQUIVALENT',
  CLASS_A_TO_ONE_CLASS_LOWER = 'CLASS_A_TO_ONE_CLASS_LOWER',
  EXCLUSIVELY_CLASS_A = 'EXCLUSIVELY_CLASS_A',
  EXCLUSIVELY_EQUIVALENT = 'EXCLUSIVELY_EQUIVALENT',
  EXCLUSIVELY_ONE_CLASS_LOWER = 'EXCLUSIVELY_ONE_CLASS_LOWER',
  INQUIRIES = 'INQUIRIES'
}

export type CarSettingsInput = {
  /** Depending on this setting an approval from leasing company is required. Depends on present of damage processing network */
  isLeasingApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CarismaData = {
  __typename?: 'CarismaData';
  /** Carisma CommId */
  commId: Scalars['String']['output'];
};

export type CarismaDataInput = {
  /** Carisma CommId */
  commId: Scalars['String']['input'];
};

export enum CarsFilterSearchKeysType {
  FLEET = 'FLEET'
}

export enum CarsSearchBy {
  LICENSEPLATE = 'LicensePlate',
  PARTNERID = 'PartnerID',
  VIN = 'VIN'
}

export enum CarsSortBy {
  ACTIVEDAMAGES = 'ActiveDamages',
  FIRSTREGISTRATION = 'FirstRegistration',
  FLEETNAME = 'FleetName',
  LICENSEPLATE = 'LicensePlate',
  LOCATION = 'Location',
  MILEAGEREPORTEDDATE = 'MileageReportedDate',
  MODEL = 'Model',
  VIN = 'VIN'
}

export type Category = {
  __typename?: 'Category';
  /** Available car parts for the category */
  carParts: Array<CarPart>;
  /** Damage categories of the category */
  damageCategories: Array<DamageCategory>;
  /** ID of the category */
  id: Scalars['String']['output'];
  /** Name of the category */
  name: Scalars['String']['output'];
  /** Type of the category */
  type: CategoryType;
};

/** Type of the damage category. */
export enum CategoryType {
  CONSOLE = 'CONSOLE',
  GLASS = 'GLASS',
  INTERIOR = 'INTERIOR',
  LIGHTING = 'LIGHTING',
  MECHANICAL = 'MECHANICAL',
  MIRROR = 'MIRROR',
  MISSING = 'MISSING',
  MISSING_PARTS = 'MISSING_PARTS',
  ODOUR = 'ODOUR',
  PAINT = 'PAINT',
  PANELS_AND_COVERS = 'PANELS_AND_COVERS',
  PILLAR = 'PILLAR',
  SERVICE_LIGHTS = 'SERVICE_LIGHTS',
  TRUNK_INTERIOR = 'TRUNK_INTERIOR',
  WHEELS = 'WHEELS'
}

export type ChangeDamageReportStatusInput = {
  /** Closing reason */
  closeReason?: InputMaybe<DamageReportCloseReasonType>;
  /** Closing reason comment */
  closeReasonComment?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Damage Report Status */
  status?: InputMaybe<DamageReportStatus>;
};

export type ChangeDamageReportStatusResult = {
  __typename?: 'ChangeDamageReportStatusResult';
  /** Result of the damage report status change */
  success: Scalars['Boolean']['output'];
};

export type ChangePreInspectionReportStatusInput = {
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Damage Report Status */
  status: DamageReportStatus;
};

export type CheckBodyshopExistsItem = {
  /** Address city */
  addressCity: Scalars['String']['input'];
  /** Address house */
  addressHouse: Scalars['String']['input'];
  /** Address street */
  addressStreet: Scalars['String']['input'];
  /** Address zip */
  addressZip: Scalars['String']['input'];
  /** Bodyshop related emails */
  emails: Array<Scalars['String']['input']>;
  /** Bodyshop name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Bodyshop title */
  title: Scalars['String']['input'];
  /** Web site URL */
  webSiteUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Type of check component checklist */
export enum CheckComponentName {
  CHECKLIST_CAR_PARTS_PAGE = 'CHECKLIST_CAR_PARTS_PAGE',
  CHECKLIST_DASHBOARD_PAGE = 'CHECKLIST_DASHBOARD_PAGE',
  CHECKLIST_DRIVER_INFORMATION_PAGE = 'CHECKLIST_DRIVER_INFORMATION_PAGE',
  CHECKLIST_INFORMATION_PAGE = 'CHECKLIST_INFORMATION_PAGE',
  CHECKLIST_REPORT_DAMAGE_PAGE = 'CHECKLIST_REPORT_DAMAGE_PAGE'
}

export type CheckConfigurationInput = {
  /** A check component name */
  component?: InputMaybe<CheckComponentName>;
  /** The type of the check */
  name: Scalars['String']['input'];
  /** The options of the check configuration */
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Title for AR location */
  title_ar?: InputMaybe<Scalars['String']['input']>;
  /** Title for DE locale */
  title_de?: InputMaybe<Scalars['String']['input']>;
  /** Title for EN locale */
  title_en?: InputMaybe<Scalars['String']['input']>;
  /** Title for ES location */
  title_es?: InputMaybe<Scalars['String']['input']>;
  /** Title for FR location */
  title_fr?: InputMaybe<Scalars['String']['input']>;
  /** Title for HR location */
  title_hr?: InputMaybe<Scalars['String']['input']>;
  /** Title for IT location */
  title_it?: InputMaybe<Scalars['String']['input']>;
  /** Title for PL location */
  title_pl?: InputMaybe<Scalars['String']['input']>;
  /** Title for RO location */
  title_ro?: InputMaybe<Scalars['String']['input']>;
  /** Title for RU location */
  title_ru?: InputMaybe<Scalars['String']['input']>;
  /** Title for SQ location */
  title_sq?: InputMaybe<Scalars['String']['input']>;
  /** Title for SR location */
  title_sr?: InputMaybe<Scalars['String']['input']>;
  /** Title for TR location */
  title_tr?: InputMaybe<Scalars['String']['input']>;
  /** Title for UA location */
  title_ua?: InputMaybe<Scalars['String']['input']>;
};

export type CheckConfigurationResult = {
  __typename?: 'CheckConfigurationResult';
  /** Custom check component name */
  component?: Maybe<Scalars['String']['output']>;
  /** The type of the check */
  name: Scalars['String']['output'];
  /** The options of the check configuration */
  options?: Maybe<Scalars['JSONObject']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Title for AR location */
  titleAr?: Maybe<Scalars['String']['output']>;
  /** Title for DE locale */
  titleDe?: Maybe<Scalars['String']['output']>;
  /** Title for EN locale */
  titleEn?: Maybe<Scalars['String']['output']>;
  /** Title for ES location */
  titleEs?: Maybe<Scalars['String']['output']>;
  /** Title for FR locale */
  titleFr?: Maybe<Scalars['String']['output']>;
  /** Title for HR location */
  titleHr?: Maybe<Scalars['String']['output']>;
  /** Title for IT location */
  titleIt?: Maybe<Scalars['String']['output']>;
  /** Title for PL location */
  titlePl?: Maybe<Scalars['String']['output']>;
  /** Title for RO location */
  titleRo?: Maybe<Scalars['String']['output']>;
  /** Title for RU location */
  titleRu?: Maybe<Scalars['String']['output']>;
  /** Title for SQ location */
  titleSq?: Maybe<Scalars['String']['output']>;
  /** Title for SR location */
  titleSr?: Maybe<Scalars['String']['output']>;
  /** Title for TR location */
  titleTr?: Maybe<Scalars['String']['output']>;
  /** Title for UA location */
  titleUa?: Maybe<Scalars['String']['output']>;
};

export type ChecklistInfoResult = {
  __typename?: 'ChecklistInfoResult';
  /** Checklist car data */
  car: ChecklistReportCarWithoutVinResult;
  /** Checklist report documents */
  checklistReportDocuments: Array<ChecklistReportChecklistInfoDocumentInput>;
  /** Checklist template data */
  checklistTemplate: ChecklistTemplateResult;
  /** Checklist state data */
  checks?: Maybe<Array<ChecklistReportCheckResult>>;
  /** Created at */
  createdAt: Scalars['Date']['output'];
  /** Executed at */
  executedAt?: Maybe<Scalars['Date']['output']>;
  /** Driver name */
  executedBy?: Maybe<Scalars['String']['output']>;
  /** Execution location */
  executionLocation?: Maybe<Scalars['String']['output']>;
  /** Fleet comment */
  fleetComment?: Maybe<Scalars['String']['output']>;
  /** Whether or not the checklist report was completed with warnings */
  hasWarnings: Scalars['Boolean']['output'];
  /** Checklist identifier */
  id: Scalars['String']['output'];
  /** Check report images */
  images?: Maybe<Array<ChecklistReportImageResult>>;
  /** Whether the checklist is completed */
  isCompleted: Scalars['Boolean']['output'];
  /** The number of the damages, linked to the checklist report check. */
  linkedDamagesCount: Scalars['Float']['output'];
  /** Updated at */
  updatedAt: Scalars['Date']['output'];
  /** Escalation rules to be show as warnings */
  warnings: Array<RuleConfigurationResult>;
};

export type ChecklistMetadataMappingsDriverInformationInput = {
  /** Driver's email checklist's mapping path */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Driver's name checklist's mapping path */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Driver's phone checklist's mapping path */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ChecklistMetadataMappingsInput = {
  carInformation?: InputMaybe<Scalars['String']['input']>;
  driverInformation?: InputMaybe<ChecklistMetadataMappingsDriverInformationInput>;
};

export type ChecklistPaginationInput = {
  /** Max number of results */
  limit: Scalars['Int']['input'];
  /** Page number */
  page: Scalars['Int']['input'];
};

export type ChecklistReportCarResult = {
  __typename?: 'ChecklistReportCarResult';
  /** Car availability */
  availability: CarAvailabilityStatus;
  /** Car body style */
  bodyStyle: BodyStyle;
  /** Car first registration date */
  firstRegistrationDate?: Maybe<Scalars['Date']['output']>;
  /** Fleet name */
  fleetName: Scalars['String']['output'];
  /** Car ID */
  id: Scalars['ID']['output'];
  /** Is car pool */
  isPool: Scalars['Boolean']['output'];
  /** Car license plate */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Car location */
  location?: Maybe<Scalars['String']['output']>;
  /** Car manufacturer */
  make: Scalars['String']['output'];
  /** Car mileage */
  mileage?: Maybe<Scalars['Float']['output']>;
  /** Last mileage update date */
  mileageReportedDate?: Maybe<Scalars['Date']['output']>;
  /** Car model */
  model: Scalars['String']['output'];
  /** Car vin */
  vin: Scalars['String']['output'];
};

export type ChecklistReportCarWithoutVinResult = {
  __typename?: 'ChecklistReportCarWithoutVINResult';
  /** Car availability */
  availability: CarAvailabilityStatus;
  /** Car body style */
  bodyStyle: BodyStyle;
  /** Car first registration date */
  firstRegistrationDate?: Maybe<Scalars['Date']['output']>;
  /** Fleet name */
  fleetName: Scalars['String']['output'];
  /** Car ID */
  id: Scalars['ID']['output'];
  /** Is car pool */
  isPool: Scalars['Boolean']['output'];
  /** Car license plate */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Car location */
  location?: Maybe<Scalars['String']['output']>;
  /** Car manufacturer */
  make: Scalars['String']['output'];
  /** Car mileage */
  mileage?: Maybe<Scalars['Float']['output']>;
  /** Last mileage update date */
  mileageReportedDate?: Maybe<Scalars['Date']['output']>;
  /** Car model */
  model: Scalars['String']['output'];
};

export type ChecklistReportCheckDamageInput = {
  /** id of the damage */
  id: Scalars['String']['input'];
};

export type ChecklistReportCheckInput = {
  /** Damages reported for current check */
  damageReports?: InputMaybe<Array<ChecklistReportCheckDamageInput>>;
  /** Checklist additional data */
  data?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Name of the check */
  name: Scalars['String']['input'];
};

export type ChecklistReportCheckResult = {
  __typename?: 'ChecklistReportCheckResult';
  /** Check report damages */
  damages?: Maybe<Array<ChecklistReportDamageResult>>;
  /** Check data */
  data?: Maybe<Scalars['JSONObject']['output']>;
  /** Checklist check ID */
  id: Scalars['String']['output'];
  /** Check report images */
  images?: Maybe<Array<ChecklistReportImageResult>>;
  /** Check name */
  name: Scalars['String']['output'];
};

export type ChecklistReportChecklistInfoDocumentInput = {
  __typename?: 'ChecklistReportChecklistInfoDocumentInput';
  /** A checklist document ID */
  id: Scalars['String']['output'];
  /** A checklist document URL */
  url: Scalars['String']['output'];
};

export type ChecklistReportDamageResult = {
  __typename?: 'ChecklistReportDamageResult';
  /** Car parts */
  carParts?: Maybe<Array<CarPartType>>;
  /** Damage categories */
  damageCategories?: Maybe<Array<DamageCategoryType>>;
  /** Damage comment */
  damageComment?: Maybe<Scalars['String']['output']>;
  /** Damage report ID */
  id: Scalars['String']['output'];
  /** Damage images */
  images?: Maybe<Array<DamageReportImage>>;
  /** Damage access token */
  token: Scalars['String']['output'];
  /** Damage report type */
  type: DamageReportType;
};

export type ChecklistReportDocumentInput = {
  __typename?: 'ChecklistReportDocumentInput';
  /** A checklist document ID */
  id: Scalars['String']['output'];
  /** A checklist document URL */
  url: Scalars['String']['output'];
};

export type ChecklistReportFiltersResult = {
  __typename?: 'ChecklistReportFiltersResult';
  /** The number of matching cases */
  count?: Maybe<Scalars['Int']['output']>;
  /** The filter key that to be sent to backend */
  key?: Maybe<Scalars['String']['output']>;
  /** The name of the filter */
  name: Scalars['String']['output'];
  /** Nested structure of filters */
  options?: Maybe<Array<ChecklistReportFiltersResult>>;
  /** Supported keys by filter */
  supportedKeys?: Maybe<Array<Scalars['String']['output']>>;
  /** Filter field type */
  type?: Maybe<FilterTypes>;
};

export type ChecklistReportImageInput = {
  /** File for upload */
  file: Scalars['Upload']['input'];
  /** Type of the image */
  type: Scalars['String']['input'];
};

export type ChecklistReportImageResult = {
  __typename?: 'ChecklistReportImageResult';
  /** Image ID */
  id: Scalars['ID']['output'];
  /** Shows whether an image is annotated or not */
  isAnnotated: Scalars['Boolean']['output'];
  /** The name of the image */
  name: Scalars['String']['output'];
  /** Image type */
  type: Scalars['String']['output'];
  /** Image url */
  url: Scalars['String']['output'];
};

export type ChecklistReportListInput = {
  /** Car id */
  carId?: InputMaybe<Scalars['String']['input']>;
  /** Driver name reported ChecklistReport */
  driver?: InputMaybe<Scalars['String']['input']>;
  /** Checklist report execution location */
  executionLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Allows filtering by execution status. Applies the filter on top of completed checklists. */
  executionStatus?: InputMaybe<ExecutionStatus>;
  /** Fleet name */
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Fleet id */
  fleetId?: InputMaybe<Scalars['String']['input']>;
  /** ChecklistReport reported FROM date */
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  /** Car licensePlate */
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  /** Car make */
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car model */
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ChecklistReport name */
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  /** date from  */
  pagination?: InputMaybe<ChecklistPaginationInput>;
  sort?: InputMaybe<ChecklistSortInput>;
  /** Template id */
  templateId?: InputMaybe<Scalars['String']['input']>;
  /** ChecklistReport reported TO date */
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ChecklistReportListResult = {
  __typename?: 'ChecklistReportListResult';
  /** Checklists */
  checklists: Array<ChecklistResult>;
};

export enum ChecklistReportsSortBy {
  CAR = 'CAR',
  EXECUTED_AT = 'EXECUTED_AT',
  FLEET = 'FLEET',
  LICENSE_PLATE = 'LICENSE_PLATE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  NAME = 'NAME'
}

export type ChecklistResult = {
  __typename?: 'ChecklistResult';
  /** Checklist car data */
  car: ChecklistReportCarResult;
  /** Checklist report documents */
  checklistReportDocuments: Array<ChecklistReportDocumentInput>;
  /** Checklist template data */
  checklistTemplate: ChecklistTemplateResult;
  /** Checklist state data */
  checks?: Maybe<Array<ChecklistReportCheckResult>>;
  /** Created at */
  createdAt: Scalars['Date']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  /** Executed at */
  executedAt?: Maybe<Scalars['Date']['output']>;
  /** Driver name */
  executedBy?: Maybe<Scalars['String']['output']>;
  /** Execution location */
  executionLocation?: Maybe<Scalars['String']['output']>;
  /** Fleet comment */
  fleetComment?: Maybe<Scalars['String']['output']>;
  /** Whether or not the checklist report was completed with warnings */
  hasWarnings: Scalars['Boolean']['output'];
  /** Checklist identifier */
  id: Scalars['String']['output'];
  /** Check report images */
  images?: Maybe<Array<ChecklistReportImageResult>>;
  /** Whether the checklist is completed */
  isCompleted: Scalars['Boolean']['output'];
  /** The number of the damages, linked to the checklist report check. */
  linkedDamagesCount: Scalars['Float']['output'];
  /** Updated at */
  updatedAt: Scalars['Date']['output'];
  /** Escalation rules to be show as warnings */
  warnings: Array<RuleConfigurationResult>;
};

export type ChecklistSortInput = {
  /** Sort by property */
  sortBy: ChecklistReportsSortBy;
  /** Sort order property */
  sortOrder: SortOrder;
};

export type ChecklistTemplateFleet = {
  __typename?: 'ChecklistTemplateFleet';
  /** Fleet ID */
  id: Scalars['ID']['output'];
  /** Fleet name */
  name: Scalars['String']['output'];
  /** Fleet name */
  title?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTemplateResult = {
  __typename?: 'ChecklistTemplateResult';
  /** Checklist template checks */
  checks: Array<CheckConfigurationResult>;
  fleet: ChecklistTemplateFleet;
  /** Checklist template id */
  id: Scalars['ID']['output'];
  /** Checklist template name */
  name: Scalars['String']['output'];
  /** Checklist name for AR location */
  nameAr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for DE location */
  nameDe?: Maybe<Scalars['String']['output']>;
  /** Checklist name for EN location */
  nameEn?: Maybe<Scalars['String']['output']>;
  /** Checklist name for ES location */
  nameEs?: Maybe<Scalars['String']['output']>;
  /** Checklist name for FR location */
  nameFr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for HR location */
  nameHr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for IT location */
  nameIt?: Maybe<Scalars['String']['output']>;
  /** Checklist name for PL location */
  namePl?: Maybe<Scalars['String']['output']>;
  /** Checklist name for RO location */
  nameRo?: Maybe<Scalars['String']['output']>;
  /** Checklist name for RU location */
  nameRu?: Maybe<Scalars['String']['output']>;
  /** Checklist name for SQ location */
  nameSq?: Maybe<Scalars['String']['output']>;
  /** Checklist name for SR location */
  nameSr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for TR location */
  nameTr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for UA location */
  nameUa?: Maybe<Scalars['String']['output']>;
  /** Checklist template escalation rules */
  rules: Array<RuleConfigurationResult>;
  /** A checklist template type */
  type: ChecklistTemplateType;
  /** A checklist warning message data */
  warningMessage?: Maybe<Scalars['JSONObject']['output']>;
};

/** Checklist template visibility scope */
export enum ChecklistTemplateScope {
  DRIVER = 'DRIVER',
  MANAGER = 'MANAGER'
}

/** Type of a checklist template */
export enum ChecklistTemplateType {
  BASELINE = 'BASELINE',
  CARHANDOVER = 'CarHandover',
  DEPARTURECONTROL = 'DepartureControl',
  LEASINGRETURN = 'LeasingReturn',
  REGULAR = 'REGULAR'
}

export type ChecklistTemplateWarningMessageInput = {
  /** Warning message default title */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Warning message AR description */
  description_ar?: InputMaybe<Scalars['String']['input']>;
  /** Warning message DE description */
  description_de?: InputMaybe<Scalars['String']['input']>;
  /** Warning message EN description */
  description_en?: InputMaybe<Scalars['String']['input']>;
  /** Warning message ES description */
  description_es?: InputMaybe<Scalars['String']['input']>;
  /** Warning message FR description */
  description_fr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message HR description */
  description_hr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message i18n key description */
  description_i18n?: InputMaybe<Scalars['String']['input']>;
  /** Warning message IT description */
  description_it?: InputMaybe<Scalars['String']['input']>;
  /** Warning message PL description */
  description_pl?: InputMaybe<Scalars['String']['input']>;
  /** Warning message RO description */
  description_ro?: InputMaybe<Scalars['String']['input']>;
  /** Warning message RU description */
  description_ru?: InputMaybe<Scalars['String']['input']>;
  /** Warning message SQ description */
  description_sq?: InputMaybe<Scalars['String']['input']>;
  /** Warning message SR description */
  description_sr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message TR description */
  description_tr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message UA description */
  description_ua?: InputMaybe<Scalars['String']['input']>;
  /** Warning message default title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Warning message AR title */
  title_ar?: InputMaybe<Scalars['String']['input']>;
  /** Warning message DE title */
  title_de?: InputMaybe<Scalars['String']['input']>;
  /** Warning message EN title */
  title_en?: InputMaybe<Scalars['String']['input']>;
  /** Warning message ES title */
  title_es?: InputMaybe<Scalars['String']['input']>;
  /** Warning message FR title */
  title_fr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message HR title */
  title_hr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message i18n key title */
  title_i18n?: InputMaybe<Scalars['String']['input']>;
  /** Warning message IT title */
  title_it?: InputMaybe<Scalars['String']['input']>;
  /** Warning message PL title */
  title_pl?: InputMaybe<Scalars['String']['input']>;
  /** Warning message RO title */
  title_ro?: InputMaybe<Scalars['String']['input']>;
  /** Warning message RU title */
  title_ru?: InputMaybe<Scalars['String']['input']>;
  /** Warning message SQ title */
  title_sq?: InputMaybe<Scalars['String']['input']>;
  /** Warning message SR title */
  title_sr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message TR title */
  title_tr?: InputMaybe<Scalars['String']['input']>;
  /** Warning message UA title */
  title_ua?: InputMaybe<Scalars['String']['input']>;
};

export type ChecklistsModuleConfiguration = {
  __typename?: 'ChecklistsModuleConfiguration';
  /** Whether a fleet inherits checklists from the parent fleet */
  inheritFromParent: Scalars['Boolean']['output'];
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type CloseCarServiceInput = {
  /** Service report ID */
  serviceReportId: Scalars['ID']['input'];
};

export type CloseIndicatedDamageReportInput = {
  /** Damage verification message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Reporter's email */
  reporterEmail?: InputMaybe<Scalars['String']['input']>;
  /** Reporter's name */
  reporterName?: InputMaybe<Scalars['String']['input']>;
  /** Reporter's phone */
  reporterPhone?: InputMaybe<Scalars['String']['input']>;
};

export type CloseIndicatedDamageReportResult = {
  __typename?: 'CloseIndicatedDamageReportResult';
  /** Damage report ID */
  id: Scalars['ID']['output'];
  /** Status of operation */
  success: Scalars['Boolean']['output'];
};

export type CommonBodyshopModuleConfiguration = {
  __typename?: 'CommonBodyshopModuleConfiguration';
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type CommonBodyshopModuleConfigurationInput = {
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['input'];
};

export type CommonModuleConfiguration = {
  __typename?: 'CommonModuleConfiguration';
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type CommonModuleConfigurationInput = {
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['input'];
};

export type CompanyAffiliationResult = {
  __typename?: 'CompanyAffiliationResult';
  /** Is a field is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Company affiliation information of reporter */
  value?: Maybe<CompanyAffiliationTypeEnum>;
};

/** Define the reporter company affiliation types. */
export enum CompanyAffiliationTypeEnum {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL'
}

export type CompleteAccidentReportInput = {
  /** Answers about driver's condition at the time of the accident */
  accidentQuestions?: InputMaybe<UpdateAccidentQuestionsInput>;
  /** Accident third party data */
  accidentThirdParty?: InputMaybe<AccidentThirdPartyInput>;
  /** Damaged car parts array */
  carParts: Array<CarPartType>;
  /** Damage category type */
  damageCategoryTypes?: InputMaybe<Array<DamageCategoryType>>;
  /** Image ids */
  damageImagesIds: Array<Scalars['String']['input']>;
  /** Damage information data */
  damageInformation?: InputMaybe<DamageInformationInput>;
  /** Indicates that a driver informed consent */
  driverInformConsent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Driver information data */
  driverInformation: DriverInformationInput;
  /** Injured person information */
  injuredPersonInformation: InjuredPersonInformationInput;
  /** Whether the damage is accident or not */
  isAccident?: InputMaybe<Scalars['Boolean']['input']>;
  isCarRoadworthy?: InputMaybe<DamageReportDrivableType>;
  /** Police report data */
  policeReport: PoliceReportInput;
  /** Reporter name */
  reportedBy?: InputMaybe<Scalars['String']['input']>;
  /** Additional information of reporter */
  reporterInformation?: InputMaybe<ReporterInformationInput>;
  /** Wildlife protection accident data */
  wildlifeProtection?: InputMaybe<DamageReportWildlifeDataInput>;
};

export type CompleteAccidentReportResult = {
  __typename?: 'CompleteAccidentReportResult';
  success: Scalars['Boolean']['output'];
};

export type CompleteCarServiceInput = {
  /** Next service date */
  nextServiceDate: Scalars['Date']['input'];
  /** Service report ID */
  serviceReportId: Scalars['ID']['input'];
};

export type CompleteDamageReportVerificationInput = {
  /** Damaged car parts array */
  carParts: Array<CarPartType>;
  /** Damage category type */
  damageCategories: Array<DamageCategoryType>;
  /** Fleet manager comment */
  fleetComment?: InputMaybe<Scalars['String']['input']>;
  /** Whether the damage is confirmed */
  isConfirmed: Scalars['Boolean']['input'];
  /** Damage verification message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Next status for damage report */
  nextStatus?: InputMaybe<CompleteDamageReportVerificationStatus>;
  /** Uploaded damage images IDs to be published */
  publishImages: Array<Scalars['String']['input']>;
};

export type CompleteDamageReportVerificationResult = {
  __typename?: 'CompleteDamageReportVerificationResult';
  /** Damage report ID */
  id: Scalars['ID']['output'];
  /** Whether damage report was confirmed */
  isConfirmed: Scalars['Boolean']['output'];
  /** Status of operation */
  success: Scalars['Boolean']['output'];
};

export enum CompleteDamageReportVerificationStatus {
  INTERNALLY_REPAIRED = 'internally_repaired',
  NEW = 'new',
  REPAIR_POSTPONED = 'repair_postponed'
}

export type CompleteExteriorDamageReportInput = {
  /** Additional information data */
  additionalInformation?: InputMaybe<AdditionalInformation>;
  /** Uploaded damage images IDs */
  attachedDamageImages?: InputMaybe<Array<AttachedDamageImage>>;
  /** Damaged car parts array */
  carParts: Array<CarPartType>;
  /** Damage category type */
  damageCategoryTypes: Array<DamageCategoryType>;
  /** Driver information data */
  driverInformation?: InputMaybe<DriverInformation>;
  /** Indicates if damage minor */
  isMinor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reporter name */
  reporterName?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report Status */
  status?: InputMaybe<DamageReportStatus>;
};

export type CompleteExteriorDamageReportResult = {
  __typename?: 'CompleteExteriorDamageReportResult';
  success: Scalars['Boolean']['output'];
};

export type CompleteIndicatedDamageReportInput = {
  /** Damaged car parts array */
  carParts: Array<CarPartType>;
  /** Damage category type */
  damageCategories: Array<DamageCategoryType>;
  /** Damage verification message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Uploaded damage images IDs to be published */
  publishImages: Array<Scalars['String']['input']>;
  /** Reporter's email */
  reporterEmail?: InputMaybe<Scalars['String']['input']>;
  /** Reporter's name */
  reporterName?: InputMaybe<Scalars['String']['input']>;
  /** Reporter's phone */
  reporterPhone?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteIndicatedDamageReportResult = {
  __typename?: 'CompleteIndicatedDamageReportResult';
  /** Damage report ID */
  id: Scalars['ID']['output'];
  /** Status of operation */
  success: Scalars['Boolean']['output'];
};

export type CompleteInspectionOnSiteInput = {
  /** The duration of the repair in days */
  duration?: InputMaybe<RepairOfferDurationInput>;
  /** Repair offer estimation comment */
  estimationComment?: InputMaybe<Scalars['String']['input']>;
  /** The price of the repair */
  price: Scalars['Float']['input'];
  /** RepairOffer ID */
  repairOfferId: Scalars['String']['input'];
  /** The scope of the repair */
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteInteriorDamageReportInput = {
  /** Additional information */
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  /** Interior damage report category */
  damageCategory: DamageCategoryType;
  /** Damage location area */
  damageLocation?: InputMaybe<Array<CarPartType>>;
  /** Address of the driver */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** City of the driver */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Email of the driver */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of the driver */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Phone of the driver */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** Zip of the driver */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** Image ids to publish */
  imagesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Reporter name */
  reporterName?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteMechanicalDamageReportInput = {
  /** Mechanical damage report category */
  damageCategory: MechanicalDamageCategory;
  /** Damage location area */
  damageLocation: Array<MechanicalCarPart>;
  /** Desired Repair Address */
  desiredRepairAddress?: InputMaybe<Scalars['String']['input']>;
  /** Address of the driver */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** City of the driver */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Email of the driver */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of the driver */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Phone of the driver */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** Zip of the driver */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** Dashboard image ID to publish */
  imageId?: InputMaybe<Scalars['String']['input']>;
  /** Provided description of the problem */
  problemDescription?: InputMaybe<Scalars['String']['input']>;
};

export type CompletePreInspectionReportInput = {
  /** Additional service update */
  additionalServices: Array<AdditionalServiceItem>;
  /** A car body type */
  bodyType: BodyStyle;
  /** Damaged car parts array */
  carParts: Array<CarPartType>;
  /** Image ids */
  damageImagesIds: Array<Scalars['String']['input']>;
  /** Damage reasons data */
  damageReasons: DamageReasons;
  /** Legal agreement */
  legalAgreements: Array<AcceptLegalAgreement>;
  /** Registration data */
  registrationData?: InputMaybe<UpdateRegistrationData>;
  slots: Array<AppointmentSlotInput>;
};

export type CompletePreInspectionReportResult = {
  __typename?: 'CompletePreInspectionReportResult';
  success: Scalars['Boolean']['output'];
};

export type CompleteSimpleAccidentReportInput = {
  /** Damaged car parts array */
  carParts: Array<CarPartType>;
  /** Damage category type */
  damageCategoryTypes: Array<DamageCategoryType>;
  /** Damage description */
  damageDescription?: InputMaybe<Scalars['String']['input']>;
  /** Image ids */
  damageImagesIds: Array<Scalars['String']['input']>;
};

export type CompleteThirdPartyReportInput = {
  /** Fleet's driver information */
  carInfo: CarInfoInput;
  /** Damaged car parts array */
  carPartTypes: Array<CarPartType>;
  /** Damage categories */
  damageCategoryTypes: Array<DamageCategoryType>;
  /** Damage Information */
  damageInformation: DamageInfoInput;
  /** Driver information */
  driverInfo: DriverInfoInput;
  /** Fleet's driver information */
  fleetDriverInfo: FleetDriverInfoInput;
};

export type ConfirmAppointmentInput = {
  /** ID of the damage report */
  appointmentId: Scalars['ID']['input'];
  /** Desired appointment */
  slot: AppointmentSlotInput;
};

export type ConfirmBodyshopAppAppointmentResult = {
  __typename?: 'ConfirmBodyshopAppAppointmentResult';
  appointment: BodyshopAppAppointment;
  success: Scalars['Boolean']['output'];
};

export type ConfirmServiceAppointmentInput = {
  /** Suggest an alternative date */
  alternativeDate?: InputMaybe<Scalars['Date']['input']>;
  /** Additional comment to fleet manager */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Service report ID */
  id: Scalars['String']['input'];
};

export type CostResult = {
  __typename?: 'CostResult';
  /** Cost VAT amount */
  amountGross: Scalars['Float']['output'];
  /** Cost net amount */
  amountNet: Scalars['Float']['output'];
  /** Cost VAT amount */
  amountVAT: Scalars['Float']['output'];
  /** Bodyshop ID */
  bodyshopId?: Maybe<Scalars['String']['output']>;
  /** Cost comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** A cost creation date */
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Shows whether VAT applied or not */
  isVAT: Scalars['Boolean']['output'];
  /** Shows verified cost or not */
  isVerified: Scalars['Boolean']['output'];
  /** Feature name */
  type: CostType;
};

/** Type of a cost amount sign (negative or positive) */
export enum CostSignType {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE'
}

export type CostSignTypeObject = {
  __typename?: 'CostSignTypeObject';
  /** A cost sign type */
  costSign?: Maybe<CostSignType>;
  /** A cost type */
  costType: CostType;
};

/** Type of a cost */
export enum CostType {
  AGGREGATED = 'AGGREGATED',
  ASSESSMENT = 'ASSESSMENT',
  CAR_RENTAL = 'CAR_RENTAL',
  CLAIMED_FROM_EMPLOYEE = 'CLAIMED_FROM_EMPLOYEE',
  DEDUCTIBLE = 'DEDUCTIBLE',
  DEPRECIATION = 'DEPRECIATION',
  OPPONENT_INSURANCE_REFUND = 'OPPONENT_INSURANCE_REFUND',
  OTHER = 'OTHER',
  OWN_INSURANCE_REFUND = 'OWN_INSURANCE_REFUND',
  REPAIR = 'REPAIR',
  TOWING_SERVICE = 'TOWING_SERVICE'
}

export type CostsResult = {
  __typename?: 'CostsResult';
  /** A costs list */
  costs: Array<CostResult>;
  /** Number of invoices */
  invoicesQuantity: Scalars['Float']['output'];
  /** Returns total information about costs */
  total: CostsTotalResult;
};

export type CostsTotalResult = {
  __typename?: 'CostsTotalResult';
  /** Total gross amount */
  totalCostsGross: Scalars['Float']['output'];
  /** Total net amount */
  totalCostsNet: Scalars['Float']['output'];
  /** Total VAT amount */
  totalCostsVAT: Scalars['Float']['output'];
};

export type CountsChecklistReportResult = {
  __typename?: 'CountsChecklistReportResult';
  /** Total number of checklist reports */
  total: Scalars['Float']['output'];
  /** Total number of checklist reports with linked damage reports */
  withLinkedDamages: Scalars['Float']['output'];
  /** Total number of checklist reports with warnings */
  withWarnings: Scalars['Float']['output'];
  /** Total number of checklist reports without warnings */
  withoutWarnings: Scalars['Float']['output'];
};

export type CreateApiTokenInput = {
  /** API token name. Example: fleet name or steerer name */
  name: Scalars['String']['input'];
  /** Tenant name */
  tenantName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChecklistReportResult = {
  __typename?: 'CreateChecklistReportResult';
  /** Checklist information */
  checklist: ChecklistInfoResult;
  /** Access token that allows checklist completion */
  code: Scalars['String']['output'];
};

export type CreateChecklistTemplateInput = {
  /** Checklist configuration */
  checks: Array<CheckConfigurationInput>;
  /** Fleet ID */
  fleetId?: InputMaybe<Scalars['ID']['input']>;
  /** Fleet name */
  fleetName?: InputMaybe<Scalars['ID']['input']>;
  /** Mappings for metadata field */
  metadataMappings?: InputMaybe<ChecklistMetadataMappingsInput>;
  /** Checklist name */
  name: Scalars['String']['input'];
  /** Checklist name for AR location */
  nameAr?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for DE location */
  nameDe?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for EN location */
  nameEn?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for ES location */
  nameEs?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for FR location */
  nameFr?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for HR location */
  nameHr?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for IT location */
  nameIt?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for PL location */
  namePl?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for RO location */
  nameRo?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for RU location */
  nameRu?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for SQ location */
  nameSq?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for SR location */
  nameSr?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for TR location */
  nameTr?: InputMaybe<Scalars['String']['input']>;
  /** Checklist name for UA location */
  nameUa?: InputMaybe<Scalars['String']['input']>;
  /** Checklist pdf report generation flag */
  pdfReport?: InputMaybe<Scalars['Boolean']['input']>;
  /** Escalation rule configuration */
  rules?: InputMaybe<Array<RuleConfigurationInput>>;
  /** Checklist template visibility scope */
  scope?: Array<ChecklistTemplateScope>;
  /** Checklist template type */
  type?: ChecklistTemplateType;
  /** Escalation rule configuration */
  warningMessage?: InputMaybe<ChecklistTemplateWarningMessageInput>;
};

export type CreateChecklistTemplateResult = {
  __typename?: 'CreateChecklistTemplateResult';
  /** Checklist template checks */
  checks: Array<CheckConfigurationResult>;
  fleet: ChecklistTemplateFleet;
  /** Checklist template id */
  id: Scalars['ID']['output'];
  /** Checklist template name */
  name: Scalars['String']['output'];
  /** Checklist name for AR location */
  nameAr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for DE location */
  nameDe?: Maybe<Scalars['String']['output']>;
  /** Checklist name for EN location */
  nameEn?: Maybe<Scalars['String']['output']>;
  /** Checklist name for ES location */
  nameEs?: Maybe<Scalars['String']['output']>;
  /** Checklist name for FR location */
  nameFr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for HR location */
  nameHr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for IT location */
  nameIt?: Maybe<Scalars['String']['output']>;
  /** Checklist name for PL location */
  namePl?: Maybe<Scalars['String']['output']>;
  /** Checklist name for RO location */
  nameRo?: Maybe<Scalars['String']['output']>;
  /** Checklist name for RU location */
  nameRu?: Maybe<Scalars['String']['output']>;
  /** Checklist name for SQ location */
  nameSq?: Maybe<Scalars['String']['output']>;
  /** Checklist name for SR location */
  nameSr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for TR location */
  nameTr?: Maybe<Scalars['String']['output']>;
  /** Checklist name for UA location */
  nameUa?: Maybe<Scalars['String']['output']>;
  /** Checklist template escalation rules */
  rules: Array<RuleConfigurationResult>;
  /** A checklist template type */
  type: ChecklistTemplateType;
  /** A checklist warning message data */
  warningMessage?: Maybe<Scalars['JSONObject']['output']>;
};

export type CreateEmailTemplateInput = {
  /** Email template content */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** Email template content body */
  contentBody: Scalars['String']['input'];
  /** Email template content title */
  contentTitle: Scalars['String']['input'];
  /** Email template description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email template headline icon */
  headlineIcon?: InputMaybe<GenericTemplateHeadlineIcon>;
  /** Email template headline label color */
  headlineLabelColor?: InputMaybe<GenericTemplateLabelColor>;
  /** Email template headline label */
  headlineLabelText?: InputMaybe<Scalars['String']['input']>;
  /** Email template headline label title */
  headlineTitle?: InputMaybe<Scalars['String']['input']>;
  /** Email template language */
  lang: Language;
  /** Email template name */
  name: EmailTemplateName;
  /** Email template sign off element */
  signoff: Scalars['String']['input'];
  /** Email template subject */
  subject: Scalars['String']['input'];
  /** Email template tenant name */
  tenant?: InputMaybe<Scalars['String']['input']>;
  /** Email template title */
  title: Scalars['String']['input'];
};

export type CreateFleetInput = {
  /** Fleet cars */
  cars?: InputMaybe<Array<FleetCar>>;
  /** Fleet configuration */
  configuration?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Fleet name (will be used in app link domain) */
  name: Scalars['String']['input'];
  /** Parent fleet name (if fleet is part of fleet group) */
  parentFleetName?: InputMaybe<Scalars['String']['input']>;
  /** Internal fleet id at partner side is required property to identify farther fleet in next api calls */
  partnerId?: InputMaybe<Scalars['String']['input']>;
  /** Fleet required services */
  requiredServices?: InputMaybe<RequiredServicesInput>;
  /** Fleet title (will be used in emails, UI) */
  title: Scalars['String']['input'];
  /** Fleet users */
  users?: InputMaybe<Array<FleetUser>>;
};

export type CreateFleetResult = {
  __typename?: 'CreateFleetResult';
  /** Status of creating fleet */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateRepairOfferInput = {
  /** Should insurance/leasing approvals be mandatory to proceed */
  approvals?: InputMaybe<PendingApprovalInput>;
  /** Bodyshop ID */
  bodyshopId?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** The duration of the repair in days */
  duration?: InputMaybe<RepairOfferDurationInput>;
  /** Repair offer estimation comment */
  estimationComment?: InputMaybe<Scalars['String']['input']>;
  /** The price of the repair */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The scope of the repair */
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSmsTemplateInput = {
  contents: Array<SmsTemplateContentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateSmsTemplateResult = {
  __typename?: 'CreateSmsTemplateResult';
  success: Scalars['Boolean']['output'];
  template: SmsTemplate;
};

export type CreateSteererInput = {
  /** List of Steerers to create */
  list: Array<CreateSteererItem>;
};

export type CreateSteererItem = {
  /** Steerer assigned bodyshop names */
  bodyshops?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Steerer API integration enabled */
  isApi: Scalars['Boolean']['input'];
  /** Steerer name (will be used in app link domain) */
  name: Scalars['String']['input'];
  /** Steerer title */
  title: Scalars['String']['input'];
  /** Steerer users */
  users: Array<SteererUser>;
};

export type CreateSteererResult = {
  __typename?: 'CreateSteererResult';
  /** Status of created steerers */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateUserInput = {
  /** User email */
  email: Scalars['String']['input'];
  /** User first name */
  firstName: Scalars['String']['input'];
  /** User last name */
  lastName: Scalars['String']['input'];
  /** User organizations */
  organizations?: InputMaybe<Array<CreateUserOrganizations>>;
  /** User role */
  role: AllowedCreateUserRoleType;
  /** Main Tenant ID in scope of user creation */
  tenantId?: InputMaybe<Scalars['String']['input']>;
  /** Trigger invitation email */
  triggerInvitationEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserOrganizations = {
  /** Restricted location */
  location?: InputMaybe<Scalars['String']['input']>;
  /** ID of the organization (CreateUserOptions.organization.id) */
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type DamageAuth = {
  __typename?: 'DamageAuth';
  /** Damage report token */
  code: Scalars['String']['output'];
};

export type DamageCategory = {
  __typename?: 'DamageCategory';
  /** Categories that belong to this damage category */
  categories: Array<Category>;
  /** ID of the damage category */
  id: Scalars['String']['output'];
  /** Name of the damage category */
  name: Scalars['String']['output'];
  /** Questions of the damage category */
  questions: Array<Question>;
  /** Sort number to order damage categories */
  sort: Scalars['Int']['output'];
  /** Type of the damage category */
  type: DamageCategoryType;
};

export enum DamageCategoryType {
  ABRASIONS = 'ABRASIONS',
  BURN_HOLES = 'BURN_HOLES',
  CIGARETTE_SMOKE_ODOUR = 'CIGARETTE_SMOKE_ODOUR',
  CRACK = 'CRACK',
  DAMAGED_TRUNK_SILL = 'DAMAGED_TRUNK_SILL',
  DENT = 'DENT',
  DIRTY_SEAT_BELTS = 'DIRTY_SEAT_BELTS',
  DIRT_AND_STAINS = 'DIRT_AND_STAINS',
  FLOOD = 'FLOOD',
  GLASS = 'GLASS',
  HAIL = 'HAIL',
  HOLES = 'HOLES',
  LIGHT = 'LIGHT',
  MATERIAL_DAMAGED = 'MATERIAL_DAMAGED',
  MECHANICAL = 'MECHANICAL',
  MIRROR = 'MIRROR',
  MISSING_PARTS = 'MISSING_PARTS',
  ODOUR = 'ODOUR',
  OLD_SEATS = 'OLD_SEATS',
  OTHER = 'OTHER',
  PETS_ODOUR = 'PETS_ODOUR',
  RED_ABS = 'RED_ABS',
  RED_AIRBAGS = 'RED_AIRBAGS',
  RED_BATTERY = 'RED_BATTERY',
  RED_BRAKE_SYSTEM = 'RED_BRAKE_SYSTEM',
  RED_BRAKE_WEAR = 'RED_BRAKE_WEAR',
  RED_COOLANT_TEMPERATURE = 'RED_COOLANT_TEMPERATURE',
  RED_MOTOR_CONTROL = 'RED_MOTOR_CONTROL',
  RED_OIL_LEVEL = 'RED_OIL_LEVEL',
  RED_TYRE_PRESSURE = 'RED_TYRE_PRESSURE',
  RIMS_TIRES = 'RIMS_TIRES',
  SCRATCH = 'SCRATCH',
  SCRATCHES = 'SCRATCHES',
  SOILING_AND_STAINGS = 'SOILING_AND_STAINGS',
  STONE_CHIPS = 'STONE_CHIPS',
  VISIBLE_COLOR_CHANGE = 'VISIBLE_COLOR_CHANGE',
  YELLOW_ABS = 'YELLOW_ABS',
  YELLOW_BATTERY = 'YELLOW_BATTERY',
  YELLOW_BRAKE_SYSTEM = 'YELLOW_BRAKE_SYSTEM',
  YELLOW_BRAKE_WEAR = 'YELLOW_BRAKE_WEAR',
  YELLOW_INSPECTION_IS_DUE = 'YELLOW_INSPECTION_IS_DUE',
  YELLOW_MOTOR_CONTROL = 'YELLOW_MOTOR_CONTROL',
  YELLOW_OIL_LEVEL = 'YELLOW_OIL_LEVEL',
  YELLOW_TYRE_PRESSURE = 'YELLOW_TYRE_PRESSURE'
}

export type DamageExternalDocument = {
  __typename?: 'DamageExternalDocument';
  /** The date that the external document has been created. */
  createdAt: Scalars['String']['output'];
  damageReportId: Scalars['String']['output'];
  /** Document comment. */
  documentComment?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Document type */
  type: DamageReportDocumentType;
  /** Document url */
  url: Scalars['String']['output'];
};

export type DamageInfoInput = {
  damageAddress: Scalars['String']['input'];
  damageDate: Scalars['Date']['input'];
};

export type DamageInformationInput = {
  accidentResponsibility?: InputMaybe<AccidentResponsibilityCauser>;
  damageAddress: Scalars['String']['input'];
  damageDate: Scalars['Date']['input'];
  /** The comment describing the details of the accident itself (not the damage) */
  damageDescription?: InputMaybe<Scalars['String']['input']>;
  /** Damage reason comment */
  damageReasonNote?: InputMaybe<Scalars['String']['input']>;
  /** Damage reasons */
  damageReasons?: InputMaybe<Array<AccidentDamageReasons>>;
  /** Description of the damaged object (Fixed object screen) */
  damagedObjectDescription?: InputMaybe<Scalars['String']['input']>;
  /** Desired Repair Address */
  desiredRepairAddress?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if a car is a prototype car */
  isPrototypeFlow?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DamageInsurance = {
  __typename?: 'DamageInsurance';
  id: Scalars['ID']['output'];
  /** Insurance connected to report */
  insuranceCompany?: Maybe<Insurance>;
  /** Insurance liability */
  insuranceLiability?: Maybe<InsuranceCoverageType>;
  /** Insurance number */
  insuranceNumber?: Maybe<Scalars['String']['output']>;
  /** Results of the insurance broker */
  resultsOfBroker?: Maybe<Scalars['String']['output']>;
};

export type DamagePhotosScreenConfiguration = {
  __typename?: 'DamagePhotosScreenConfiguration';
  /** Enables a possibility to upload images from a device storage */
  isImageLibraryEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Makes images on the screen optional (default is required) */
  isPhotoOptional?: Maybe<Scalars['Boolean']['output']>;
};

export type DamagePhotosScreenConfigurationInput = {
  /** Enables a possibility to upload images from a device storage */
  isImageLibraryEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Makes images on the screen optional (default is required) */
  isPhotoOptional?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DamageReasons = {
  /** Damage description */
  damageDescription?: InputMaybe<Scalars['String']['input']>;
  /** Damage reasons */
  damageReasons?: InputMaybe<Array<PreInspectionDamageReasons>>;
};

export type DamageReport = {
  __typename?: 'DamageReport';
  /** The car that the damage description belongs to. */
  car: Car;
  /** The car parts that the report has */
  carParts: Array<CarPart>;
  /** The date that the damage report has been created. */
  createdAt: Scalars['Date']['output'];
  /** Damage categories that this damage report has */
  damageCategories: Array<DamageCategory>;
  /** Number of the damage */
  damageNumber: Scalars['Int']['output'];
  /** Driver comment */
  driverComment?: Maybe<Scalars['String']['output']>;
  /** Driver name */
  driverName?: Maybe<Scalars['String']['output']>;
  /** Fleet comment */
  fleetComment?: Maybe<Scalars['String']['output']>;
  /** ID of the damage report */
  id: Scalars['ID']['output'];
  /** Images for the damage report */
  images: Array<DamageReportImage>;
  /** Indicates if damage is minor or not */
  isMinor?: Maybe<Scalars['Boolean']['output']>;
  /** Location */
  location?: Maybe<Scalars['String']['output']>;
  /** Damage report repair offers */
  repairOffers?: Maybe<Array<RepairOffer>>;
  /** Damage Status */
  status?: Maybe<Scalars['String']['output']>;
  /** Damage report type */
  type?: Maybe<DamageReportType>;
};

/** Name of damage report action group available for user on damage report page  */
export enum DamageReportActionGroupType {
  REPAIR = 'REPAIR',
  STATUS_CHANGE = 'STATUS_CHANGE'
}

export type DamageReportActionInput = {
  /** Damage report ID */
  damageReportId: Scalars['String']['input'];
  /** Name of damage report action */
  name: DamageReportActionName;
  /** Action input arguments */
  payload?: InputMaybe<DamageReportActionPayload>;
};

/** Name of damage report action available for user on damage report page */
export enum DamageReportActionName {
  APPROVE_ASSESSMENT = 'APPROVE_ASSESSMENT',
  APPROVE_ESTIMATION = 'APPROVE_ESTIMATION',
  APPROVE_REPAIR_OFFER = 'APPROVE_REPAIR_OFFER',
  APPROVE_REPAIR_OFFER_ADJUSTMENT = 'APPROVE_REPAIR_OFFER_ADJUSTMENT',
  APPROVE_REPAIR_OFFER_MECHANICAL = 'APPROVE_REPAIR_OFFER_MECHANICAL',
  ASSESSMENT_REPORT_UPLOAD = 'ASSESSMENT_REPORT_UPLOAD',
  ASSESSMENT_REQUEST = 'ASSESSMENT_REQUEST',
  ASSESSMENT_UPLOAD = 'ASSESSMENT_UPLOAD',
  CANCEL_REPAIR_OFFER = 'CANCEL_REPAIR_OFFER',
  DAMAGE_REPORT_CLOSE = 'DAMAGE_REPORT_CLOSE',
  DAMAGE_REPORT_CLOSED_UNDO = 'DAMAGE_REPORT_CLOSED_UNDO',
  DAMAGE_REPORT_INTERNALLY_REPAIRED_REOPEN = 'DAMAGE_REPORT_INTERNALLY_REPAIRED_REOPEN',
  DAMAGE_REPORT_INVOICE_UPLOAD = 'DAMAGE_REPORT_INVOICE_UPLOAD',
  DAMAGE_REPORT_INVOICE_VERIFY = 'DAMAGE_REPORT_INVOICE_VERIFY',
  DAMAGE_REPORT_POSTPONE_ESTIMATION = 'DAMAGE_REPORT_POSTPONE_ESTIMATION',
  DAMAGE_REPORT_REPAIR_CANCEL = 'DAMAGE_REPORT_REPAIR_CANCEL',
  DAMAGE_REPORT_REPAIR_FINISH = 'DAMAGE_REPORT_REPAIR_FINISH',
  DAMAGE_REPORT_REPAIR_REQUEST_CANCEL = 'DAMAGE_REPORT_REPAIR_REQUEST_CANCEL',
  DAMAGE_REPORT_REPAIR_REQUEST_CANCEL_AND_INTERNALLY_REPAIR = 'DAMAGE_REPORT_REPAIR_REQUEST_CANCEL_AND_INTERNALLY_REPAIR',
  DAMAGE_REPORT_REPAIR_REQUEST_CANCEL_AND_POSTPONE = 'DAMAGE_REPORT_REPAIR_REQUEST_CANCEL_AND_POSTPONE',
  DAMAGE_REPORT_REPAIR_START = 'DAMAGE_REPORT_REPAIR_START',
  DAMAGE_REPORT_REPAIR_START_FORCE = 'DAMAGE_REPORT_REPAIR_START_FORCE',
  DAMAGE_REPORT_SET_INTERNALLY_REPAIRED = 'DAMAGE_REPORT_SET_INTERNALLY_REPAIRED',
  DAMAGE_REPORT_SET_NEW = 'DAMAGE_REPORT_SET_NEW',
  DAMAGE_REPORT_VERIFY = 'DAMAGE_REPORT_VERIFY',
  OLD_APPROVE_REPAIR_OFFER = 'OLD_APPROVE_REPAIR_OFFER',
  OLD_APPROVE_REPAIR_OFFER_MECHANICAL = 'OLD_APPROVE_REPAIR_OFFER_MECHANICAL',
  REPAIR_DECISION = 'REPAIR_DECISION',
  REPAIR_OFFER_ADJUST = 'REPAIR_OFFER_ADJUST',
  REPAIR_OFFER_APPOINTMENT_CREATE = 'REPAIR_OFFER_APPOINTMENT_CREATE',
  REPAIR_OFFER_COMPLETE = 'REPAIR_OFFER_COMPLETE',
  REPAIR_OFFER_CREATE = 'REPAIR_OFFER_CREATE',
  REPAIR_OFFER_INSPECTION_ON_SITE_REQUEST = 'REPAIR_OFFER_INSPECTION_ON_SITE_REQUEST',
  REPAIR_OFFER_REQUEST = 'REPAIR_OFFER_REQUEST',
  REQUEST_ASSESSMENT_INSURANCE_COVERAGE = 'REQUEST_ASSESSMENT_INSURANCE_COVERAGE',
  REQUEST_ASSESSMENT_THIRD_PARTY_COVERAGE = 'REQUEST_ASSESSMENT_THIRD_PARTY_COVERAGE'
}

export type DamageReportActionPayload = {
  /** Assessor user id */
  assessorId?: InputMaybe<Scalars['String']['input']>;
  /** Car location */
  carLocation?: InputMaybe<Scalars['String']['input']>;
  /** Action reason */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** Repair offer ID */
  repairOfferId?: InputMaybe<Scalars['ID']['input']>;
  /** Request comment */
  requestComment?: InputMaybe<Scalars['String']['input']>;
};

export type DamageReportActionResult = {
  __typename?: 'DamageReportActionResult';
  /** Short action description */
  description?: Maybe<Scalars['String']['output']>;
  /** Action group type */
  groupType: DamageReportActionGroupType;
  /** Is action hidden (part of a composite action) */
  isHidden: Scalars['Boolean']['output'];
  /** Checklist information */
  isMain: Scalars['Boolean']['output'];
  /** Action name */
  name: DamageReportActionName;
  /**
   * Status transition
   * @deprecated Used to determine next damage status and attempt the transition to the new status. A new "performDamageAction" mutation needs to be used instead
   */
  statusTransition?: Maybe<DamageReportStatus>;
};

export type DamageReportCar = {
  __typename?: 'DamageReportCar';
  /** License plate number of the car. */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Current location. */
  location?: Maybe<Scalars['String']['output']>;
  /** Make name. */
  make?: Maybe<Scalars['String']['output']>;
  /** Model name. */
  model?: Maybe<Scalars['String']['output']>;
  /** VIN code of the car. */
  vin?: Maybe<Scalars['String']['output']>;
};

export enum DamageReportCloseReasonType {
  DUPLICATE = 'DUPLICATE',
  NOT_A_DAMAGE = 'NOT_A_DAMAGE',
  OTHER = 'OTHER',
  REPAIRED = 'REPAIRED',
  WILL_NO_BE_REPAIRED = 'WILL_NO_BE_REPAIRED',
  WRONG_DAMAGE = 'WRONG_DAMAGE'
}

export type DamageReportComparisonImage = {
  __typename?: 'DamageReportComparisonImage';
  /** An image damage report ID */
  damageReportId: Scalars['String']['output'];
  /** An image damage report number */
  damageReportNumber: Scalars['Float']['output'];
  /** An image URL */
  fullUrl: Scalars['String']['output'];
  /** An image ID */
  id: Scalars['ID']['output'];
  /** Shows whether an image visibility is restricted */
  isVisibilityRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Type of an image */
  type: DamageReportComparisonImageType;
};

export enum DamageReportComparisonImageType {
  DASHBOARD = 'DASHBOARD',
  INTERIOR_LEFT = 'INTERIOR_LEFT',
  INTERIOR_RIGHT = 'INTERIOR_RIGHT',
  LEFT_DIAGONAL_VIEW = 'LEFT_DIAGONAL_VIEW',
  RIGHT_DIAGONAL_VIEW = 'RIGHT_DIAGONAL_VIEW',
  VEHICLE_DRIVER_SIDE_VIEW = 'VEHICLE_DRIVER_SIDE_VIEW',
  VEHICLE_FRONT_SIDE_VIEW = 'VEHICLE_FRONT_SIDE_VIEW',
  VEHICLE_PASSENGER_SIDE_VIEW = 'VEHICLE_PASSENGER_SIDE_VIEW',
  VEHICLE_REAR_SIDE_VIEW = 'VEHICLE_REAR_SIDE_VIEW'
}

export type DamageReportDocumentList = {
  __typename?: 'DamageReportDocumentList';
  documents: Array<DamageReportDocumentListItem>;
  /** total number of cars */
  total: Scalars['Float']['output'];
};

export type DamageReportDocumentListItem = {
  __typename?: 'DamageReportDocumentListItem';
  /** Document created at */
  createdAt: Scalars['Date']['output'];
  /** Document Comment */
  documentComment?: Maybe<Scalars['String']['output']>;
  /** Document id */
  id: Scalars['String']['output'];
  /** Is document locked for current user */
  isLocked: Scalars['Boolean']['output'];
  /** Document kind */
  kind: AggregatedDocumentKind;
  name: Scalars['String']['output'];
  /** Document subType */
  subType?: Maybe<DamageReportDocumentSubType>;
  /** Document type */
  type?: Maybe<Scalars['String']['output']>;
  uploadedBy?: Maybe<DocumentCreator>;
  url: Scalars['String']['output'];
};

export type DamageReportDocumentMetadata = {
  __typename?: 'DamageReportDocumentMetadata';
  /** Whether the document has been signed */
  isSigned: Scalars['Boolean']['output'];
  /** Signature placeholder positions */
  signaturePlacements: Array<DamageReportDocumentSignaturePlacement>;
};

export type DamageReportDocumentMetadataInput = {
  /** Document pages allowed to display to driver */
  driverAllowedPages?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Signature placeholder positions */
  signaturePlacements: Array<DamageReportDocumentSignaturePlacementInput>;
};

export type DamageReportDocumentSignaturePlacement = {
  __typename?: 'DamageReportDocumentSignaturePlacement';
  /** Signature coordinates */
  coordinates: SignatureCoordinates;
  /** Page number */
  page: Scalars['Float']['output'];
};

export type DamageReportDocumentSignaturePlacementInput = {
  /** Signature coordinates */
  coordinates: SignatureCoordinatesInput;
  /** Page number */
  page: Scalars['Float']['input'];
};

export enum DamageReportDocumentSubType {
  AGGREGATED = 'AGGREGATED',
  ASSESSMENT = 'ASSESSMENT',
  CAR_RENTAL = 'CAR_RENTAL',
  OTHER = 'OTHER',
  REPAIR = 'REPAIR',
  TOWING_SERVICE = 'TOWING_SERVICE'
}

export enum DamageReportDocumentType {
  ACCIDENT_REPORT = 'ACCIDENT_REPORT',
  ASSESSOR_ESTIMATION = 'ASSESSOR_ESTIMATION',
  INVOICE = 'INVOICE',
  OTHER = 'OTHER',
  THIRD_PARTY_DAMAGE_REPORT = 'THIRD_PARTY_DAMAGE_REPORT'
}

/** DamageReportDrivableType */
export enum DamageReportDrivableType {
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES'
}

/** List of statuses that could be used for damage list filtering */
export enum DamageReportFilteringStatus {
  ASSESSMENT_PROVIDED = 'ASSESSMENT_PROVIDED',
  ASSESSMENT_REQUESTED = 'ASSESSMENT_REQUESTED',
  CLOSED = 'CLOSED',
  DAMAGE_REQUEST_RECEIVED = 'DAMAGE_REQUEST_RECEIVED',
  FLEET_MANAGER_APPROVAL_RECEIVED = 'FLEET_MANAGER_APPROVAL_RECEIVED',
  FLEET_MANAGER_APPROVAL_REJECTED = 'FLEET_MANAGER_APPROVAL_REJECTED',
  FLEET_MANAGER_APPROVAL_REQUESTED = 'FLEET_MANAGER_APPROVAL_REQUESTED',
  INSPECTION_ON_SITE_APPROVED = 'INSPECTION_ON_SITE_APPROVED',
  INSPECTION_REJECTED = 'INSPECTION_REJECTED',
  INSPECTION_REQUESTED = 'INSPECTION_REQUESTED',
  INSPECTION_SCHEDULED = 'INSPECTION_SCHEDULED',
  INSURANCE_APPROVAL_RECEIVED = 'INSURANCE_APPROVAL_RECEIVED',
  INSURANCE_APPROVAL_REQUESTED = 'INSURANCE_APPROVAL_REQUESTED',
  INTERNALLY_REPAIRED = 'INTERNALLY_REPAIRED',
  INVOICE_SETTLED = 'INVOICE_SETTLED',
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  IN_RECOGNITION = 'IN_RECOGNITION',
  LEASING_APPROVAL_RECEIVED = 'LEASING_APPROVAL_RECEIVED',
  LEASING_APPROVAL_REQUESTED = 'LEASING_APPROVAL_REQUESTED',
  LINK_SENT = 'LINK_SENT',
  NEW = 'NEW',
  REPAIR_APPOINTMENT_SCHEDULED = 'REPAIR_APPOINTMENT_SCHEDULED',
  REPAIR_APPOINTMENT_SUGGESTED = 'REPAIR_APPOINTMENT_SUGGESTED',
  REPAIR_ESTIMATED = 'REPAIR_ESTIMATED',
  REPAIR_FINISHED = 'REPAIR_FINISHED',
  REPAIR_OFFER_ADJUSTED = 'REPAIR_OFFER_ADJUSTED',
  REPAIR_POSTPONED = 'REPAIR_POSTPONED',
  REPAIR_REJECTED = 'REPAIR_REJECTED',
  REPAIR_REQUESTED = 'REPAIR_REQUESTED',
  REPAIR_STARTED = 'REPAIR_STARTED',
  SERVICE_PROVIDER_ASSIGNED = 'SERVICE_PROVIDER_ASSIGNED',
  TO_REVIEW = 'TO_REVIEW'
}

export enum DamageReportFilteringType {
  ACCIDENT = 'ACCIDENT',
  COMPOUND = 'COMPOUND',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  MECHANICAL = 'MECHANICAL',
  NON_ACCIDENT = 'NON_ACCIDENT',
  PRE_INSPECTION = 'PRE_INSPECTION',
  SERVICE_LIGHTS = 'SERVICE_LIGHTS',
  TELEMATIC = 'TELEMATIC',
  THIRD_PARTY = 'THIRD_PARTY'
}

export type DamageReportImage = {
  __typename?: 'DamageReportImage';
  /** Category, assigned based on the image type. */
  category: DamageReportImageCategory;
  /** Damage report image creation date */
  createdAt: Scalars['Date']['output'];
  /** URL for the image */
  fullUrl: Scalars['String']['output'];
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Was the the image returned by damage recognition (AI provider) */
  isAnnotated?: Maybe<Scalars['Boolean']['output']>;
  /** Shows whether it is possible to delete a photo */
  isDeletable: Scalars['Boolean']['output'];
  /** Image visibility restricted */
  isVisibilityRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Type of the image */
  type: DamageReportImageType;
};

/** Category, assigned based on the image type. */
export enum DamageReportImageCategory {
  DAMAGE = 'DAMAGE',
  OTHER = 'OTHER'
}

/** Possible damage report image types. */
export enum DamageReportImageType {
  ACCIDENT_DOCUMENT = 'ACCIDENT_DOCUMENT',
  ACCIDENT_REPORT = 'ACCIDENT_REPORT',
  ACCIDENT_SCENE = 'ACCIDENT_SCENE',
  ADDITIONAL = 'ADDITIONAL',
  CHECKLIST_DUPLICATE = 'CHECKLIST_DUPLICATE',
  CLOSE_UP = 'CLOSE_UP',
  DAMAGED_OBJECT = 'DAMAGED_OBJECT',
  DASHBOARD = 'DASHBOARD',
  FRONT_LEFT_ANGLE = 'FRONT_LEFT_ANGLE',
  INSPECTION = 'INSPECTION',
  INTERIOR = 'INTERIOR',
  INTERIOR_LEFT = 'INTERIOR_LEFT',
  INTERIOR_RIGHT = 'INTERIOR_RIGHT',
  LEFT_DIAGONAL_VIEW = 'LEFT_DIAGONAL_VIEW',
  OTHER_PARTY_DRIVER_LICENSE = 'OTHER_PARTY_DRIVER_LICENSE',
  OTHER_PARTY_VEHICLE = 'OTHER_PARTY_VEHICLE',
  POLICE_REPORT = 'POLICE_REPORT',
  REAR_RIGHT_ANGLE = 'REAR_RIGHT_ANGLE',
  REAR_SIDE_GENERAL = 'REAR_SIDE_GENERAL',
  REGISTRATION_DOCUMENT = 'REGISTRATION_DOCUMENT',
  RIGHT_DIAGONAL_VIEW = 'RIGHT_DIAGONAL_VIEW',
  SILHOUETTE_OF_CAR = 'SILHOUETTE_OF_CAR',
  UPLOADED = 'UPLOADED',
  VEHICLE_DRIVER_SIDE_VIEW = 'VEHICLE_DRIVER_SIDE_VIEW',
  VEHICLE_FRONT_SIDE_VIEW = 'VEHICLE_FRONT_SIDE_VIEW',
  VEHICLE_PASSENGER_SIDE_VIEW = 'VEHICLE_PASSENGER_SIDE_VIEW',
  VEHICLE_REAR_SIDE_VIEW = 'VEHICLE_REAR_SIDE_VIEW',
  VIEW_OF_DAMAGE = 'VIEW_OF_DAMAGE',
  WILDLIFE_PROTECTION_LICENSE_NUMBER = 'WILDLIFE_PROTECTION_LICENSE_NUMBER'
}

export type DamageReportImagesUpload = {
  /** The category of the image */
  category?: InputMaybe<DamageReportImageCategory>;
  /** File for upload */
  file: Scalars['Upload']['input'];
  /** Image visibility restricted */
  isVisibilityRestricted: Scalars['Boolean']['input'];
};

export type DamageReportInfo = {
  __typename?: 'DamageReportInfo';
  /** A list of suggested additional services */
  additionalServices?: Maybe<Array<AdditionalService>>;
  /** Bodyshop assigned to damage report in case of pre-inspection */
  bodyshop?: Maybe<Bodyshop>;
  /** The car that the damage description belongs to. */
  car?: Maybe<CarInfo>;
  /** The car parts that the report has */
  carParts: Array<CarPart>;
  /** Created date */
  createdAt: Scalars['Date']['output'];
  /** Damage categories that this damage report has */
  damageCategories?: Maybe<Array<DamageCategory>>;
  /** Damage information */
  damageInformation: DamageReportInformation;
  /** Damage number */
  damageNumber: Scalars['Float']['output'];
  /** The car that the damage description belongs to. */
  damageReportCar?: Maybe<DamageReportCar>;
  /** Desired repair address */
  desiredRepairAddress?: Maybe<Scalars['String']['output']>;
  documents: Array<DamageReportSignedDocumentInfo>;
  /** Driver phone */
  driverPhone?: Maybe<Scalars['String']['output']>;
  /** Driver type in case of pre-inspection report */
  driverType?: Maybe<DriverType>;
  /** Fleet comment */
  fleetComment?: Maybe<Scalars['String']['output']>;
  /** ID of the damage report */
  id: Scalars['ID']['output'];
  /** Images for the damage report */
  images: Array<DamageReportImage>;
  /** Is a damage accident */
  isAccident: Scalars['Boolean']['output'];
  /** Is a damage minor */
  isMinor?: Maybe<Scalars['Boolean']['output']>;
  /** A list of legal agreements */
  legalAgreements?: Maybe<Array<DamageReportLegalAgreementInfo>>;
  /** Car license plate in case of pre-inspection report */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Link sent email */
  linkSentEmail?: Maybe<Scalars['String']['output']>;
  /** Damage report repair offer if accepted */
  repairOffer?: Maybe<RepairOfferInfo>;
  /** Reporter email */
  reporterEmail?: Maybe<Scalars['String']['output']>;
  /** Reporter name */
  reporterName?: Maybe<Scalars['String']['output']>;
  /** Damage Status */
  status?: Maybe<Scalars['String']['output']>;
  /** Damage Report Type */
  type: DamageReportType;
};

export type DamageReportInformation = {
  __typename?: 'DamageReportInformation';
  /** Accident responsibility */
  accidentResponsibility?: Maybe<AccidentResponsibilityCauser>;
  /** Damage address */
  damageAddress?: Maybe<Scalars['String']['output']>;
  /** Damage categories that this damage report has */
  damageCategories?: Maybe<Array<DamageCategory>>;
  /** An accident date and time */
  damageDate?: Maybe<Scalars['Date']['output']>;
  /** Damage Description */
  damageDescription?: Maybe<Scalars['String']['output']>;
  /** Damage reason comment */
  damageReasonComment?: Maybe<Scalars['String']['output']>;
  /** Damage reason note */
  damageReasonNote?: Maybe<Scalars['String']['output']>;
  /** Damage reasons */
  damageReasons?: Maybe<Array<AllDamageReasons>>;
  /** Description of the damaged object (Fixed object screen) */
  damagedObjectDescription?: Maybe<Scalars['String']['output']>;
  /** Desired repair address */
  desiredRepairAddress?: Maybe<Scalars['String']['output']>;
  /** Insurance coverage */
  insuranceCoverage?: Maybe<DamageReportInsuranceCoverageType>;
  /** Indicates whether a car is a prototype car or not */
  isPrototypeFlow?: Maybe<Scalars['Boolean']['output']>;
  /** Steerer comment */
  steererComment?: Maybe<Scalars['String']['output']>;
};

/** Define the damage report insurance types. */
export enum DamageReportInsuranceCoverageType {
  BREAKDOWN = 'BREAKDOWN',
  FULL = 'FULL',
  NOT_COVERED = 'NOT_COVERED',
  PARTIAL = 'PARTIAL',
  THIRD_PARTY = 'THIRD_PARTY',
  UNKNOWN = 'UNKNOWN'
}

export type DamageReportLegalAgreement = {
  __typename?: 'DamageReportLegalAgreement';
  isAccepted: Scalars['Boolean']['output'];
  timestamp: Scalars['Date']['output'];
  type: LegalAgreementType;
};

export type DamageReportLegalAgreementInfo = {
  __typename?: 'DamageReportLegalAgreementInfo';
  isAccepted: Scalars['Boolean']['output'];
  timestamp: Scalars['Date']['output'];
  type: LegalAgreementType;
};

export type DamageReportListItem = {
  __typename?: 'DamageReportListItem';
  /** Assigned user name */
  assignee?: Maybe<DamageReportListItemAssignee>;
  /** Bodyshop title */
  bodyshopTitle?: Maybe<Scalars['String']['output']>;
  /** The car that the damage description belongs to. */
  car?: Maybe<DamageReportListItemCar>;
  /** The car parts that the report has */
  carParts?: Maybe<Array<CarPart>>;
  /** Car registration company */
  carRegistrationCompany?: Maybe<Scalars['String']['output']>;
  /** comment */
  comment?: Maybe<ExternalDataObjectType>;
  /** The date that the damage report has been created. */
  createdAt: Scalars['Date']['output'];
  /** damageAreas for table */
  damageArea?: Maybe<Array<Scalars['String']['output']>>;
  /** Damage report information details */
  damageInformation?: Maybe<DamageReportInformation>;
  /** Damage number (internal) */
  damageNumber: Scalars['Int']['output'];
  /** damageTypes for table */
  damageType?: Maybe<Array<Scalars['String']['output']>>;
  /** Driver name */
  driverName?: Maybe<Scalars['String']['output']>;
  /** externalId */
  externalId?: Maybe<ExternalDataObjectType>;
  /** filteringStatuses */
  filteringStatuses?: Maybe<Array<Scalars['String']['output']>>;
  /** Fleet repair price */
  fleetRepairPrice?: Maybe<Scalars['Float']['output']>;
  /** ID of the damage report */
  id: Scalars['ID']['output'];
  /** Indicates damage report as a by-product of accident. Is TRUE if damage report type is COMPOUND or damage report type is EXTERIOR and isAccident flag is set to TRUE */
  isAccident: Scalars['Boolean']['output'];
  isDrivable: DamageReportDrivableType;
  /** Indicates if damage is minor or not */
  isMinor?: Maybe<Scalars['Boolean']['output']>;
  /** Location */
  location?: Maybe<Scalars['String']['output']>;
  /** Number of the damage */
  number?: Maybe<Scalars['String']['output']>;
  /** Partner id of the damage */
  partnerId?: Maybe<Scalars['String']['output']>;
  /** Damage report repair offer if accepted */
  repairOffer?: Maybe<AcceptedRepairOfferResult>;
  /** Reported by user name */
  reporterName?: Maybe<Scalars['String']['output']>;
  /** Steerer name */
  steererName?: Maybe<Scalars['String']['output']>;
  /** tags */
  tags?: Maybe<Array<ExternalDataObjectType>>;
  /** Tenant name */
  tenant?: Maybe<Scalars['String']['output']>;
  /** Type of the damage report */
  type: DamageReportType;
};

export type DamageReportListItemAssignee = {
  __typename?: 'DamageReportListItemAssignee';
  /** Car make name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** If TRUE - a user an access the platform. If FALSE - can't */
  isEnabled: Scalars['Boolean']['output'];
  /** Car model name */
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DamageReportListItemCar = {
  __typename?: 'DamageReportListItemCar';
  /** Car bodyStyle */
  bodyStyle?: Maybe<Scalars['String']['output']>;
  /** Car firstRegistration date */
  firstRegistration?: Maybe<Scalars['Date']['output']>;
  /** Fleet */
  fleet?: Maybe<FleetWithConfiguration>;
  /** Car licensePlate */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Car make name */
  make?: Maybe<Scalars['String']['output']>;
  /** Car model name */
  model?: Maybe<Scalars['String']['output']>;
  /** Car partnerId */
  partnerId?: Maybe<Scalars['String']['output']>;
  /** Car vin */
  vin?: Maybe<Scalars['String']['output']>;
};

export type DamageReportRelatedItemType = {
  __typename?: 'DamageReportRelatedItemType';
  /** The date that the damage report has been created. */
  createdAt: Scalars['Date']['output'];
  /** damageAreas for table */
  damageArea?: Maybe<Array<Scalars['String']['output']>>;
  /** damageTypes for table */
  damageType?: Maybe<Array<Scalars['String']['output']>>;
  /** ID of the damage report */
  id: Scalars['ID']['output'];
  /** Images for the damage report */
  images?: Maybe<Array<DamageReportImage>>;
  /** Number of the damage */
  number?: Maybe<Scalars['String']['output']>;
  /** Type of the damage report */
  type: DamageReportType;
};

/** List of statuses that could be used for status road map */
export enum DamageReportRoadMapStatus {
  ASSESSMENT = 'ASSESSMENT',
  CLOSED = 'CLOSED',
  FLEET_APPROVAL = 'FLEET_APPROVAL',
  INSPECTION_ON_SITE = 'INSPECTION_ON_SITE',
  INSURANCE_APPROVAL = 'INSURANCE_APPROVAL',
  IN_REPAIR = 'IN_REPAIR',
  IN_SETTLEMENT = 'IN_SETTLEMENT',
  LEASING_APPROVAL = 'LEASING_APPROVAL',
  OPEN = 'OPEN',
  REPAIR_APPOINTMENT = 'REPAIR_APPOINTMENT',
  REPAIR_DECISION = 'REPAIR_DECISION',
  REPAIR_ESTIMATE = 'REPAIR_ESTIMATE',
  SERVICE_PROVIDER_ASSIGNMENT = 'SERVICE_PROVIDER_ASSIGNMENT'
}

export type DamageReportSignedDocument = {
  __typename?: 'DamageReportSignedDocument';
  createdAt: Scalars['Date']['output'];
  damageReportId: Scalars['String']['output'];
  /** Signed document ID */
  id: Scalars['ID']['output'];
  isSharedWithDriver: Scalars['Boolean']['output'];
  isUploadedBySteerer: Scalars['Boolean']['output'];
  metadata?: Maybe<DamageReportDocumentMetadata>;
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  type: SignedDocumentType;
  updatedAt: Scalars['Date']['output'];
  url: Scalars['String']['output'];
};

export type DamageReportSignedDocumentInfo = {
  __typename?: 'DamageReportSignedDocumentInfo';
  createdAt: Scalars['Date']['output'];
  damageReportId: Scalars['String']['output'];
  /** Signed document ID */
  id: Scalars['ID']['output'];
  isSharedWithDriver: Scalars['Boolean']['output'];
  isUploadedBySteerer: Scalars['Boolean']['output'];
  metadata?: Maybe<DamageReportDocumentMetadata>;
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  type: SignedDocumentType;
  updatedAt: Scalars['Date']['output'];
  url: Scalars['String']['output'];
};

export type DamageReportSignedDocumentInput = {
  /** Document file for upload */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** Id of the signed document, should be provided for document update */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Is full document shared with driver */
  isSharedWithDriver?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<DamageReportDocumentMetadataInput>;
  /** Typeof uploaded document */
  type?: InputMaybe<SignedDocumentType>;
};

/** List of statues of damage report */
export enum DamageReportStatus {
  CLOSED = 'CLOSED',
  DAMAGE_REQUEST_RECEIVED = 'DAMAGE_REQUEST_RECEIVED',
  INDICATED = 'INDICATED',
  INTERNALLY_REPAIRED = 'INTERNALLY_REPAIRED',
  IN_RECOGNITION = 'IN_RECOGNITION',
  IN_REPAIR = 'IN_REPAIR',
  IN_SETTLEMENT = 'IN_SETTLEMENT',
  LINK_GENERATED = 'LINK_GENERATED',
  LINK_SENT = 'LINK_SENT',
  NEW = 'NEW',
  REPAIRED = 'REPAIRED',
  REPAIR_POSTPONED = 'REPAIR_POSTPONED',
  REPAIR_REQUESTED = 'REPAIR_REQUESTED',
  SETTLED = 'SETTLED',
  TO_REVIEW = 'TO_REVIEW'
}

/** List of damage report statuses, which are eligible to be updated to */
export enum DamageReportStatusAllowedForUpdate {
  CLOSED = 'CLOSED',
  INTERNALLY_REPAIRED = 'INTERNALLY_REPAIRED',
  IN_REPAIR = 'IN_REPAIR',
  NEW = 'NEW',
  REPAIR_POSTPONED = 'REPAIR_POSTPONED',
  REPAIR_REQUESTED = 'REPAIR_REQUESTED'
}

export enum DamageReportType {
  COMPOUND = 'COMPOUND',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  MECHANICAL = 'MECHANICAL',
  PRE_INSPECTION = 'PRE_INSPECTION',
  SERVICE_LIGHTS = 'SERVICE_LIGHTS',
  TELEMATIC = 'TELEMATIC',
  THIRD_PARTY = 'THIRD_PARTY'
}

export type DamageReportWildlifeDataInput = {
  /** A wildlife protection authority office */
  authorityOffice?: InputMaybe<Scalars['String']['input']>;
  /** A wildlife protection license number */
  wildlifeProtectionLicenseNumber?: InputMaybe<Scalars['String']['input']>;
};

export type DamageReportWildlifeDataResult = {
  __typename?: 'DamageReportWildlifeDataResult';
  /** A wildlife protection authority office */
  authorityOffice?: Maybe<Scalars['String']['output']>;
  /** A wildlife protection license number */
  wildlifeProtectionLicenseNumber?: Maybe<Scalars['String']['output']>;
};

export type DamageReports = {
  __typename?: 'DamageReports';
  reports: Array<DamageReportListItem>;
  /** total number of damage reports */
  total: Scalars['Float']['output'];
};

export type DamageReportsCountResult = {
  __typename?: 'DamageReportsCountResult';
  /** The number of damage reports */
  count: Scalars['Int']['output'];
};

export type DamageReportsInput = {
  /** Current step name, is unique. */
  currentStepName?: InputMaybe<Scalars['String']['input']>;
};

export type DamageReportsListFilterArgs = {
  /** Assignee name */
  assignee?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Bodyshop name */
  bodyshop?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Id of the car */
  carId?: InputMaybe<Scalars['ID']['input']>;
  /** Returns damage reports related to a particular checklist report */
  checklistReportId?: InputMaybe<Scalars['ID']['input']>;
  /** Damaged area */
  damageArea?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Damage type */
  damageType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Fleet name */
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Damage reported from date */
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  /** Flag to show closed and rejected statuses on the list */
  includeArchived?: Scalars['Boolean']['input'];
  /** Defines insurance coverage type to filter with. Makes sense for Compound and Exterior (accidents only) damage reports. */
  insuranceCoverageType?: InputMaybe<Array<DamageReportInsuranceCoverageType>>;
  /** Damage reports count on the page */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Damage Report Location */
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car manufacturer name */
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car model name */
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Start page */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by car properties */
  q?: InputMaybe<Scalars['String']['input']>;
  /** Roadworthy status */
  roadworthy?: InputMaybe<Array<DamageReportDrivableType>>;
  /** Damage reports sort by */
  sortBy?: InputMaybe<ReportsSortBy>;
  /** Damage reports sort order */
  sortOrder?: InputMaybe<SortOrder>;
  /** Damage Report Status */
  status?: InputMaybe<Array<DamageReportFilteringStatus>>;
  /** Steerer name */
  steerer?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tags values */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Damage reported to date */
  toDate?: InputMaybe<Scalars['Date']['input']>;
  /** Damage Report Type */
  type?: InputMaybe<Array<DamageReportFilteringType>>;
};

export type DamageReportsRelated = {
  __typename?: 'DamageReportsRelated';
  /** List of related damage reports */
  reports: Array<DamageReportRelatedItemType>;
  /** total number of damage reports */
  total: Scalars['Float']['output'];
};

export type DamageStatisticsByTypeResult = {
  __typename?: 'DamageStatisticsByTypeResult';
  /** Result of the invoice upload */
  byType: TotalDamagesByType;
  /** Total damages count */
  totalDamages: TotalDamagesByPeriod;
};

export enum DashboardFilterSupportedKeys {
  FLEET = 'FLEET'
}

export type DataExportResult = {
  __typename?: 'DataExportResult';
  /** data */
  data?: Maybe<Scalars['String']['output']>;
  /** data */
  rowsToProcess?: Maybe<Scalars['Float']['output']>;
  /** Status of the archivation */
  status?: Maybe<Scalars['String']['output']>;
};

/** The source of damage report. Does not necessarily directly map to the application name. */
export enum DataSource {
  BODYSHOP_APPLICATION = 'BODYSHOP_APPLICATION',
  DRIVER_APPLICATION = 'DRIVER_APPLICATION',
  FLEET_MANAGER_APPLICATION = 'FLEET_MANAGER_APPLICATION'
}

/** Type of the dealer management system */
export enum DealerManagementSystem {
  ABISKFZ = 'AbisKfz',
  CARISMA = 'Carisma',
  KSR = 'KSR',
  OTHER = 'Other',
  PDRCLOUD = 'PdrCloud',
  PLANSO = 'Planso',
  PROCESSMANAGER = 'ProcessManager',
  SFKBUCHNER = 'SfkBuchner'
}

export type DefaultManager = {
  __typename?: 'DefaultManager';
  /** Status of creating fleet */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCarInput = {
  /** Car ID */
  carId: Scalars['String']['input'];
};

export type DeleteChecklistDamageResult = {
  __typename?: 'DeleteChecklistDamageResult';
  /** ID of the deleted damage report */
  damageReportId: Scalars['String']['output'];
  /** Whether it was deleted */
  isDeleted: Scalars['Boolean']['output'];
};

export type DeleteChecklistReportImageInput = {
  /** A checklist report id */
  id: Scalars['String']['input'];
  /** Checklist report images to delete IDs */
  imageIDs: Array<Scalars['ID']['input']>;
};

export type DeleteCostInput = {
  /** Cost ID */
  id: Scalars['String']['input'];
};

export type DeleteDamageReportDocumentsInput = {
  /** A damage report ID */
  damageReportId: Scalars['ID']['input'];
  /** Documents IDs to delete */
  deleteDocumentIDs: Array<Scalars['ID']['input']>;
};

export type DeleteDamageReportImageInput = {
  /** Damage Report ID */
  id: Scalars['ID']['input'];
  /** Damage report images to delete IDs */
  imageIDs: Array<Scalars['ID']['input']>;
};

export type DocumentCreator = DocumentCreatorCompany | DocumentCreatorUser;

export type DocumentCreatorCompany = {
  __typename?: 'DocumentCreatorCompany';
  /** Company ID */
  id: Scalars['String']['output'];
  /** User role */
  role: UserRole;
  /** Company title */
  title: Scalars['String']['output'];
};

export type DocumentCreatorUser = {
  __typename?: 'DocumentCreatorUser';
  /** User first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** User ID */
  id: Scalars['String']['output'];
  /** User last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** User role */
  role: UserRole;
};

export type Driver = {
  __typename?: 'Driver';
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type DriverAcceptAppointmentInput = {
  /** Appointment date */
  appointmentISO: Scalars['Date']['input'];
  /** Appointment ID */
  appointmentId: Scalars['ID']['input'];
  /** Repair Offer ID */
  repairOfferId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverFacingAppConfiguration = {
  __typename?: 'DriverFacingAppConfiguration';
  isEnabled: Scalars['Boolean']['output'];
  isNewDriverApp?: Maybe<Scalars['Boolean']['output']>;
  /** Is separated app (from parent) */
  isSeparated?: Maybe<Scalars['Boolean']['output']>;
  /** App specific modules configuration */
  modules?: Maybe<ModulesConfiguration>;
  screens?: Maybe<Scalars['JSONObject']['output']>;
  settings?: Maybe<AppSettings>;
};

export type DriverFacingAppConfigurationInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNewDriverApp?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is separated app (from parent) */
  isSeparated?: InputMaybe<Scalars['Boolean']['input']>;
  /** App specific modules configuration */
  modules?: InputMaybe<ModulesConfigurationInput>;
  screens?: InputMaybe<DriverFacingAppScreenConfigurationInput>;
  settings?: InputMaybe<AppSettingsInput>;
};

export type DriverFacingAppScreenConfigurationInput = {
  /** Accident driver information screen configuration */
  ACCIDENT_DRIVER_INFORMATION?: InputMaybe<AccidentDriverInformationConfigurationInput>;
  /** Accident screen configuration */
  ACCIDENT_SCENE?: InputMaybe<AccidentScreenConfigurationInput>;
  /** Car identification screen configuration */
  CAR_IDENTIFICATION?: InputMaybe<CarIdentificationConfigurationInput>;
  /** Damage photos screen configuration */
  DAMAGE_PHOTOS?: InputMaybe<DamagePhotosScreenConfigurationInput>;
  /** Welcome screen configuration */
  WELCOME_SCREEN?: InputMaybe<WelcomeScreenConfigurationInput>;
};

export type DriverIdentificationModuleConfiguration = {
  __typename?: 'DriverIdentificationModuleConfiguration';
  drivers: Array<Driver>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type DriverInfoInput = {
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  driverCity?: InputMaybe<Scalars['String']['input']>;
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  driverName?: InputMaybe<Scalars['String']['input']>;
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  driverZip?: InputMaybe<Scalars['String']['input']>;
};

export type DriverInformConsent = {
  __typename?: 'DriverInformConsent';
  /** Accident module overview screen configuration */
  overview?: Maybe<AccidentModuleOverviewScreenConfiguration>;
  /** Driver inform consent value */
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverInformation = {
  /** Client number */
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  /** Address */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** City */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Driver email */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Driver name */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Driver phone */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** ZIP code */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** Privacy flag */
  isPrivacyAndTermsSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DriverInformationInput = {
  /** Client number */
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  /** Address */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** City */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Driver email */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Driver name */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Driver phone */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** ZIP code */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** Privacy flag */
  isPrivacyAndTermsSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DriverInformationModuleConfiguration = {
  __typename?: 'DriverInformationModuleConfiguration';
  /** Driver information screen configuration */
  fields?: Maybe<Array<DriverInformationScreenField>>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type DriverInformationModuleConfigurationInput = {
  /** Driver information screen configuration */
  fields?: InputMaybe<Array<DriverInformationScreenFieldInput>>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['input'];
};

export type DriverInformationScreenField = {
  __typename?: 'DriverInformationScreenField';
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  translationKey?: Maybe<Scalars['String']['output']>;
};

export type DriverInformationScreenFieldInput = {
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  name: DriverInformationScreenFieldsType;
  translationKey?: InputMaybe<Scalars['String']['input']>;
};

/** The type of driver information screen field. */
export enum DriverInformationScreenFieldsType {
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  CLIENT_NUMBER = 'CLIENT_NUMBER',
  COMPANY_AFFILIATION = 'COMPANY_AFFILIATION',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  PERSONAL_NUMBER = 'PERSONAL_NUMBER',
  PHONE = 'PHONE',
  REPORTED_BY = 'REPORTED_BY',
  TERMS_AND_CONDITION = 'TERMS_AND_CONDITION',
  TITLE = 'TITLE',
  ZIP = 'ZIP'
}

export type DriverRequestCustomAppointmentInput = {
  /** Repair Offer ID */
  repairOfferId?: InputMaybe<Scalars['ID']['input']>;
};

/** Define the driver type: steered, fleet, private, etc. */
export enum DriverType {
  FLEET_DRIVER = 'FLEET_DRIVER',
  PRIVATE_DRIVER = 'PRIVATE_DRIVER',
  STEERED_DRIVER = 'STEERED_DRIVER'
}

export type EditUserOptionsInput = {
  /** ID of the user to update the details for */
  userId: Scalars['String']['input'];
};

/** Define the email template name type: NEW_DAMAGE_REPORT, REPAIR_IS_ARRANGED etc. */
export enum EmailTemplateName {
  ALL_INVOICES_COLLECTED = 'ALL_INVOICES_COLLECTED',
  ASSESSOR_REQUESTED = 'ASSESSOR_REQUESTED',
  BODYSHOP_APP_ALTERNATIVE_APPOINTMENT_CONFIRMED = 'BODYSHOP_APP_ALTERNATIVE_APPOINTMENT_CONFIRMED',
  BODYSHOP_APP_DRIVER_APPOINTMENT_CONFIRMED = 'BODYSHOP_APP_DRIVER_APPOINTMENT_CONFIRMED',
  CHECKLIST_EXECUTED_DAMAGES = 'CHECKLIST_EXECUTED_DAMAGES',
  CHECKLIST_EXECUTED_DAMAGES_BY_MANAGER = 'CHECKLIST_EXECUTED_DAMAGES_BY_MANAGER',
  CHECKLIST_EXECUTED_SUCCESSFULLY = 'CHECKLIST_EXECUTED_SUCCESSFULLY',
  CHECKLIST_EXECUTED_SUCCESSFULLY_BY_MANAGER = 'CHECKLIST_EXECUTED_SUCCESSFULLY_BY_MANAGER',
  CHECKLIST_EXECUTED_UNSUCCESSFULLY = 'CHECKLIST_EXECUTED_UNSUCCESSFULLY',
  CHECKLIST_EXECUTED_UNSUCCESSFULLY_BY_MANAGER = 'CHECKLIST_EXECUTED_UNSUCCESSFULLY_BY_MANAGER',
  CHECKLIST_REPORT_DOCUMENT_TO_RF_OPERATORS = 'CHECKLIST_REPORT_DOCUMENT_TO_RF_OPERATORS',
  CHECKLIST_REPORT_WARNINGS = 'CHECKLIST_REPORT_WARNINGS',
  CHECKLIST_REPORT_WARNINGS_BY_MANAGER = 'CHECKLIST_REPORT_WARNINGS_BY_MANAGER',
  COSTS_VERIFICATION = 'COSTS_VERIFICATION',
  CUSTOM_APPOINTMENT_REQUESTED = 'CUSTOM_APPOINTMENT_REQUESTED',
  DAMAGE_REPORT_ASSIGN_USER = 'DAMAGE_REPORT_ASSIGN_USER',
  DAMAGE_REPORT_LINK = 'DAMAGE_REPORT_LINK',
  DAMAGE_REPORT_LINK_FROM_BODYSHOP = 'DAMAGE_REPORT_LINK_FROM_BODYSHOP',
  DAMAGE_REPORT_OVERVIEW_CLOSED = 'DAMAGE_REPORT_OVERVIEW_CLOSED',
  DAMAGE_REPORT_OVERVIEW_NEW = 'DAMAGE_REPORT_OVERVIEW_NEW',
  DAMAGE_REPORT_OVERVIEW_POSTPONED = 'DAMAGE_REPORT_OVERVIEW_POSTPONED',
  DAMAGE_REPORT_SETTLED = 'DAMAGE_REPORT_SETTLED',
  DAMAGE_REPORT_SHARED_LINK = 'DAMAGE_REPORT_SHARED_LINK',
  DAMAGE_REPORT_VERIFICATION_COMPLETED = 'DAMAGE_REPORT_VERIFICATION_COMPLETED',
  DAMAGE_REPORT_VERIFICATION_LINK = 'DAMAGE_REPORT_VERIFICATION_LINK',
  DAMAGE_REQUEST_RECEIVED = 'DAMAGE_REQUEST_RECEIVED',
  DEDUCTIBLE_COST_CREATED = 'DEDUCTIBLE_COST_CREATED',
  DUPLICATE_DAMAGE_REPORT = 'DUPLICATE_DAMAGE_REPORT',
  EG_BODYSHOP_ALREADY_EXISTS = 'EG_BODYSHOP_ALREADY_EXISTS',
  EG_BODYSHOP_MISSING = 'EG_BODYSHOP_MISSING',
  ESTIMATION_APPROVED_BODYSHOP = 'ESTIMATION_APPROVED_BODYSHOP',
  ESTIMATION_EXPERT_APPROVAL_REQUIRED = 'ESTIMATION_EXPERT_APPROVAL_REQUIRED',
  ESTIMATION_REJECTED_BODYSHOP = 'ESTIMATION_REJECTED_BODYSHOP',
  ESTIMATION_REQUEST_BODYSHOP = 'ESTIMATION_REQUEST_BODYSHOP',
  ESTIMATION_REQUEST_BODYSHOP_EG = 'ESTIMATION_REQUEST_BODYSHOP_EG',
  ESTIMATION_REQUEST_EG_OPERATIONS = 'ESTIMATION_REQUEST_EG_OPERATIONS',
  INDICATED_DAMAGE_REPORT = 'INDICATED_DAMAGE_REPORT',
  INSPECTION_IS_ARRANGED = 'INSPECTION_IS_ARRANGED',
  INSPECTION_ON_SITE = 'INSPECTION_ON_SITE',
  INSPECTION_SCHEDULED_BODYSHOP = 'INSPECTION_SCHEDULED_BODYSHOP',
  INVOICE_TO_EXTERNAL_OPERATORS = 'INVOICE_TO_EXTERNAL_OPERATORS',
  INVOICE_TO_RF_OPERATORS = 'INVOICE_TO_RF_OPERATORS',
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  LEASING_INSURANCE_APPROVAL_REQUIRED = 'LEASING_INSURANCE_APPROVAL_REQUIRED',
  NEW_DAMAGE_REPORT = 'NEW_DAMAGE_REPORT',
  NEW_FLEET_ONBOARDED = 'NEW_FLEET_ONBOARDED',
  NEW_HOTLINE_DAMAGE_REPORT = 'NEW_HOTLINE_DAMAGE_REPORT',
  NEW_THIRD_PARTY_DAMAGE_REPORT = 'NEW_THIRD_PARTY_DAMAGE_REPORT',
  NEW_THIRD_PARTY_DAMAGE_REPORT_TO_DRIVER = 'NEW_THIRD_PARTY_DAMAGE_REPORT_TO_DRIVER',
  PRE_INSPECTION = 'PRE_INSPECTION',
  PRE_INSPECTION_BODYSHOP = 'PRE_INSPECTION_BODYSHOP',
  PRE_INSPECTION_STEERER = 'PRE_INSPECTION_STEERER',
  PROVIDE_ASSESSMENT = 'PROVIDE_ASSESSMENT',
  REJECT_REPAIR_OFFER = 'REJECT_REPAIR_OFFER',
  REPAIR_ASSESSMENT_ACCEPTED = 'REPAIR_ASSESSMENT_ACCEPTED',
  REPAIR_ASSESSMENT_ACCEPTED_EG = 'REPAIR_ASSESSMENT_ACCEPTED_EG',
  REPAIR_ASSESSMENT_REJECTED = 'REPAIR_ASSESSMENT_REJECTED',
  REPAIR_ASSESSMENT_REJECTED_EG = 'REPAIR_ASSESSMENT_REJECTED_EG',
  REPAIR_ESTIMATED = 'REPAIR_ESTIMATED',
  REPAIR_IS_ARRANGED = 'REPAIR_IS_ARRANGED',
  REPAIR_IS_ARRANGED_FLEET_MANAGER = 'REPAIR_IS_ARRANGED_FLEET_MANAGER',
  REPAIR_OFFER_ACCEPTED_BODYSHOP = 'REPAIR_OFFER_ACCEPTED_BODYSHOP',
  REPAIR_OFFER_ACCEPTED_BODYSHOP_EG = 'REPAIR_OFFER_ACCEPTED_BODYSHOP_EG',
  REPAIR_OFFER_ACCEPTED_BY_ALL_MEMBERS = 'REPAIR_OFFER_ACCEPTED_BY_ALL_MEMBERS',
  REPAIR_OFFER_ACCEPTED_EXTERNAL_OPERATIONS = 'REPAIR_OFFER_ACCEPTED_EXTERNAL_OPERATIONS',
  REPAIR_OFFER_ADJUSTED = 'REPAIR_OFFER_ADJUSTED',
  REPAIR_OFFER_ADJUSTMENT_APPROVED_BODYSHOP = 'REPAIR_OFFER_ADJUSTMENT_APPROVED_BODYSHOP',
  REPAIR_OFFER_ADJUSTMENT_APPROVED_BODYSHOP__EG = 'REPAIR_OFFER_ADJUSTMENT_APPROVED_BODYSHOP__EG',
  REPAIR_OFFER_ADJUSTMENT_APPROVED_EXTERNAL_OPERATIONS = 'REPAIR_OFFER_ADJUSTMENT_APPROVED_EXTERNAL_OPERATIONS',
  REPAIR_OFFER_ADJUSTMENT_REJECTED_BODYSHOP = 'REPAIR_OFFER_ADJUSTMENT_REJECTED_BODYSHOP',
  REPAIR_OFFER_ADJUSTMENT_REJECTED_BODYSHOP__EG = 'REPAIR_OFFER_ADJUSTMENT_REJECTED_BODYSHOP__EG',
  REPAIR_OFFER_ADJUSTMENT_REJECTED_EXTERNAL_OPERATIONS = 'REPAIR_OFFER_ADJUSTMENT_REJECTED_EXTERNAL_OPERATIONS',
  REPAIR_OFFER_CANCELLED = 'REPAIR_OFFER_CANCELLED',
  REPAIR_OFFER_REJECTED = 'REPAIR_OFFER_REJECTED',
  REPAIR_POSTPONED = 'REPAIR_POSTPONED',
  REPAIR_SCHEDULED_BODYSHOP = 'REPAIR_SCHEDULED_BODYSHOP',
  REQUEST_ASSESSOR = 'REQUEST_ASSESSOR',
  REQUEST_ASSESSOR_EG = 'REQUEST_ASSESSOR_EG',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REVIEW_DAMAGE_REPORT = 'REVIEW_DAMAGE_REPORT',
  SEND_OTP = 'SEND_OTP',
  SERVICE_INVOICE_UPLOADED = 'SERVICE_INVOICE_UPLOADED',
  SERVICE_REMINDER = 'SERVICE_REMINDER',
  SERVICE_REPORT_APPOINTMENT_ALTERNATIVE_DATE = 'SERVICE_REPORT_APPOINTMENT_ALTERNATIVE_DATE',
  SERVICE_REPORT_APPOINTMENT_ALTERNATIVE_DATE_CONFIRMED = 'SERVICE_REPORT_APPOINTMENT_ALTERNATIVE_DATE_CONFIRMED',
  SERVICE_REPORT_APPOINTMENT_SCHEDULED = 'SERVICE_REPORT_APPOINTMENT_SCHEDULED',
  SMS_REMAINING = 'SMS_REMAINING',
  WEBHOOK_EVENT_IS_FAILED = 'WEBHOOK_EVENT_IS_FAILED',
  WELCOME = 'WELCOME',
  WELCOME_BODYSHOP = 'WELCOME_BODYSHOP',
  WELCOME_STEERER = 'WELCOME_STEERER'
}

/** Checklist report executions status filter values. */
export enum ExecutionStatus {
  WITHOUT_ISSUES = 'WITHOUT_ISSUES',
  WITH_DAMAGES = 'WITH_DAMAGES',
  WITH_WARNINGS = 'WITH_WARNINGS'
}

export type ExistingBodyshop = {
  __typename?: 'ExistingBodyshop';
  /** Bodyshop address city */
  addressCity: Scalars['String']['output'];
  /** Bodyshop address house */
  addressHouse: Scalars['String']['output'];
  /** Bodyshop address street */
  addressStreet: Scalars['String']['output'];
  /** Bodyshop address zip */
  addressZip: Scalars['String']['output'];
  /** Bodyshop ID */
  id: Scalars['ID']['output'];
  /** Bodyshop configuration: digital reception */
  isDigitalReception?: Maybe<Scalars['Boolean']['output']>;
  /** Bodyshop configuration: fleet processing */
  isFleetProcessing?: Maybe<Scalars['Boolean']['output']>;
  /** Bodyshop configuration: is DPN is involved */
  isFromDPN?: Maybe<Scalars['Boolean']['output']>;
  /** Bodyshop name */
  name: Scalars['String']['output'];
  /** Bodyshop title */
  title?: Maybe<Scalars['String']['output']>;
  users: Array<Scalars['String']['output']>;
  /** Website url */
  webSiteUrl?: Maybe<Scalars['String']['output']>;
};

export type ExportCarsInput = {
  /** Document type */
  docType?: Scalars['String']['input'];
  /** Fleet ids */
  fleetId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car location */
  location?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExportCarsResult = {
  __typename?: 'ExportCarsResult';
  /** File link */
  link: Scalars['String']['output'];
};

export type ExtendedMutationResult = {
  __typename?: 'ExtendedMutationResult';
  /** Additional data */
  data?: Maybe<Scalars['JSONObject']['output']>;
  /** Error */
  error?: Maybe<Scalars['String']['output']>;
  /** Additional error data */
  errorData?: Maybe<Scalars['JSONObject']['output']>;
  /** Status of mutation */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ExteriorModuleConfiguration = {
  __typename?: 'ExteriorModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type ExternalDamageReportLink = {
  __typename?: 'ExternalDamageReportLink';
  /** External damage report  button title */
  buttonTitle?: Maybe<Scalars['String']['output']>;
  /** External damage report link */
  link: Scalars['String']['output'];
};

export type ExternalDamageReportLinkModuleConfiguration = {
  __typename?: 'ExternalDamageReportLinkModuleConfiguration';
  buttonTitle?: Maybe<Scalars['String']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
  linkTemplate?: Maybe<Scalars['String']['output']>;
};

export type ExternalDamageReportLinkModuleConfigurationInput = {
  buttonTitle?: InputMaybe<Scalars['String']['input']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['input'];
  linkTemplate?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalDataObjectType = {
  __typename?: 'ExternalDataObjectType';
  /** Constent of the external data entity */
  data: Scalars['String']['output'];
  /** Id of the external data entity */
  id: Scalars['ID']['output'];
  /** External data entity type */
  type: Scalars['String']['output'];
};

export type FileOrUrlUpload = {
  /** File */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** Url */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileUploadResult = {
  __typename?: 'FileUploadResult';
  /** Error cased by uploading current file */
  error?: Maybe<Scalars['String']['output']>;
  /** Uploaded file name */
  fileName: Scalars['String']['output'];
  /** Result of uploading current file */
  success: Scalars['Boolean']['output'];
};

export enum FilterTypes {
  CATEGORIZED_CHECKBOX_LIST = 'CATEGORIZED_CHECKBOX_LIST',
  CHECKBOX_LIST = 'CHECKBOX_LIST',
  CHECKBOX_LIST_OLD = 'CHECKBOX_LIST_OLD',
  DATE_RANGE = 'DATE_RANGE',
  TEXT = 'TEXT',
  TOGGLE = 'TOGGLE'
}

export type FiltersResult = {
  __typename?: 'FiltersResult';
  /** Filter data category name */
  categoryName?: Maybe<ReportFilterSearchKeysType>;
  /** The number of matching cases */
  count?: Maybe<Scalars['Int']['output']>;
  /** An option group */
  group?: Maybe<Scalars['String']['output']>;
  /** Do we need to show icon in the field? */
  isIconRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a filter allows for searching in scope of its options */
  isSearchable?: Maybe<Scalars['Boolean']['output']>;
  /** Do we need to translate the field? */
  isTranslationRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The filter key that to be sent to backend */
  key?: Maybe<Scalars['String']['output']>;
  /** The name of the filter */
  name?: Maybe<Scalars['String']['output']>;
  /** Nested structure of filters */
  options?: Maybe<Array<FiltersResult>>;
  /** Query name */
  queryName?: Maybe<Scalars['String']['output']>;
  /** A start adornment component name */
  startAdornmentName?: Maybe<Scalars['String']['output']>;
  /** Supported keys by filter */
  supportedKeys?: Maybe<Array<Scalars['String']['output']>>;
  /** Filter field type */
  type?: Maybe<FilterTypes>;
};

export type Fleet = {
  __typename?: 'Fleet';
  configuration: FleetConfiguration;
  /** ID of the fleet */
  id: Scalars['ID']['output'];
  /** Fleet name */
  name: Scalars['String']['output'];
  /** Tenant */
  tenant: Tenant;
};

export type FleetCar = {
  /** Car body type */
  bodyStyle: BodyStyle;
  /** Car first registration date */
  firstRegistration: Scalars['Date']['input'];
  /** Insurance information */
  insurance?: InputMaybe<CarInsuranceInput>;
  /** Is car shareable */
  isPool: Scalars['Boolean']['input'];
  /** Last inspection date */
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** Leasing information */
  leasing?: InputMaybe<CarLeasingInput>;
  /** Main driver/leagaly responsible user's email. Required for non-pool cars */
  liableUser?: InputMaybe<Scalars['String']['input']>;
  /** Car license plate */
  licensePlate: Scalars['String']['input'];
  /** Car location (city) */
  location: Scalars['String']['input'];
  /** Car manufacturer name */
  make: Scalars['String']['input'];
  /** Car model name */
  model: Scalars['String']['input'];
  /** Next inspection date */
  nextInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** Car settings */
  settings?: InputMaybe<CarSettingsInput>;
  /** Car VIN */
  vin: Scalars['String']['input'];
  /** Waranty expiration date */
  warrantyExpirationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type FleetConfiguration = {
  __typename?: 'FleetConfiguration';
  /** Available apps configuration */
  apps: AppsFleetConfiguration;
  /** Account domain */
  domain?: Maybe<Scalars['String']['output']>;
  /** Available locations configuration */
  locations: Array<Location>;
  /** Available modules configuration */
  modules: ModulesConfiguration;
  /** Fleet settings */
  settings?: Maybe<FleetSettings>;
};

export type FleetDriverInfoInput = {
  fleetDriverName?: InputMaybe<Scalars['String']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
};

export type FleetInfo = {
  __typename?: 'FleetInfo';
  /** Fleet configuration */
  configuration: FleetWebConfiguration;
  /** Fleet name */
  name: Scalars['String']['output'];
};

export type FleetMetricsInput = {
  /** Fleet name */
  fleetName: Scalars['String']['input'];
  /** Fleet segments */
  fleetSegments?: InputMaybe<Array<FleetSegment>>;
  /** Fleet product packages */
  productPackages?: InputMaybe<Array<FleetProductPackages>>;
  /** Fleet status */
  status?: InputMaybe<FleetStatus>;
};

export enum FleetProductPackages {
  AI = 'AI',
  CHECKLIST = 'CHECKLIST',
  DAMAGE = 'DAMAGE',
  FULL_SERVICE = 'FULL_SERVICE',
  LEASING = 'LEASING',
  SOFTWARE = 'SOFTWARE'
}

export enum FleetSegment {
  DELIVERY = 'DELIVERY',
  MOBILITY = 'MOBILITY',
  MOTIVATIONAL = 'MOTIVATIONAL',
  SERVICE = 'SERVICE'
}

export type FleetSettings = {
  __typename?: 'FleetSettings';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit: Scalars['Float']['output'];
  /** Defines if driver should provide his data in the end of flow */
  collectDriverData?: Maybe<Scalars['Boolean']['output']>;
  conditionsUrl?: Maybe<Scalars['String']['output']>;
  costCenter?: Maybe<Scalars['String']['output']>;
  /** A default fleet country code */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** The currency code (ISO_4217) used by fleet */
  currencyCode?: Maybe<Scalars['String']['output']>;
  /** Defines damage processing network */
  damageProcessingNetwork?: Maybe<Scalars['String']['output']>;
  dataProtectionUrl?: Maybe<Scalars['String']['output']>;
  deductibleTk?: Maybe<Scalars['Float']['output']>;
  deductibleVk?: Maybe<Scalars['Float']['output']>;
  /** Default address for invoices */
  defaultInvoiceAddress?: Maybe<Scalars['String']['output']>;
  /** Default email for invoices notifications */
  defaultInvoiceNotificationEmail?: Maybe<Scalars['String']['output']>;
  expertAssessmentLimit: Scalars['Float']['output'];
  /** Hotline phone numbers */
  hotlinePhoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  impressumUrl?: Maybe<Scalars['String']['output']>;
  insuranceBroker?: Maybe<Scalars['String']['output']>;
  insuranceCompany?: Maybe<Scalars['String']['output']>;
  /** Defines automatic insurance approval limit for repair offers */
  insuranceLimit: Scalars['Float']['output'];
  insuranceNumber?: Maybe<Scalars['String']['output']>;
  /** Defines if AI recognition being used */
  isAIRecognitionEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if AI history recognition being used */
  isAIRecognitionHistoryEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if AI recognition analytics being used */
  isAIUsageAnalyticsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet is using API */
  isAPI?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet manager takes active part in steering damage: requesting repairs, approvals, etc. Or logic is taken over on RF side */
  isActiveDamageManagement?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if fleet manager can select bodyshop when requesting repair offer. Only applicable when DPN is configured */
  isDpnBodyshopSelectAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Depending on this setting an approval from fleet company is required. Depends on damage processing network */
  isFleetApprovalRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Enables the damage location feature */
  isGeolocationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if hotline should be involved in the process */
  isHotline?: Maybe<Scalars['Boolean']['output']>;
  /** Depending on this setting an approval from insurance company is required. Depends on damage processing network */
  isInsuranceApprovalRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet deactivate Mileage info in driver damage report */
  isMileageDeactivated?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet can create multi repair offer */
  isMultiRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet is using Portal */
  isPortal?: Maybe<Scalars['Boolean']['output']>;
  /** Fleet default language */
  lang?: Maybe<Scalars['String']['output']>;
  /** Defines automatic leasing approval limit for repair offers */
  leasingLimit: Scalars['Float']['output'];
  /** Fleet apps language list */
  locales?: Maybe<Array<Scalars['String']['output']>>;
  locizeProjectId?: Maybe<Scalars['String']['output']>;
  /** Primary contact for pool cars */
  poolPrimaryContact?: Maybe<PoolPrimaryContact>;
  repairReleaseLimit: Scalars['Float']['output'];
  repairReleaseLimitKasko: Scalars['Float']['output'];
  /** The tax rate (VAT) in % applicable to fleet's costs */
  vatPercentage?: Maybe<Scalars['Float']['output']>;
  webSiteUrl?: Maybe<Scalars['String']['output']>;
};

export enum FleetStatus {
  ACTIVE = 'ACTIVE',
  CHURNED = 'CHURNED',
  TRIAL = 'TRIAL'
}

export type FleetUser = {
  /** User email */
  email: Scalars['String']['input'];
  /** User first name */
  firstName: Scalars['String']['input'];
  /** Is notifications enabled */
  isNotificationsEnabled?: Scalars['Boolean']['input'];
  /** User last name */
  lastName: Scalars['String']['input'];
  /** User password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** User phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** User role */
  role: Scalars['String']['input'];
  /** Trigger invitation email */
  triggerInvitationEmail?: Scalars['Boolean']['input'];
  /** User visibility scope */
  visibilityScope?: InputMaybe<Array<UserVisibilityScope>>;
};

export type FleetUserObject = {
  __typename?: 'FleetUserObject';
  /** A user's email address */
  email: Scalars['String']['output'];
  /** A user's full name */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type FleetWebConfiguration = {
  __typename?: 'FleetWebConfiguration';
  /** Available apps configuration */
  apps: AppsFleetConfiguration;
  /** Available location names */
  locations: Array<LocationName>;
  /** Available modules configuration */
  modules: ModulesConfiguration;
  /** Fleet settings */
  settings?: Maybe<FleetWebSettings>;
};

export type FleetWebSettings = {
  __typename?: 'FleetWebSettings';
  /** Defines if driver should provide his data in the end of flow */
  collectDriverData?: Maybe<Scalars['Boolean']['output']>;
  /** Defines damage processing network */
  damageProcessingNetwork?: Maybe<Scalars['String']['output']>;
  /** Hotline phone numbers */
  hotlinePhoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  /** Defines if fleet manager can select bodyshop when requesting repair offer. Only applicable when DPN is configured */
  isDpnBodyshopSelectAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if hotline should be involved in the process */
  isHotline?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet deactivate Mileage info in driver damage report */
  isMileageDeactivated?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if a fleet can create multi repair offer */
  isMultiRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Fleet default language */
  lang?: Maybe<Scalars['String']['output']>;
  /** Fleet apps language list */
  locales?: Maybe<Array<Scalars['String']['output']>>;
};

export type FleetWithConfiguration = {
  __typename?: 'FleetWithConfiguration';
  /** Fleet configuration */
  configuration: Scalars['JSONObject']['output'];
  /** ID of the fleet */
  id: Scalars['ID']['output'];
  /** Fleet name */
  name: Scalars['String']['output'];
};

export type GeneratePdfReportInput = {
  /** Entity ID */
  id: Scalars['String']['input'];
  /** Entity type */
  type?: InputMaybe<SupportedEntityType>;
};

export type GenericDamageReport = {
  __typename?: 'GenericDamageReport';
  /** Accident questions answers */
  accidentQuestionsAnswers?: Maybe<AccidentQuestionsAnswers>;
  /** Other driver data */
  accidentThirdParty?: Maybe<AccidentThirdParty>;
  actions: Array<DamageReportActionResult>;
  /** Damage Description */
  additionalComments?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of suggested additional services */
  additionalServices?: Maybe<Array<AdditionalService>>;
  assignees: Array<UserInfoResult>;
  /** Car body type */
  bodyType?: Maybe<BodyStyle>;
  /** The car that the damage description belongs to. */
  car?: Maybe<PortalCar>;
  /** The car parts that the report has */
  carParts?: Maybe<Array<CarPart>>;
  /** Car registration company */
  carRegistrationCompany?: Maybe<Scalars['String']['output']>;
  /** Carisma identifier */
  carismaTaskId?: Maybe<Scalars['String']['output']>;
  /** Internal reference to client number */
  clientNumber?: Maybe<Scalars['String']['output']>;
  /** comment */
  comment?: Maybe<ExternalDataObjectType>;
  /** The date that the damage report has been created. */
  createdAt: Scalars['Date']['output'];
  /** Damage report information details */
  damageInformation: DamageReportInformation;
  /** Insurance information */
  damageInsurance?: Maybe<DamageInsurance>;
  /** Number of the damage */
  damageNumber?: Maybe<Scalars['Int']['output']>;
  /** Damage circumstances from EG side */
  damageReasonComment?: Maybe<Scalars['String']['output']>;
  /** Link to EG damage report document */
  damageReportLink?: Maybe<Scalars['String']['output']>;
  /** Documents external for damage */
  documents?: Maybe<Array<DamageExternalDocument>>;
  /** Additional driver phone */
  driverAdditionalPhone?: Maybe<Scalars['String']['output']>;
  /** Driver address */
  driverAddress?: Maybe<Scalars['String']['output']>;
  /** Driver city */
  driverCity?: Maybe<Scalars['String']['output']>;
  /** Driver email */
  driverEmail?: Maybe<Scalars['String']['output']>;
  /** Indicates that a driver informed consent */
  driverInformConsent?: Maybe<DriverInformConsent>;
  /** Driver name */
  driverName?: Maybe<Scalars['String']['output']>;
  /** Driver phone */
  driverPhone?: Maybe<Scalars['String']['output']>;
  /** Driver type */
  driverType?: Maybe<DriverType>;
  /** Driver ZIP */
  driverZip?: Maybe<Scalars['String']['output']>;
  /** Available fields for editing */
  editableFields: Array<Scalars['String']['output']>;
  /** The date when EG process was requested */
  egProcessRequestedAt?: Maybe<Scalars['Date']['output']>;
  /** External damage report link */
  externalDamageReportLink?: Maybe<ExternalDamageReportLink>;
  /** externalId */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Array of filtering statuses that may match damage report */
  filteringStatuses?: Maybe<Array<DamageReportFilteringStatus>>;
  /** Car first registration date */
  firstRegistration?: Maybe<Scalars['String']['output']>;
  /** Fleet */
  fleet?: Maybe<Fleet>;
  fleetComment?: Maybe<Scalars['String']['output']>;
  /** Fleet repair price */
  fleetRepairPrice?: Maybe<Scalars['Float']['output']>;
  /** Indicates if a damage car has comparison images */
  hasComparisonImages?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if a damage report has an uploaded final invoice */
  hasFinalInvoice?: Maybe<Scalars['Boolean']['output']>;
  /** KBA 1 */
  hsn?: Maybe<Scalars['String']['output']>;
  /** ID of the damage report */
  id: Scalars['ID']['output'];
  /** Images for the damage report */
  images?: Maybe<Array<DamageReportImage>>;
  /** Impact information */
  impactInfo?: Maybe<ImpactInfo>;
  /** Injured person information */
  injuredPersonInformation?: Maybe<InjuredPersonInformation>;
  /** Input tax deduction */
  inputTaxDeduction?: Maybe<Scalars['Boolean']['output']>;
  /** Car inspection date */
  inspectionDate?: Maybe<Scalars['String']['output']>;
  insuranceCompanyName?: Maybe<Scalars['String']['output']>;
  insurancePolicyNumber?: Maybe<Scalars['String']['output']>;
  /** Damage report repair invoices */
  invoices?: Maybe<Array<Invoice>>;
  /** Indicates whether damage report is accident */
  isAccident?: Maybe<Scalars['Boolean']['output']>;
  isDrivable: DamageReportDrivableType;
  /** Is damage initiated from damage processing network side and is accident */
  isExternalAccident?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether damage reported via hotline */
  isHotline?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if damage minor */
  isMinor?: Maybe<Scalars['Boolean']['output']>;
  /** Link to KSR xml document */
  ksrXmlFileLink?: Maybe<Scalars['String']['output']>;
  /** List of legal agreements */
  legalAgreements?: Maybe<Array<DamageReportLegalAgreement>>;
  /** Car license plate */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Car license plate correction by the driver/orc */
  licensePlateCorrection?: Maybe<Scalars['String']['output']>;
  /** Link sent driver phone */
  linkSentPhone?: Maybe<Scalars['String']['output']>;
  /** Location */
  location?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  /** Car mileage when damage reported */
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  /** Status change flow for the fleet */
  nextStatuses?: Maybe<Scalars['JSONObject']['output']>;
  /** Is old assessment flow */
  oldAssessorFlow?: Maybe<Scalars['Boolean']['output']>;
  /** operationalId */
  operationalId?: Maybe<ExternalDataObjectType>;
  /** EG damage id */
  partnerDamageId?: Maybe<Scalars['String']['output']>;
  /** Police report data */
  policeReport?: Maybe<PoliceReport>;
  /** Power KW */
  powerKw?: Maybe<Scalars['String']['output']>;
  /** Damage report repair offers */
  repairOffers?: Maybe<Array<RepairOffer>>;
  /** Reported by user */
  reportedBy?: Maybe<UserInfoResult>;
  /** Reporter email */
  reporterEmail?: Maybe<Scalars['String']['output']>;
  reporterInformation?: Maybe<ReporterInformationResult>;
  /** Reporter name */
  reporterName?: Maybe<Scalars['String']['output']>;
  /** Reporter phone */
  reporterPhone?: Maybe<Scalars['String']['output']>;
  /** EG request processing comment */
  requestEgProcessingComment?: Maybe<Scalars['String']['output']>;
  /** Required services */
  requiredServices?: Maybe<RequiredServices>;
  /** Send link message */
  sendLinkMessage?: Maybe<Scalars['String']['output']>;
  /** The date that the damage report has been settled. */
  settledAt?: Maybe<Scalars['Date']['output']>;
  /** Documents that to be signed by driver */
  signedDocuments?: Maybe<Array<DamageReportSignedDocument>>;
  /** Damage Status */
  status?: Maybe<Scalars['String']['output']>;
  /** Damage report status change history */
  statusRoadMap?: Maybe<Array<StatusRoadMapItem>>;
  /** Steerer connected to report */
  steerer?: Maybe<Steerer>;
  /** tags */
  tags?: Maybe<Array<ExternalDataObjectType>>;
  /** KBA 2 */
  tsn?: Maybe<Scalars['String']['output']>;
  /** Damage Report Type */
  type: DamageReportType;
  /** Available fields for viewing */
  viewableFields: Array<Scalars['String']['output']>;
  /** Car vin code */
  vin?: Maybe<Scalars['String']['output']>;
  /** Wildlife protection license data */
  wildlifeProtection?: Maybe<DamageReportWildlifeDataResult>;
};


export type GenericDamageReportRepairOffersArgs = {
  bodyshopId?: InputMaybe<Scalars['String']['input']>;
};

/** Define the email template headline icon type: repair, inspection etc. */
export enum GenericTemplateHeadlineIcon {
  CALENDAR = 'CALENDAR',
  DAMAGE_REPORT = 'DAMAGE_REPORT',
  FRIENDLY_CAR = 'FRIENDLY_CAR',
  INSPECTION = 'INSPECTION',
  INVOICE = 'INVOICE',
  KEY = 'KEY',
  REPAIR = 'REPAIR',
  REPAIR_CLOSED = 'REPAIR_CLOSED'
}

/** Define the email template headline color type: red, yellow etc. */
export enum GenericTemplateLabelColor {
  GREY = 'GREY',
  RED = 'RED',
  YELLOW = 'YELLOW'
}

export type GetActiveCarsResult = {
  __typename?: 'GetActiveCarsResult';
  /** Available cars */
  available: Scalars['Float']['output'];
  /** Partially available cars */
  partiallyAvailable: Scalars['Float']['output'];
  /** Unavailable cars */
  unavailable: Scalars['Float']['output'];
};

export type GetAssessorsListArgs = {
  /** Damage report ID */
  damageReportId: Scalars['String']['input'];
  /** Pagination */
  pagination?: InputMaybe<GetAssessorsListArgsPagination>;
  /** A search query string */
  q?: InputMaybe<Scalars['String']['input']>;
  /** Sort order */
  sort?: InputMaybe<GetAssessorsListArgsSort>;
};

export type GetAssessorsListArgsPagination = {
  /** Users per page */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** A user's list page */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAssessorsListArgsSort = {
  /** Sort by property */
  sortBy: UserListSortBy;
  /** Sort order property */
  sortOrder: SortOrder;
};

export type GetCarAuthResult = {
  __typename?: 'GetCarAuthResult';
  /** Car Information */
  car: CarInfo;
  /** Access code on car level */
  code: Scalars['String']['output'];
};

export type GetCarInfoResult = {
  __typename?: 'GetCarInfoResult';
  /** Car Information */
  car: CarInfo;
};

export type GetChecklistDetailsInput = {
  /** A checklist report id */
  id: Scalars['String']['input'];
};

export type GetCompletedDamageReportTokenResult = {
  __typename?: 'GetCompletedDamageReportTokenResult';
  /** Damage report token */
  code?: Maybe<Scalars['String']['output']>;
  /** Damage report ID */
  damageReportId: Scalars['String']['output'];
};

export type GetDamageReportComparisonImagesResult = {
  __typename?: 'GetDamageReportComparisonImagesResult';
  images: Array<DamageReportComparisonImage>;
  /** Total images */
  total: Scalars['Float']['output'];
};

export type GetExportDataLinkInput = {
  /** Assignee name */
  assignee?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Bodyshop name */
  bodyshop?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns damage reports related to a particular checklist report */
  checklistReportId?: InputMaybe<Scalars['ID']['input']>;
  /** Damaged area */
  damageArea?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Damage type */
  damageType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Fleet name */
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Damage reported from date */
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  /** Flag to show closed and rejected statuses on the list */
  includeArchived?: Scalars['Boolean']['input'];
  /** Defines insurance coverage type to filter with. Makes sense for Compound and Exterior (accidents only) damage reports. */
  insuranceCoverageType?: InputMaybe<Array<DamageReportInsuranceCoverageType>>;
  /** Damage Report Location */
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car manufacturer name */
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car model name */
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by car properties */
  q?: InputMaybe<Scalars['String']['input']>;
  /** Roadworthy status */
  roadworthy?: InputMaybe<Array<DamageReportDrivableType>>;
  /** Damage Report Status */
  status?: InputMaybe<Array<DamageReportFilteringStatus>>;
  /** Steerer name */
  steerer?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tags values */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Damage reported to date */
  toDate?: InputMaybe<Scalars['Date']['input']>;
  /** Damage Report Type */
  type?: InputMaybe<Array<DamageReportFilteringType>>;
};

export type GetExportDataLinkResult = {
  __typename?: 'GetExportDataLinkResult';
  /** An exported file URL */
  url: Scalars['String']['output'];
};

export type GetFleetUsersInput = {
  /** A fleet id */
  fleetId?: InputMaybe<Scalars['String']['input']>;
  /** A user's list page */
  page?: InputMaybe<Scalars['Float']['input']>;
  /** Users per page */
  perPage?: InputMaybe<Scalars['Float']['input']>;
  /** A search query string */
  q?: InputMaybe<Scalars['String']['input']>;
};

export type GetFleetUsersResult = {
  __typename?: 'GetFleetUsersResult';
  /** Total users number */
  total: Scalars['Float']['output'];
  users: Array<FleetUserObject>;
};

export type GetReportCarOverviewResult = {
  __typename?: 'GetReportCarOverviewResult';
  reports: Array<CarOverviewDamageReport>;
  /** Total number of damage reports */
  total: Scalars['Float']['output'];
};

export type GetUserAvailableRolesAndOrganizationsOptionsInput = {
  /** Organization ID */
  organizationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the tenant for which we would like to create a user */
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserAvailableTenantsOptionsArgs = {
  /** Filters */
  filters?: InputMaybe<Scalars['String']['input']>;
  /** The page number of search results (starts from 1) */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Text for searching options */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserDetailsArgs = {
  /** User ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type GetUserListArgs = {
  /** User location */
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  /** User organization */
  organization?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination */
  pagination?: InputMaybe<GetUserListArgsPagination>;
  /** A search query string */
  q?: InputMaybe<Scalars['String']['input']>;
  /** User role */
  role?: InputMaybe<Array<UserRole>>;
  /** Sort order */
  sort?: InputMaybe<GetUserListArgsSort>;
  /** User status */
  status?: InputMaybe<Array<UserStatus>>;
};

export type GetUserListArgsPagination = {
  /** Users per page */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** A user's list page */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type GetUserListArgsSort = {
  /** Sort by property */
  sortBy: UserListSortBy;
  /** Sort order property */
  sortOrder: SortOrder;
};

export type HealthObject = {
  __typename?: 'HealthObject';
  datetime: Scalars['Date']['output'];
  imageTag?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  uptime: Scalars['Float']['output'];
};

/** List of the supported car identification methods */
export enum IdentificationMethod {
  LICENSE_PLATE = 'LICENSE_PLATE',
  VIN = 'VIN'
}

export type ImageUpload = {
  /** File for upload */
  file: Scalars['Upload']['input'];
  /** Type of the image */
  type: DamageReportImageType;
};

export type ImagesUpdate = {
  /** The category of the image */
  category?: InputMaybe<DamageReportImageCategory>;
  /** Image Id */
  id: Scalars['String']['input'];
  /** Image visibility restricted */
  isVisibilityRestricted: Scalars['Boolean']['input'];
};

export type ImpactInfo = {
  __typename?: 'ImpactInfo';
  /** Impact angle */
  impactAngle: Scalars['Float']['output'];
  /** Damage severity */
  severity: Scalars['String']['output'];
  /** Collision speed */
  speed: Scalars['Float']['output'];
};

export type ImportCarsAndDriversInput = {
  /** Custom mapping */
  customMapping?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Fleet name */
  fleetName: Scalars['String']['input'];
  /** mode */
  mode?: ImportModeType;
};

export type ImportCarsInput = {
  /** Fleet ids */
  fleetId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Car location */
  location?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ImportCarsResult = {
  __typename?: 'ImportCarsResult';
  /** Report URL */
  link?: Maybe<Scalars['String']['output']>;
};

/** Define import mode. Create/FullUpdate are possible */
export enum ImportModeType {
  CREATE = 'CREATE',
  FULL_UPDATE = 'FULL_UPDATE'
}

export type InitChecklistDamageReportInput = {
  /** Type of the check */
  checkType: Scalars['String']['input'];
  /** Damage report type */
  type?: DamageReportType;
};

export type InitChecklistDamageReportResult = {
  __typename?: 'InitChecklistDamageReportResult';
  /** Damage report token */
  code?: Maybe<Scalars['String']['output']>;
  /** Damage report ID */
  damageReportId: Scalars['String']['output'];
};

export type InjuredPersonInformation = {
  __typename?: 'InjuredPersonInformation';
  comment?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insuranceCompany?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type InjuredPersonInformationInput = {
  /** Injured person accident comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Injured person email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Injured person full name */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Health insurance company */
  insuranceCompany?: InputMaybe<Scalars['String']['input']>;
  /** Injured person phone */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Insurance = {
  __typename?: 'Insurance';
  /** ID of the insurance */
  id: Scalars['ID']['output'];
  /** Name of the insurance */
  name: Scalars['String']['output'];
  /** Title of the insurance */
  title: Scalars['String']['output'];
};

export enum InsuranceCompanySortBy {
  NAME = 'NAME'
}

/** Define the insurance coverage type: third party, full, partial, unknown. */
export enum InsuranceCoverageType {
  FULL = 'FULL',
  NOT_COVERED = 'NOT_COVERED',
  PARTIAL = 'PARTIAL',
  THIRD_PARTY = 'THIRD_PARTY',
  UNKNOWN = 'UNKNOWN'
}

export type InsuranceList = {
  __typename?: 'InsuranceList';
  companies: Array<Insurance>;
  /** total number of insurance companies */
  total: Scalars['Float']['output'];
};

export type InteriorModuleConfiguration = {
  __typename?: 'InteriorModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Invoice address */
  address: Scalars['String']['output'];
  /** Invoice amount */
  amount: Scalars['Float']['output'];
  /** Bodyshop */
  bodyshop?: Maybe<Bodyshop>;
  /** Invoice comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** Invoice created date */
  createdAt: Scalars['Date']['output'];
  /** Invoice document */
  document?: Maybe<Scalars['String']['output']>;
  /** Invoice download url */
  downloadUrl: Scalars['String']['output'];
  /** ID of the invoice */
  id: Scalars['ID']['output'];
  /** Type of the invoice */
  type: InvoiceType;
  /** Invoice updated date */
  updatedAt: Scalars['Date']['output'];
};

export enum InvoiceDocumentSubtype {
  AGGREGATED = 'AGGREGATED',
  ASSESSMENT = 'ASSESSMENT',
  CAR_RENTAL = 'CAR_RENTAL',
  OTHER = 'OTHER',
  REPAIR = 'REPAIR',
  TOWING_SERVICE = 'TOWING_SERVICE'
}

export enum InvoiceType {
  ACTUAL_REPAIR_PRICE = 'ACTUAL_REPAIR_PRICE',
  AGGREGATED = 'AGGREGATED',
  COSTS_COVERED_BY_INSURANCE = 'COSTS_COVERED_BY_INSURANCE',
  EXTERNAL_ACCESSOR_COSTS = 'EXTERNAL_ACCESSOR_COSTS',
  OTHER_COST = 'OTHER_COST',
  RENTAL_CAR_COSTS = 'RENTAL_CAR_COSTS',
  TOWING_COSTS = 'TOWING_COSTS'
}

export type InvoiceUploadInput = {
  /** Invoice upload comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Type of invoice document */
  type?: InputMaybe<InvoiceDocumentSubtype>;
};

export type InvoiceUploadResult = {
  __typename?: 'InvoiceUploadResult';
  /** Result of the invoices upload */
  files: Array<FileUploadResult>;
};

export type IsValidResetPasswordToken = {
  /** Reset password token */
  token: Scalars['String']['input'];
};

export type IsValidResetPasswordTokenResult = {
  __typename?: 'IsValidResetPasswordTokenResult';
  /** Status of the resetting user password */
  isValid: Scalars['Boolean']['output'];
};

/** Define the language de, en, es */
export enum Language {
  AR = 'AR',
  DE = 'DE',
  EN = 'EN',
  ES = 'ES',
  HR = 'HR',
  IT = 'IT',
  PL = 'PL',
  RO = 'RO',
  RU = 'RU',
  SQ = 'SQ',
  SR = 'SR',
  TR = 'TR',
  UA = 'UA'
}

/** Type of the legal agreement */
export enum LegalAgreementType {
  CANCELLATION_POLICY = 'CANCELLATION_POLICY',
  SERVICE_AGREEMENT = 'SERVICE_AGREEMENT',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS'
}

export type Location = {
  __typename?: 'Location';
  /** Address for invoices */
  invoiceAddress?: Maybe<Scalars['String']['output']>;
  /** Location name */
  name: Scalars['String']['output'];
};

export type LocationName = {
  __typename?: 'LocationName';
  /** Location name */
  name: Scalars['String']['output'];
};

export type MakeRepairDecisionInput = {
  /** Damage Report ID */
  id: Scalars['ID']['input'];
  /** Repair decision */
  repairDecision: RepairDecision;
};

export type ManagerAppConfiguration = {
  __typename?: 'ManagerAppConfiguration';
  isEnabled: Scalars['Boolean']['output'];
  /** App specific modules configuration */
  modules?: Maybe<ModulesConfiguration>;
  screens?: Maybe<Scalars['JSONObject']['output']>;
  settings?: Maybe<AppSettings>;
};

/** Car part types related to mechanical damage report */
export enum MechanicalCarPart {
  AIR_CONDITIONING_AND_HEATING = 'AIR_CONDITIONING_AND_HEATING',
  BRAKES = 'BRAKES',
  CHASSIS_AND_STEERING = 'CHASSIS_AND_STEERING',
  ELECTRONICS = 'ELECTRONICS',
  ENGINE = 'ENGINE',
  MAINTENANCE = 'MAINTENANCE',
  OTHER = 'OTHER',
  TYRES = 'TYRES'
}

/** Damage categories related to mechanical damage report */
export enum MechanicalDamageCategory {
  MECHANICAL = 'MECHANICAL'
}

export type MechanicalModuleConfiguration = {
  __typename?: 'MechanicalModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type ModulesConfiguration = {
  __typename?: 'ModulesConfiguration';
  /** Accident reporting module configuration */
  ACCIDENT_REPORT?: Maybe<AccidentWithCarModuleConfiguration>;
  /** Accident without another car reporting module configuration */
  ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT?: Maybe<AccidentWithoutCarModuleConfiguration>;
  /** Car identification configuration. Previous name: DRIVER_LOGIN */
  CAR_IDENTIFICATION?: Maybe<CarIdentificationModuleConfiguration>;
  /** Checklist module configuration */
  CHECKLIST?: Maybe<ChecklistsModuleConfiguration>;
  /** Driver identification module configuration */
  DRIVER_IDENTIFICATION?: Maybe<DriverIdentificationModuleConfiguration>;
  /** Driver information module configuration */
  DRIVER_INFORMATION?: Maybe<DriverInformationModuleConfiguration>;
  /** Exterior damage reporting module configuration */
  EXTERIOR_DAMAGE_REPORT?: Maybe<ExteriorModuleConfiguration>;
  /** External damage report link */
  EXTERNAL_DAMAGE_REPORT_LINK?: Maybe<ExternalDamageReportLinkModuleConfiguration>;
  /** Interior damage reporting module configuration */
  INTERIOR_DAMAGE_REPORT?: Maybe<InteriorModuleConfiguration>;
  /** Mechanical flow configuration */
  MECHANICAL_DAMAGE_REPORT?: Maybe<MechanicalModuleConfiguration>;
  /** Car services module configuration */
  SERVICES?: Maybe<CommonModuleConfiguration>;
  /** Service lights module configuration */
  SERVICE_LIGHTS_REPORT?: Maybe<ServiceLightsModuleConfiguration>;
  /** Telematic report */
  TELEMATIC_REPORT?: Maybe<CommonModuleConfiguration>;
  /** Third party reporting module configuration */
  THIRD_PARTY_REPORT?: Maybe<ThirdPartyModuleConfiguration>;
  /** User management module configuration */
  USER_MANAGEMENT?: Maybe<UserManagementModuleConfiguration>;
};

export type ModulesConfigurationInput = {
  /** Driver information module configuration */
  DRIVER_INFORMATION?: InputMaybe<DriverInformationModuleConfigurationInput>;
  /** External damage report link */
  EXTERNAL_DAMAGE_REPORT_LINK?: InputMaybe<ExternalDamageReportLinkModuleConfigurationInput>;
  /** Car services module configuration */
  SERVICES?: InputMaybe<CommonModuleConfigurationInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Deletes car */
  DeleteCar: MutationResult;
  /** Onboard bodyshop */
  addBodyshop: AddBodyshopResult;
  /** It creates a cost and return it in case of successful creation */
  addCost: CostResult;
  /** Adding new tag if it is not exist */
  addDamageReportTag: MutationResult;
  /** Add new insurance */
  addInsurance: AddInsuranceResult;
  /** Adds a service to a car */
  addServiceCar: MutationResult;
  /** Add a new theme */
  addTheme: ActionResult;
  /** Adjust repair offer */
  adjustRepairOffer: RepairOfferActionResult;
  /** Approve/reject estimation */
  approveEstimation: PortalActionResult;
  /** Approve/reject inspection on site offer */
  approveInspectionOnSiteOffer: RepairOfferActionResult;
  /** Approve/reject mechanical offer */
  approveMechanicalOffer: RepairOfferActionResult;
  /** Approve/reject repair offer */
  approveRepairOffer: RepairOfferActionResult;
  /** Assign organization user to the damage report */
  assignUserToDamage: MutationResult;
  /** Cancels repair offer request, if it has not yet been processed by a bodyshop. */
  cancelRepairOfferRequest: RepairOfferActionResult;
  /** Changes status of the damage report */
  changeDamageReportStatus: ChangeDamageReportStatusResult;
  /** Changes status of the pre-inspection damage report */
  changePreInspectionDamageReportStatus: ChangeDamageReportStatusResult;
  checkHealth: HealthObject;
  /** Close a service for a car by fleet and operations */
  closeCarService: MutationResult;
  /** Close damage report */
  closeIndicatedDamageReport: CloseIndicatedDamageReportResult;
  /** Complete accident report */
  completeAccidentReport: CompleteAccidentReportResult;
  /** Complete a service for a car by bodyshop */
  completeCarService: MutationResult;
  /** Completes damage report verification process */
  completeDamageReportVerification: CompleteDamageReportVerificationResult;
  /** Complete exterior damage report */
  completeExteriorDamageReport: CompleteExteriorDamageReportResult;
  /** Completes damage report modify process */
  completeIndicatedDamageReport: CompleteIndicatedDamageReportResult;
  /** Completes inspection on site */
  completeInspectionOnSite: RepairOffer;
  /** It reports an interior damage */
  completeInteriorDamageReport: ActionResult;
  /** Completes mechanical damage reporting and creates the report */
  completeMechanicalDamageReport: ActionResult;
  /** Complete pre inspection report */
  completePreInspectionReport: CompletePreInspectionReportResult;
  /** Complete simple accident report */
  completeSimpleAccidentReport: MutationResult;
  /** Complete pre third party report */
  completeThirdPartyReport: ExtendedMutationResult;
  /** Confirm bodyshop app appointment (by bodyshop user) */
  confirmBodyshopAppAppointment: ConfirmBodyshopAppAppointmentResult;
  /** Confirms a service appointment */
  confirmServiceAppointment: MutationResult;
  /** Creates API token */
  createApiToken: ExtendedMutationResult;
  createChecklistReport: CreateChecklistReportResult;
  createChecklistTemplate: CreateChecklistTemplateResult;
  /** Create new email template */
  createEmailTemplate: MutationResult;
  /** Create new fleet with cars and users */
  createFleet: CreateFleetResult;
  /** Creates repair offer */
  createRepairOffer: RepairOffer;
  /** It reports the service lights report for the current car. */
  createServiceLightsReport: ActionResult;
  /** Create sms template */
  createSmsTemplate: CreateSmsTemplateResult;
  /** Create new Steerer with users */
  createSteerer: CreateSteererResult;
  /** It creates a telematic damage */
  createTelematicReport: ActionResult;
  /** Creates a new user in the system */
  createUser: SignUpResult;
  /** Invoke damage report action */
  damageReportActionInvoke: ActionResult;
  /** Deletes all checklist damages */
  deleteChecklistAllDamages: MutationResult;
  /** Deletes a checklist damage */
  deleteChecklistDamage: DeleteChecklistDamageResult;
  /** Deletes checklist report images */
  deleteChecklistReportImage: MutationResult;
  /** It deletes a cost and return it in case of successful delete */
  deleteCost: MutationResult;
  /** Deletes damage report documents */
  deleteDamageReportDocuments: ExtendedMutationResult;
  /** Deletes damage report images */
  deleteDamageReportImage: MutationResult;
  /** Accepts one of the appointments suggested by a bodyshop */
  driverAcceptAppointment: ActionResult;
  /** Requests other appointment date than suggested */
  driverRequestCustomAppointment: ActionResult;
  /** Exports all cars available to user */
  exportCars: ExportCarsResult;
  /** Exports all cars available to user */
  exportCarsTemplate: ExportCarsResult;
  /** Changes status of the damage to repaired */
  finishDamageRepair: ChangeDamageReportStatusResult;
  /** Forced onboarding fleets and cars to EG */
  forceOnboardFleetsToEg: ExtendedMutationResult;
  /** Generate pdf report */
  generatePdfReport: MutationResult;
  /** Approve/reject repair offer adjustment */
  handleRepairOfferAdjustment: RepairOfferActionResult;
  /** Imports cars and drivers */
  importCars: ImportCarsResult;
  /** Import fleet cars and drivers */
  importCarsAndDrivers: ExtendedMutationResult;
  initChecklistDamageReport: InitChecklistDamageReportResult;
  /** ... */
  initiateNewDataExport: DataExportResult;
  /** Approves/rejects repair (repair offer/assessment) */
  makeRepairDecision: MutationResult;
  /** Accept appointment */
  managerAcceptAppointment: RepairOfferActionResult;
  /** Accept custom appointment */
  managerRequestCustomAppointment: RepairOfferActionResult;
  /** It creates damage report entity in db to handle updates of damage report */
  newDamageReport: NewDamageReportResult;
  /** Uploads assessment report */
  oldUploadAssessmentReport: MutationResult;
  /** Onboard assessor organisation */
  onboardAssessorOrganisation: OnboardAssessorOrganisationResult;
  /** Onboard bodyshop with users */
  onboardBodyshop: OnboardBodyshopResult;
  /** Refresh access token */
  refreshToken: RefreshTokenResult;
  /** It removes a checklist report image */
  removeChecklistReportImage: ExtendedMutationResult;
  /** It removes damage report image */
  removeDamageImage: ActionResult;
  /** Removing existing tag from damageReport */
  removeDamageReportTag: MutationResult;
  /** Request accessor email */
  requestAssessor: MutationResult;
  /** Requests EG processing */
  requestEgProcessing: RequestEgProcessingResult;
  /** Requests inspection on-site */
  requestInspectionOnSite: RepairOffer;
  /** Requests repair offer */
  requestRepairOffer: Array<RepairOfferRequest>;
  /** Resends invitation email in case of missed */
  resendInvitationEmail: SignUpResult;
  /** Reset user password */
  resetPassword: ResetPasswordResult;
  /** It swaps original image with the rotated version */
  rotateChecklistReportImage: MutationResult;
  /** It swaps original image with the rotated version */
  rotateDamageReportImage: MutationResult;
  /** Add bodyshop appointments and additional services */
  scheduleAppointment: MutationResult;
  /** Schedules a service for a car */
  scheduleCarService: MutationResult;
  /** Send email with link to damage report page */
  sendDamageReportLink: SendDamageReportLinkResult;
  /** Send email with link to damage report verification page */
  sendDamageReportVerificationLink: SendDamageReportVerificationLinkResult;
  /** Send email with link to damage report modify page */
  sendIndicatedDamageReportLink: SendIndicatedDamageReportLinkResult;
  /** Sends OTP to user */
  sendOtp: SendOtpResult;
  /** Send email or sms with link to pre-inspection report page */
  sendPreInspectionReportLink: SendDamageReportLinkResult;
  /** Send report to DMS system */
  sendReportToDms: MutationResult;
  /** Send email with link to reset password */
  sendResetPasswordLink: SendResetPasswordLinkResult;
  /** Sets Anyline recognition token in the database for all the domains, embedded in the token */
  setAnylineToken: SetAnylineTokenResultType;
  /** Set default manager for bodyshop */
  setBodyshopDefaultManager: DefaultManager;
  /** Sets bodyshop partner ID and "IsFleetProcessing" configuration */
  setBodyshopPartnerId: ExtendedMutationResult;
  setDamageReportSettled: MutationResult;
  /** Set default manager for fleet */
  setFleetDefaultManager: DefaultManager;
  /** Needs to be called when fleet is ready on EG side to initial cars onboard */
  setFleetIsEgReady: ExtendedMutationResult;
  /** Shares damage report link */
  shareDamageReportLink: MutationResult;
  /** Start a service for a car by bodyshop */
  startCarService: MutationResult;
  startChecklistReport: StartChecklistReportResult;
  /** Changes status of the damage to in_repair */
  startDamageRepair: ChangeDamageReportStatusResult;
  /** Syncs damage report to Carisma (DMS) */
  syncDamageReportToCarisma: ExtendedMutationResult;
  /** Active/disable cars either in bulk or separately */
  updateCarActivation: MutationResult;
  /** Updates  */
  updateCars: MutationResult;
  /** Updates cars availability statuses either in bulk or separately */
  updateCarsAvailability: MutationResult;
  /** Updates checklist report fleet comment */
  updateChecklistFleetComment: ChecklistResult;
  updateChecklistReport: UpdateChecklistReportResult;
  updateCompletedDamageReportInformation: MutationResult;
  /** It updates a cost and return it in case of successful update */
  updateCost: CostResult;
  updateDamageImage: UpdateDamageImageResult;
  /** Updates damage report */
  updateDamageReport: MutationResult;
  /** Update damage's report comment */
  updateDamageReportComment: MutationResult;
  /** It updates status images from the portal */
  updateDamageReportImages: MutationResult;
  updateDamageReportInformation: GenericDamageReport;
  /** Updates damage report via portal */
  updateDamageReportPortal: MutationResult;
  /** Update driver's camera access consent */
  updateDriverCameraAccessConsent: UpdateDriverCameraAccessConsentResult;
  /** Updates an existing email template */
  updateEmailTemplate: MutationResult;
  /** Updates fleet configuration, by passing partial fleet config (Unsets field, if passed value null; Replaces array values) */
  updateFleetConfiguration: ExtendedMutationResult;
  /** Update fleet metrics */
  updateFleetMetrics: ExtendedMutationResult;
  /** Updates steerer configuration, by passing partial steerer config (Unsets field, if passed value null; Replaces array values) */
  updateSteererConfiguration: ExtendedMutationResult;
  /** Updates list of suggested assessor organisations */
  updateSuggestedAssessorOrganisations: MutationResult;
  /** Updates list of suggested bodyshops */
  updateSuggestedBodyshops: MutationResult;
  /** Updates the user details in the system */
  updateUserDetails: UserEditResult;
  /** Changes user isEnabled status */
  updateUserEnabledStatus: MutationResult;
  /** Updates personal info in user profile */
  updateUserPersonalInfo: UserEditResult;
  /** Updates the user personal notifications group settings */
  updateUserPersonalNotificationSettings: UpdatePersonalNotificationSettingsResult;
  /** Uploads assessment report */
  uploadAssessmentReport: MutationResult;
  /** Uploads image to checklist report */
  uploadChecklistReportImage: UploadChecklistReportImageResult;
  /** It uploads image from the portal */
  uploadChecklistReportImages: MutationResult;
  /** Uploads damage report documents */
  uploadDamageReportDocument: ExtendedMutationResult;
  /** It uploads image from the portal */
  uploadDamageReportImages: MutationResult;
  /** It uploads the given image. */
  uploadImage: UploadImageResult;
  /** Upload Invoices for repair offer */
  uploadInvoice: InvoiceUploadResult;
  /** Uploading service invoice file by bodyshop */
  uploadServiceInvoice: MutationResult;
  /** Sign in user */
  userSignIn: UserSignInResult;
  /** Sign out user */
  userSignOut: UserSignOutResult;
  /** Verifies costs */
  verifyCosts: Array<CostResult>;
  /** Verifies OTP */
  verifyOtp: VerifyOtpResult;
};


export type MutationDeleteCarArgs = {
  input: DeleteCarInput;
};


export type MutationAddBodyshopArgs = {
  input: AddBodyshopInput;
};


export type MutationAddCostArgs = {
  input: AddCostInput;
};


export type MutationAddDamageReportTagArgs = {
  input: AddTagInput;
};


export type MutationAddInsuranceArgs = {
  input: AddInsuranceInput;
};


export type MutationAddServiceCarArgs = {
  input: AddServiceCarInput;
};


export type MutationAddThemeArgs = {
  themes: Array<AddThemeInput>;
};


export type MutationAdjustRepairOfferArgs = {
  adjustmentDocument: Scalars['Upload']['input'];
  adjustmentImages?: InputMaybe<Array<Scalars['Upload']['input']>>;
  input: AdjustRepairOfferInput;
};


export type MutationApproveEstimationArgs = {
  input: ApproveEstimationInput;
};


export type MutationApproveInspectionOnSiteOfferArgs = {
  input: RepairOfferActionsInput;
};


export type MutationApproveMechanicalOfferArgs = {
  input: RepairOfferActionsInput;
};


export type MutationApproveRepairOfferArgs = {
  input: RepairOfferActionsInput;
};


export type MutationAssignUserToDamageArgs = {
  input: AssignUserInput;
};


export type MutationCancelRepairOfferRequestArgs = {
  input: CancelRepairOfferInput;
};


export type MutationChangeDamageReportStatusArgs = {
  input: ChangeDamageReportStatusInput;
};


export type MutationChangePreInspectionDamageReportStatusArgs = {
  input: ChangePreInspectionReportStatusInput;
};


export type MutationCloseCarServiceArgs = {
  input: CloseCarServiceInput;
};


export type MutationCloseIndicatedDamageReportArgs = {
  input: CloseIndicatedDamageReportInput;
};


export type MutationCompleteAccidentReportArgs = {
  input: CompleteAccidentReportInput;
};


export type MutationCompleteCarServiceArgs = {
  input: CompleteCarServiceInput;
};


export type MutationCompleteDamageReportVerificationArgs = {
  input: CompleteDamageReportVerificationInput;
};


export type MutationCompleteExteriorDamageReportArgs = {
  input: CompleteExteriorDamageReportInput;
};


export type MutationCompleteIndicatedDamageReportArgs = {
  input: CompleteIndicatedDamageReportInput;
};


export type MutationCompleteInspectionOnSiteArgs = {
  additionalPhotos?: InputMaybe<Array<Scalars['Upload']['input']>>;
  estimationDocument: Scalars['Upload']['input'];
  input: CompleteInspectionOnSiteInput;
};


export type MutationCompleteInteriorDamageReportArgs = {
  input: CompleteInteriorDamageReportInput;
};


export type MutationCompleteMechanicalDamageReportArgs = {
  input: CompleteMechanicalDamageReportInput;
};


export type MutationCompletePreInspectionReportArgs = {
  input: CompletePreInspectionReportInput;
};


export type MutationCompleteSimpleAccidentReportArgs = {
  input: CompleteSimpleAccidentReportInput;
};


export type MutationCompleteThirdPartyReportArgs = {
  input: CompleteThirdPartyReportInput;
};


export type MutationConfirmBodyshopAppAppointmentArgs = {
  input: ConfirmAppointmentInput;
};


export type MutationConfirmServiceAppointmentArgs = {
  input: ConfirmServiceAppointmentInput;
};


export type MutationCreateApiTokenArgs = {
  input: CreateApiTokenInput;
};


export type MutationCreateChecklistReportArgs = {
  templateId: Scalars['ID']['input'];
};


export type MutationCreateChecklistTemplateArgs = {
  input: CreateChecklistTemplateInput;
};


export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};


export type MutationCreateFleetArgs = {
  input: CreateFleetInput;
};


export type MutationCreateRepairOfferArgs = {
  estimationDocument?: InputMaybe<Scalars['Upload']['input']>;
  input: CreateRepairOfferInput;
};


export type MutationCreateServiceLightsReportArgs = {
  input: ServiceLightsReportInput;
};


export type MutationCreateSmsTemplateArgs = {
  input: CreateSmsTemplateInput;
};


export type MutationCreateSteererArgs = {
  input: CreateSteererInput;
};


export type MutationCreateTelematicReportArgs = {
  input: TelematicReportInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDamageReportActionInvokeArgs = {
  input: DamageReportActionInput;
};


export type MutationDeleteChecklistDamageArgs = {
  damageReportId: Scalars['ID']['input'];
};


export type MutationDeleteChecklistReportImageArgs = {
  input: DeleteChecklistReportImageInput;
};


export type MutationDeleteCostArgs = {
  input: DeleteCostInput;
};


export type MutationDeleteDamageReportDocumentsArgs = {
  input: DeleteDamageReportDocumentsInput;
};


export type MutationDeleteDamageReportImageArgs = {
  input: DeleteDamageReportImageInput;
};


export type MutationDriverAcceptAppointmentArgs = {
  input: DriverAcceptAppointmentInput;
};


export type MutationDriverRequestCustomAppointmentArgs = {
  input: DriverRequestCustomAppointmentInput;
};


export type MutationExportCarsArgs = {
  input: ExportCarsInput;
};


export type MutationExportCarsTemplateArgs = {
  input: ExportCarsInput;
};


export type MutationFinishDamageRepairArgs = {
  id: Scalars['String']['input'];
  input?: InputMaybe<ChangeDamageReportStatusInput>;
};


export type MutationGeneratePdfReportArgs = {
  input: GeneratePdfReportInput;
};


export type MutationHandleRepairOfferAdjustmentArgs = {
  input: RepairOfferAdjustmentActionsInput;
};


export type MutationImportCarsArgs = {
  input: ImportCarsInput;
  sourceSheetFile: Scalars['Upload']['input'];
};


export type MutationImportCarsAndDriversArgs = {
  input: ImportCarsAndDriversInput;
  sourceSheetFile: Scalars['Upload']['input'];
};


export type MutationInitChecklistDamageReportArgs = {
  input: InitChecklistDamageReportInput;
};


export type MutationInitiateNewDataExportArgs = {
  fleetId: Scalars['String']['input'];
};


export type MutationMakeRepairDecisionArgs = {
  input: MakeRepairDecisionInput;
};


export type MutationManagerAcceptAppointmentArgs = {
  input: RepairOfferActionsInput;
};


export type MutationManagerRequestCustomAppointmentArgs = {
  input: RepairOfferActionsInput;
};


export type MutationOldUploadAssessmentReportArgs = {
  file: Scalars['Upload']['input'];
  input: OldUploadAssessmentReportInput;
};


export type MutationOnboardAssessorOrganisationArgs = {
  input: OnboardAssessorOrganisationInput;
};


export type MutationOnboardBodyshopArgs = {
  input: OnboardBodyshopInput;
};


export type MutationRemoveChecklistReportImageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveDamageImageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveDamageReportTagArgs = {
  input: RemoveTagInput;
};


export type MutationRequestAssessorArgs = {
  input: RequestAssessorInput;
};


export type MutationRequestEgProcessingArgs = {
  input: RequestEgProcessingInput;
};


export type MutationRequestInspectionOnSiteArgs = {
  input: RequestEstimationOnSiteInput;
};


export type MutationRequestRepairOfferArgs = {
  input: RequestRepairOfferInput;
};


export type MutationResendInvitationEmailArgs = {
  input: ResendInvitationEmailInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRotateChecklistReportImageArgs = {
  input: RotateChecklistReportImageInput;
};


export type MutationRotateDamageReportImageArgs = {
  input: RotateDamageReportImageInput;
};


export type MutationScheduleAppointmentArgs = {
  input: ScheduleAppointmentsInput;
};


export type MutationScheduleCarServiceArgs = {
  input: ScheduleCarServiceInput;
};


export type MutationSendDamageReportLinkArgs = {
  input: SendDamageReportLinkInput;
};


export type MutationSendDamageReportVerificationLinkArgs = {
  input: SendDamageReportVerificationLinkInput;
};


export type MutationSendIndicatedDamageReportLinkArgs = {
  input: SendIndicatedDamageReportLinkInput;
};


export type MutationSendOtpArgs = {
  input: SendOtpInput;
};


export type MutationSendPreInspectionReportLinkArgs = {
  input: SendPreInspectionReportLinkInput;
};


export type MutationSendReportToDmsArgs = {
  input: SendReportToDmsInput;
};


export type MutationSendResetPasswordLinkArgs = {
  input: SendResetPasswordLinkInput;
};


export type MutationSetAnylineTokenArgs = {
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type MutationSetBodyshopDefaultManagerArgs = {
  input: SetBodyshopDefaultManagerInput;
};


export type MutationSetBodyshopPartnerIdArgs = {
  input: SetBodyshopPartnerIdInput;
};


export type MutationSetDamageReportSettledArgs = {
  input: SetDamageReportSettledInput;
};


export type MutationSetFleetDefaultManagerArgs = {
  input: SetFleetDefaultManagerInput;
};


export type MutationSetFleetIsEgReadyArgs = {
  input: SetFleetIsEgReadyInput;
};


export type MutationShareDamageReportLinkArgs = {
  files: Array<Scalars['Upload']['input']>;
  input: ShareDamageReportLinkInput;
};


export type MutationStartCarServiceArgs = {
  input: StartCarServiceInput;
};


export type MutationStartDamageRepairArgs = {
  id: Scalars['String']['input'];
  input?: InputMaybe<ChangeDamageReportStatusInput>;
};


export type MutationSyncDamageReportToCarismaArgs = {
  input: SyncDamageReportToCarismaInput;
};


export type MutationUpdateCarActivationArgs = {
  input: UpdateCarActivationInput;
};


export type MutationUpdateCarsArgs = {
  input: UpdateCarsInput;
};


export type MutationUpdateCarsAvailabilityArgs = {
  input: UpdateCarsAvailabilityInput;
};


export type MutationUpdateChecklistFleetCommentArgs = {
  input: UpdateChecklistFleetCommentInput;
};


export type MutationUpdateChecklistReportArgs = {
  input: UpdateChecklistReportInput;
};


export type MutationUpdateCompletedDamageReportInformationArgs = {
  input: UpdateCompletedDamageReportInformationInput;
};


export type MutationUpdateCostArgs = {
  input: UpdateCostInput;
};


export type MutationUpdateDamageImageArgs = {
  input: UpdateDamageImageInput;
};


export type MutationUpdateDamageReportArgs = {
  input: UpdateDamageReportInput;
};


export type MutationUpdateDamageReportCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateDamageReportImagesArgs = {
  input: UpdateDamageReportImagesInput;
};


export type MutationUpdateDamageReportInformationArgs = {
  input: UpdateDamageReportInformationInput;
};


export type MutationUpdateDamageReportPortalArgs = {
  input: UpdateDamageReportPortalInput;
};


export type MutationUpdateDriverCameraAccessConsentArgs = {
  input: UpdateDriverCameraAccessConsentInput;
};


export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};


export type MutationUpdateFleetConfigurationArgs = {
  input: Array<UpdateFleetConfigurationInput>;
};


export type MutationUpdateFleetMetricsArgs = {
  input: Array<FleetMetricsInput>;
};


export type MutationUpdateSteererConfigurationArgs = {
  input: Array<UpdateSteererConfigurationInput>;
};


export type MutationUpdateSuggestedAssessorOrganisationsArgs = {
  input: UpdateSuggestedAssessorOrganisationInput;
};


export type MutationUpdateSuggestedBodyshopsArgs = {
  input: UpdateSuggestedBodyshopInput;
};


export type MutationUpdateUserDetailsArgs = {
  input: UpdateUserDetailsInput;
};


export type MutationUpdateUserEnabledStatusArgs = {
  input: UpdateUserEnabledStatusInput;
};


export type MutationUpdateUserPersonalInfoArgs = {
  input: UpdateUserPersonalInfoInput;
};


export type MutationUpdateUserPersonalNotificationSettingsArgs = {
  input: UpdatePersonalNotificationSettingsInput;
};


export type MutationUploadAssessmentReportArgs = {
  files: Array<Scalars['Upload']['input']>;
  input: UploadAssessmentReportInput;
};


export type MutationUploadChecklistReportImageArgs = {
  input: ChecklistReportImageInput;
};


export type MutationUploadChecklistReportImagesArgs = {
  input: UploadDamageReportImagesInput;
};


export type MutationUploadDamageReportDocumentArgs = {
  files: Array<Scalars['Upload']['input']>;
  input: UploadDamageReportDocumentInput;
};


export type MutationUploadDamageReportImagesArgs = {
  input: UploadDamageReportImagesInput;
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isVisibilityRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<DamageReportImageType>;
};


export type MutationUploadInvoiceArgs = {
  files: Array<Scalars['Upload']['input']>;
  input: InvoiceUploadInput;
};


export type MutationUploadServiceInvoiceArgs = {
  file: Scalars['Upload']['input'];
  input: ServiceInvoiceUploadInput;
};


export type MutationUserSignInArgs = {
  input: UserSignInInput;
};


export type MutationVerifyCostsArgs = {
  input: VerifyCostsInput;
};


export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};

export type MutationResult = {
  __typename?: 'MutationResult';
  /** Error */
  error?: Maybe<Scalars['String']['output']>;
  /** Status of mutation */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum Notification_Setting_Option {
  ALL = 'ALL',
  MIXED = 'MIXED',
  NONE = 'NONE'
}

export type NewDamageReportResult = {
  __typename?: 'NewDamageReportResult';
  /** New code for reporting new damage */
  code: Scalars['String']['output'];
  /** New damage report ID */
  id: Scalars['String']['output'];
};

export type OcrRegistrationDataResult = {
  __typename?: 'OCRRegistrationDataResult';
  /** Car registration company */
  carRegistrationCompany?: Maybe<Scalars['String']['output']>;
  /** Address */
  driverAddress?: Maybe<Scalars['String']['output']>;
  /** City */
  driverCity?: Maybe<Scalars['String']['output']>;
  /** Driver name (company name) */
  driverName?: Maybe<Scalars['String']['output']>;
  /** ZIP code */
  driverZip?: Maybe<Scalars['String']['output']>;
  /** First registration date */
  firstRegistration?: Maybe<Scalars['String']['output']>;
  /** The date of inspection */
  inspectionDate?: Maybe<Scalars['String']['output']>;
  /** Wheteher driver info was recognized by OCR service */
  isDriverInfoOCRed?: Maybe<Scalars['Boolean']['output']>;
  /** License Plate */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** VIN */
  vin?: Maybe<Scalars['String']['output']>;
};

export type OldUploadAssessmentReportInput = {
  /** Comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage report id */
  id: Scalars['String']['input'];
};

export type OnboardAssessorOrganisationInput = {
  /** Address city */
  addressCity: Scalars['String']['input'];
  /** Address house */
  addressHouse: Scalars['String']['input'];
  /** Address street */
  addressStreet: Scalars['String']['input'];
  /** Address zip */
  addressZip: Scalars['String']['input'];
  /** Contact person */
  contactPerson: Scalars['String']['input'];
  /** Email */
  email: Scalars['String']['input'];
  /** Assessor organisation ID */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Assessor organisation title (will be used in emails, UI) */
  title: Scalars['String']['input'];
};

export type OnboardAssessorOrganisationResult = {
  __typename?: 'OnboardAssessorOrganisationResult';
  /** Id of onboarded assessor organisation */
  assessorOrganisationId?: Maybe<Scalars['String']['output']>;
  /** Status */
  success: Scalars['Boolean']['output'];
};

export type OnboardBodyshopInput = {
  dryRun?: Scalars['Boolean']['input'];
  /** List of bodyshops to onboard */
  list: Array<OnboardBodyshopItem>;
};

export type OnboardBodyshopItem = {
  /** Address city */
  addressCity: Scalars['String']['input'];
  /** Address house */
  addressHouse: Scalars['String']['input'];
  /** Address street */
  addressStreet: Scalars['String']['input'];
  /** Address zip */
  addressZip: Scalars['String']['input'];
  /** Customer care email */
  ccEmailList?: InputMaybe<Scalars['String']['input']>;
  /** Bodyshop configuration */
  configuration: BodyshopConfigurationInput;
  /** Contact person */
  contactPerson: Scalars['String']['input'];
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Bodyshop ID */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Bodyshop name (will be used in app link domain) */
  name: Scalars['String']['input'];
  /** Onboarding date */
  onboardingDate?: InputMaybe<Scalars['Date']['input']>;
  /** Bodyshop package */
  package?: InputMaybe<BodyshopPackageType>;
  /** Parent bodyshop name (if bodyshop is part of bodyshop group) */
  parentBodyshopName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Steerers ids or names */
  steerers: Array<Scalars['String']['input']>;
  /** Bodyshop title (will be used in emails, UI) */
  title: Scalars['String']['input'];
  /** Bodyshop users */
  users: Array<BodyshopUser>;
};

export type OnboardBodyshopResult = {
  __typename?: 'OnboardBodyshopResult';
  /** Hint on existing bodyshops in case of dryRun = true */
  hint?: Maybe<Array<ExistingBodyshop>>;
  /** Onboarded bodyshops data */
  result?: Maybe<Array<Bodyshop>>;
  /** Status of onboarded bodyshop */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Operations = {
  __typename?: 'Operations';
  actions: AccessControlActionType;
  resources: AccessControlResourceType;
};

export type PaginationArgs = {
  /** Damage reports count on the page */
  limit?: Scalars['Int']['input'];
  /** Start page */
  page?: Scalars['Int']['input'];
  /** Damage reports sort by */
  sortBy?: ReportsSortBy;
  /** Damage reports sort order */
  sortOrder?: SortOrder;
};

export type PendingApprovalInput = {
  /** Fleet approval */
  fleet?: InputMaybe<Scalars['Boolean']['input']>;
  /** Insurance approval */
  insurance: Scalars['Boolean']['input'];
  /** Leasing approval */
  leasing: Scalars['Boolean']['input'];
};

export type PersonalNotificationGroupInput = {
  APPOINTMENTS?: InputMaybe<Scalars['Boolean']['input']>;
  CHECKLIST_REPORT?: InputMaybe<Scalars['Boolean']['input']>;
  DAMAGE_REPORT?: InputMaybe<Scalars['Boolean']['input']>;
  INVOICE?: InputMaybe<Scalars['Boolean']['input']>;
  OTHERS?: InputMaybe<Scalars['Boolean']['input']>;
  REPAIR_OFFER?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PersonalNotificationGroupItemType = {
  __typename?: 'PersonalNotificationGroupItemType';
  /** Name of display group for notification groups */
  displayInGroup?: Maybe<Supported_Display_Groups_For_Notification_Groups>;
  /** Value of group settings ALL|NONE|MIXED */
  value: Notification_Setting_Option;
};

export type PersonalNotificationGroupType = {
  __typename?: 'PersonalNotificationGroupType';
  APPOINTMENTS?: Maybe<PersonalNotificationGroupItemType>;
  CHECKLIST_REPORT?: Maybe<PersonalNotificationGroupItemType>;
  DAMAGE_REPORT?: Maybe<PersonalNotificationGroupItemType>;
  INVOICE?: Maybe<PersonalNotificationGroupItemType>;
  OTHERS?: Maybe<PersonalNotificationGroupItemType>;
  REPAIR_OFFER?: Maybe<PersonalNotificationGroupItemType>;
};

export type PersonalNotificationSettingsResult = {
  __typename?: 'PersonalNotificationSettingsResult';
  /** Are all notifications muted (Disabled) */
  areAllNotificationsMuted: Scalars['Boolean']['output'];
  /** Detailed settings for notifications groups */
  notificationGoups?: Maybe<PersonalNotificationGroupType>;
};

export type PersonalNumberResult = {
  __typename?: 'PersonalNumberResult';
  /** Is a field is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Personal number of user with internal company affiliation */
  value?: Maybe<Scalars['String']['output']>;
};

export type PmAuth = {
  __typename?: 'PmAuth';
  /** Process Manager */
  customer: Scalars['String']['output'];
  /** Token */
  token: Scalars['String']['output'];
};

export type PoliceReport = {
  __typename?: 'PoliceReport';
  id: Scalars['ID']['output'];
  isRegistered?: Maybe<Scalars['Boolean']['output']>;
  policeOfficerName?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  reportNumber?: Maybe<Scalars['String']['output']>;
};

export type PoliceReportInput = {
  /** Indicates if the accident has been registered by the police at all */
  isRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of a police officer */
  policeOfficerName?: InputMaybe<Scalars['String']['input']>;
  /** Indicates why the accident was not registered by the police */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** Number of a police report */
  reportNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PoolPrimaryContact = {
  __typename?: 'PoolPrimaryContact';
  /** Primary contact email */
  email: Scalars['String']['output'];
  /** Primary contact full name */
  fullName: Scalars['String']['output'];
  /** Primary contact phone number */
  phone: Scalars['String']['output'];
};

export type PortalActionResult = {
  __typename?: 'PortalActionResult';
  /** Repair offer id */
  id: Scalars['String']['output'];
  /** Whether the action was successful */
  success: Scalars['Boolean']['output'];
};

export type PortalCar = {
  __typename?: 'PortalCar';
  activeDamages: Scalars['Float']['output'];
  /** Describes the availability status of the car */
  availability?: Maybe<CarAvailabilityStatus>;
  /** Body style */
  bodyStyle: BodyStyle;
  /** Damage documents */
  carDocuments?: Maybe<Array<CarDocument>>;
  /** Total number of new damages */
  carNewDamages?: Maybe<Scalars['Float']['output']>;
  /** Car registration date */
  firstRegistration?: Maybe<Scalars['Date']['output']>;
  /** Fleet */
  fleet?: Maybe<Fleet>;
  /** ID of the car */
  id: Scalars['ID']['output'];
  /** Whether or not the car is active */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the car is used by many people */
  isPool?: Maybe<Scalars['Boolean']['output']>;
  /** Liable user */
  liableUser?: Maybe<UserInfoResult>;
  /** License plate number of the car */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Car location */
  location?: Maybe<Scalars['String']['output']>;
  /** Car manufacturer */
  make: Scalars['String']['output'];
  /** Latest reported mileage */
  mileage?: Maybe<Scalars['Float']['output']>;
  /** Date of latest reported mileage */
  mileageReportedDate?: Maybe<Scalars['Date']['output']>;
  /** Car model */
  model: Scalars['String']['output'];
  /** Partner ID of the car */
  partnerId?: Maybe<Scalars['String']['output']>;
  /** Primary contact user */
  primaryContact?: Maybe<PrimaryContact>;
  /** Number of processed damages */
  processedDamages?: Maybe<Scalars['Float']['output']>;
  /** Car Settings */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** Total number of completed damages */
  totalCompletedDamages?: Maybe<Scalars['Float']['output']>;
  /** Total number of damages */
  totalDamages?: Maybe<Scalars['Float']['output']>;
  /** VIN of the car */
  vin?: Maybe<Scalars['String']['output']>;
  /** Car warranty expiration date */
  warrantyExpirationDate?: Maybe<Scalars['Date']['output']>;
};

export enum PortalLang {
  DE = 'DE',
  EN = 'EN'
}

/** Type of the preInspection damage reason */
export enum PreInspectionDamageReasons {
  ANIMAL_COLLISION = 'ANIMAL_COLLISION',
  CAR_COLLISION = 'CAR_COLLISION',
  LANE_CHANGE = 'LANE_CHANGE',
  OBJECT_COLLISION = 'OBJECT_COLLISION',
  OTHER = 'OTHER',
  OVERHAUL = 'OVERHAUL',
  PARKED_CAR_COLLISION = 'PARKED_CAR_COLLISION',
  PARKING_DAMAGE = 'PARKING_DAMAGE',
  REAR_END_COLLISION = 'REAR_END_COLLISION',
  RIGHT_OF_WAY_VIOLATION = 'RIGHT_OF_WAY_VIOLATION',
  ROADWAY_AGREEMENT = 'ROADWAY_AGREEMENT',
  STONE_CHIP = 'STONE_CHIP',
  WEATHER_DAMAGE = 'WEATHER_DAMAGE'
}

export type PrimaryContact = {
  __typename?: 'PrimaryContact';
  /** Primary contact email */
  email: Scalars['String']['output'];
  /** Primary contact full name */
  fullName: Scalars['String']['output'];
  /** Primary contact phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** Primary contact role */
  role: UserRole;
};

export type ProcessManagerAuthInput = {
  /** Process Manager */
  customer: Scalars['String']['input'];
  /** Token */
  token: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** It returns a list access control constants */
  accessControlOperations: Operations;
  /** It returns the list of damages based on filter */
  allDamageReports: DamageReports;
  /** It returns app configuration */
  appConfiguration: AppConfiguration;
  /** It returns a list of users, who is able to be assigned to damage */
  availableAssigneeUsers: Array<UserInfoResult>;
  /** It returns bodyshop details */
  bodyshopDetails: BodyshopDetails;
  /** It returns is email Available */
  bodyshopEmailAvailable: BodyshopEmailAvailable;
  /** It returns list of bodyshops */
  bodyshopList: BodyshopList;
  /** It returns the count of bodyshops */
  bodyshopsCount: BodyshopsCountResult;
  car: PortalCar;
  /** It returns car list */
  carList: CarList;
  /** It returns the available filters for car list */
  carListFilters: Array<FiltersResult>;
  /** It returns the given car part */
  carPart: CarPart;
  /** It returns all available car parts */
  carParts: Array<CarPart>;
  /** It returns the filter options for car list by given field */
  carsFiltersSearch: Array<FiltersResult>;
  /** It returns all car part categories */
  categories: Array<Category>;
  /** It returns the given category */
  category: Category;
  /** Check if bodyshop exists */
  checkBodyshopExists?: Maybe<Array<ExistingBodyshop>>;
  /** It returns list of bodyshops */
  childBodyshopList: Array<Bodyshop>;
  /** It returns all damage categories */
  damageCategories: Array<DamageCategory>;
  /** It returns the given damage category */
  damageCategory: DamageCategory;
  /** Returns damage report by ID */
  damageReport: GenericDamageReport;
  /** Returns damage report assessment by damage report ID */
  damageReportAssessment?: Maybe<Assessment>;
  /** Returns damage report image list damage report by ID */
  damageReportImageList: Array<DamageReportImage>;
  /** It returns damage information by id */
  damageReportInfo: DamageReportInfo;
  /** It returns damages for the given car pictogram */
  damageReports: Array<DamageReport>;
  /** It returns the count of damages based on filter */
  damageReportsCount: DamageReportsCountResult;
  /** It returns the filter options for damage report list by given field */
  damageReportsFilterSearch: Array<FiltersResult>;
  /** It returns the list of related damages by carParts */
  damageReportsRelated: DamageReportsRelated;
  /** It returns damage statistics for the widget on damage details page */
  damageStatisticsByType: DamageStatisticsByTypeResult;
  /** It returns the filter options for a dashboard by given field */
  dashboardFilterSearch: Array<FiltersResult>;
  /** It returns fleet configuration */
  fleetConfiguration: FleetInfo;
  /** It returns current fleet info */
  fleetInfo: FleetInfo;
  /** It returns list of available fleets */
  fleetList: Array<Fleet>;
  /** It returns the cars quantity by availability status */
  getActiveCars: GetActiveCarsResult;
  /** It returns activity list for the given entity */
  getActivityList: ActivityList;
  /** Returns all tags used by tenant */
  getAllTagsByTenant: Array<Scalars['String']['output']>;
  /** It returns a list of assessors users */
  getAssessorsList: UserListResult;
  getBodyshopAppAppointment?: Maybe<BodyshopAppAppointment>;
  /** It returns car token */
  getCarAuth: GetCarAuthResult;
  /** It returns car information from token */
  getCarInfo: GetCarInfoResult;
  /** It returns current fleet car */
  getCarInfoByFleet: CarInfo;
  /** Return a checklist details */
  getChecklistDetails: ChecklistResult;
  /** Return a checklist details */
  getChecklistInfo: ChecklistInfoResult;
  /** Query list of checklistReport filters */
  getChecklistReportFilters: Array<ChecklistReportFiltersResult>;
  /** Query list of checklistReports */
  getChecklistReports: ChecklistReportListResult;
  getCompletedDamageReportToken: GetCompletedDamageReportTokenResult;
  /** It returns a list of costs their total information */
  getCosts: CostsResult;
  /** It returns a list of cost types with sign types (negative or positive) */
  getCostsSignTypes: Array<CostSignTypeObject>;
  /** Query counts of checklistReports */
  getCountsChecklistReports: CountsChecklistReportResult;
  /** It returns a list of comparison images by a damage report car */
  getDamageReportComparisonImages: GetDamageReportComparisonImagesResult;
  getDamageReportDocumentList: DamageReportDocumentList;
  /** It returns damage report token */
  getDamageToken: DamageAuth;
  /** It returns the filters configuration for the analytics dashboard */
  getDashboardFilters: Array<FiltersResult>;
  /** It returns a link where the data available for download */
  getExportDataLink: GetExportDataLinkResult;
  /** It returns a list of fleet users */
  getFleetUsers: GetFleetUsersResult;
  getHealth: HealthObject;
  /** It parsed registration data from OCR service */
  getOCRRegistrationData: OcrRegistrationDataResult;
  /** It returns the list of damages based on filter */
  getReportCarOverview: GetReportCarOverviewResult;
  /** It returns the questions for the given damage category */
  getStatistics: StatisticsResult;
  /** .... */
  getStatusOfDataExport: DataExportResult;
  /** It returns the theme by tenant id */
  getTheme: ThemeResult;
  /** It returns detailed information about a user */
  getUserDetails: UserDetailsType;
  /** It returns a list of users */
  getUserList: UserListResult;
  /** Insurance companies list */
  insuranceCompaniesList: InsuranceList;
  /** Check the validity of mail */
  isValidTokenResetPassword: IsValidResetPasswordTokenResult;
  /** It returns the given question */
  question: Question;
  /** It returns the questions for the given damage category */
  questions: Array<Question>;
  /** Returns service reporty by ID */
  serviceReportDetails: ServiceReport;
  /** It returns the list of service reports based on filter */
  serviceReportList: ServiceReportList;
  /** It returns the count of service */
  serviceReportsCount: ServiceReportsCountResult;
  /** It returns the available filters for service reports */
  serviceReportsFilter: Array<FiltersResult>;
  /** It returns list of steerers for particular bodyshop */
  steererList: Array<Steerer>;
  /** It returns lits of suggested bodyshops */
  suggestedBodyshops: Array<SuggestedBodyshop>;
  /** It returns the list of the roles, which are available for creation for this user */
  userAvailableRolesAndOrganizationsOptions: UserAvailableRolesAndOrganizationsOptionsResultObject;
  /** It returns the list of top level tenants, which are available for creation for this user */
  userAvailableTenantsOptions: Array<UserTenantObject>;
  /** It returns the list of the roles, which are available in terms of updating the provided user */
  userEditingOptions: UserEditingOptionsResultObject;
  /** Returns user information */
  userInfo: UserInfoResult;
  /** It returns the filters configuration for user list */
  userListFilters: Array<FiltersResult>;
  /** It returns the filter options for user list by given field */
  usersFilterSearch: Array<FiltersResult>;
};


export type QueryAllDamageReportsArgs = {
  assignee?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyshop?: InputMaybe<Array<Scalars['String']['input']>>;
  carId?: InputMaybe<Scalars['ID']['input']>;
  checklistReportId?: InputMaybe<Scalars['ID']['input']>;
  damageArea?: InputMaybe<Array<Scalars['String']['input']>>;
  damageType?: InputMaybe<Array<Scalars['String']['input']>>;
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  insuranceCoverageType?: InputMaybe<Array<DamageReportInsuranceCoverageType>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  roadworthy?: InputMaybe<Array<DamageReportDrivableType>>;
  sortBy?: InputMaybe<ReportsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<Array<DamageReportFilteringStatus>>;
  steerer?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<Array<DamageReportFilteringType>>;
};


export type QueryAppConfigurationArgs = {
  app?: InputMaybe<AppName>;
};


export type QueryAvailableAssigneeUsersArgs = {
  damageReportId: Scalars['String']['input'];
  q?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBodyshopDetailsArgs = {
  bodyshopId: Scalars['String']['input'];
};


export type QueryBodyshopEmailAvailableArgs = {
  email: Scalars['String']['input'];
};


export type QueryBodyshopListArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isGroup?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  webSiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCarArgs = {
  id: Scalars['String']['input'];
};


export type QueryCarListArgs = {
  availability?: InputMaybe<Array<CarAvailabilityStatus>>;
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  searchFields?: InputMaybe<Array<CarsSearchBy>>;
  sortBy?: InputMaybe<CarsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryCarListFiltersArgs = {
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCarPartArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCarsFiltersSearchArgs = {
  filters?: InputMaybe<CarListFilterArgs>;
  keys?: InputMaybe<Array<CarsFilterSearchKeysType>>;
  page?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCheckBodyshopExistsArgs = {
  bodyshop: CheckBodyshopExistsItem;
};


export type QueryChildBodyshopListArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDamageCategoriesArgs = {
  damageReportId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDamageCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDamageReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDamageReportAssessmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDamageReportImageListArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryDamageReportInfoArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDamageReportsArgs = {
  input: DamageReportsInput;
};


export type QueryDamageReportsCountArgs = {
  assignee?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyshop?: InputMaybe<Array<Scalars['String']['input']>>;
  checklistReportId?: InputMaybe<Scalars['ID']['input']>;
  damageArea?: InputMaybe<Array<Scalars['String']['input']>>;
  damageType?: InputMaybe<Array<Scalars['String']['input']>>;
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  insuranceCoverageType?: InputMaybe<Array<DamageReportInsuranceCoverageType>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  roadworthy?: InputMaybe<Array<DamageReportDrivableType>>;
  status?: InputMaybe<Array<DamageReportFilteringStatus>>;
  steerer?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<Array<DamageReportFilteringType>>;
};


export type QueryDamageReportsFilterSearchArgs = {
  filters?: InputMaybe<DamageReportsListFilterArgs>;
  keys?: InputMaybe<Array<ReportFilterSearchKeysType>>;
  page?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDamageReportsRelatedArgs = {
  currentDamageReportId: Scalars['ID']['input'];
  paginationArgs?: InputMaybe<PaginationArgs>;
};


export type QueryDamageStatisticsByTypeArgs = {
  carId: Scalars['String']['input'];
  damageCategoryTypes: Array<DamageCategoryType>;
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};


export type QueryDashboardFilterSearchArgs = {
  keys: Array<DashboardFilterSupportedKeys>;
  page?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFleetListArgs = {
  importExportFunctionality?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryGetActiveCarsArgs = {
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryGetActivityListArgs = {
  entityRefId: Scalars['ID']['input'];
  entityRefName?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  pagination?: InputMaybe<ActivityPaginationInput>;
  sort?: InputMaybe<ActivitySortInput>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryGetAllTagsByTenantArgs = {
  authTenantId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAssessorsListArgs = {
  input: GetAssessorsListArgs;
};


export type QueryGetBodyshopAppAppointmentArgs = {
  damageReportId: Scalars['ID']['input'];
};


export type QueryGetCarAuthArgs = {
  carId: Scalars['String']['input'];
};


export type QueryGetCarInfoByFleetArgs = {
  q: Scalars['String']['input'];
  searchField: CarByFleetSearchBy;
};


export type QueryGetChecklistDetailsArgs = {
  input: GetChecklistDetailsInput;
};


export type QueryGetChecklistReportFiltersArgs = {
  input: ChecklistReportListInput;
};


export type QueryGetChecklistReportsArgs = {
  input?: InputMaybe<ChecklistReportListInput>;
};


export type QueryGetCompletedDamageReportTokenArgs = {
  damageReportId: Scalars['ID']['input'];
};


export type QueryGetCostsArgs = {
  damageReportId: Scalars['String']['input'];
};


export type QueryGetCountsChecklistReportsArgs = {
  input?: InputMaybe<ChecklistReportListInput>;
};


export type QueryGetDamageReportComparisonImagesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDamageReportDocumentListArgs = {
  damageReportId: Scalars['ID']['input'];
  kind?: InputMaybe<Array<AggregatedDocumentKind>>;
};


export type QueryGetDamageTokenArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetExportDataLinkArgs = {
  input: GetExportDataLinkInput;
  lang?: InputMaybe<PortalLang>;
};


export type QueryGetFleetUsersArgs = {
  input: GetFleetUsersInput;
};


export type QueryGetReportCarOverviewArgs = {
  assignee?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyshop?: InputMaybe<Array<Scalars['String']['input']>>;
  carId: Scalars['ID']['input'];
  carParts?: InputMaybe<Array<CarPartType>>;
  checklistReportId?: InputMaybe<Scalars['ID']['input']>;
  damageArea?: InputMaybe<Array<Scalars['String']['input']>>;
  damageReportId: Scalars['ID']['input'];
  damageType?: InputMaybe<Array<Scalars['String']['input']>>;
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  insuranceCoverageType?: InputMaybe<Array<DamageReportInsuranceCoverageType>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  roadworthy?: InputMaybe<Array<DamageReportDrivableType>>;
  sortBy?: InputMaybe<ReportsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<Array<DamageReportFilteringStatus>>;
  steerer?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<Array<DamageReportFilteringType>>;
};


export type QueryGetStatisticsArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserDetailsArgs = {
  input?: InputMaybe<GetUserDetailsArgs>;
};


export type QueryGetUserListArgs = {
  input: GetUserListArgs;
};


export type QueryInsuranceCompaniesListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<InsuranceCompanySortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryIsValidTokenResetPasswordArgs = {
  input: IsValidResetPasswordToken;
};


export type QueryQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuestionsArgs = {
  damageCategory: Scalars['ID']['input'];
};


export type QueryServiceReportDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryServiceReportListArgs = {
  carId?: InputMaybe<Scalars['ID']['input']>;
  dueStatus?: InputMaybe<ServiceReportDueStatus>;
  fleet?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDueDate?: InputMaybe<Scalars['Date']['input']>;
  fromScheduledDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ServiceReportsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<Array<ServiceReportStatus>>;
  toDueDate?: InputMaybe<Scalars['Date']['input']>;
  toScheduledDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryServiceReportsCountArgs = {
  fromDueDate?: InputMaybe<Scalars['Date']['input']>;
  fromScheduledDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ServiceReportStatus>>;
  toDueDate?: InputMaybe<Scalars['Date']['input']>;
  toScheduledDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryServiceReportsFilterArgs = {
  fromDueDate?: InputMaybe<Scalars['Date']['input']>;
  fromScheduledDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ServiceReportStatus>>;
  toDueDate?: InputMaybe<Scalars['Date']['input']>;
  toScheduledDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QuerySteererListArgs = {
  bodyshopId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySuggestedBodyshopsArgs = {
  damageReportId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  serviceReportId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserAvailableRolesAndOrganizationsOptionsArgs = {
  input: GetUserAvailableRolesAndOrganizationsOptionsInput;
};


export type QueryUserAvailableTenantsOptionsArgs = {
  input: GetUserAvailableTenantsOptionsArgs;
};


export type QueryUserEditingOptionsArgs = {
  input: EditUserOptionsInput;
};


export type QueryUsersFilterSearchArgs = {
  filters?: InputMaybe<GetUserListArgs>;
  keys: Array<UserListFilterSupportedKeys>;
  page?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Question = {
  __typename?: 'Question';
  /** Possible answers of the question */
  answers: Array<Answer>;
  /** Damage category of the question */
  damageCategory: DamageCategory;
  /** ID of the question */
  id: Scalars['String']['output'];
  /** Question body */
  question: Scalars['String']['output'];
  /** Question translate key */
  translateKey: Scalars['String']['output'];
};

export type RefreshTokenResult = {
  __typename?: 'RefreshTokenResult';
  /** Newly generated access token. Expiration time: 10 minutes. */
  accessToken: Scalars['String']['output'];
};

export type RemoveTagInput = {
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Tag data */
  tags: Array<Scalars['String']['input']>;
};

export type RepairDecision = {
  /** Action name */
  actionName: DamageReportActionName;
  /** Is repair approved */
  approve: Scalars['Boolean']['input'];
  /** Assessment id */
  assessmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Comment */
  comment: Scalars['String']['input'];
  /** Repair offer id */
  repairOfferId: Scalars['ID']['input'];
};

export type RepairOffer = {
  __typename?: 'RepairOffer';
  /** The latest adjustment, suggested by the bodyshop */
  adjustment?: Maybe<Adjustment>;
  /** Appointments suggested by a bodyshop */
  appointments: Array<Appointment>;
  /** Approvals */
  approvals: Array<Approval>;
  /** Bodyshop assigned to repair offer */
  bodyshop?: Maybe<Bodyshop>;
  /** The date when the repair offer has been requested */
  createdAt: Scalars['Date']['output'];
  /** Repair offer estimation document name */
  document?: Maybe<Scalars['String']['output']>;
  /** Repair offer estimation document download url */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /** Repair maximum duration */
  durationMax?: Maybe<Scalars['Float']['output']>;
  /** Repair minimal duration */
  durationMin?: Maybe<Scalars['Float']['output']>;
  /** The date when the repair offer has been created */
  estimatedAt?: Maybe<Scalars['Date']['output']>;
  /** Estimation comment */
  estimationComment?: Maybe<Scalars['String']['output']>;
  /** ID of the repair offer */
  id: Scalars['ID']['output'];
  insuranceApprovalReceivedDate?: Maybe<Scalars['String']['output']>;
  /** The flag if damage processed based on assessment */
  isProcessedBasedOnAssessment: Scalars['Boolean']['output'];
  /** The flag if repair offer limit exceeded */
  isRepairLimitExceeded?: Maybe<Scalars['Boolean']['output']>;
  leasingApprovalReceivedDate?: Maybe<Scalars['String']['output']>;
  /** The initial repair offer price */
  price?: Maybe<Scalars['Float']['output']>;
  /** Rejection comment */
  rejectionComment?: Maybe<Scalars['String']['output']>;
  /** Request comment */
  requestComment?: Maybe<Scalars['String']['output']>;
  /** Repair scope */
  scope?: Maybe<Scalars['String']['output']>;
  /** Status of the repair offer */
  status: RepairOfferStatuses;
  /** The date when the repair offer has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type RepairOfferActionResult = {
  __typename?: 'RepairOfferActionResult';
  /** Status of the repair offer status change action */
  success: Scalars['Boolean']['output'];
};

export type RepairOfferActionsInput = {
  /** Accepted repair offer appointment ISO date */
  appointment?: InputMaybe<Scalars['String']['input']>;
  /** Accepted appointment id */
  appointmentId?: InputMaybe<Scalars['String']['input']>;
  /** Appointment type */
  appointmentType?: InputMaybe<AppointmentType>;
  /** Approval boolean of the repair offer */
  approved: Scalars['Boolean']['input'];
  /** Repair Offer ID */
  id: Scalars['String']['input'];
  /** Rejection reason */
  rejectReason?: InputMaybe<Scalars['String']['input']>;
};

export type RepairOfferAdjustmentActionsInput = {
  /** Adjustment ID */
  adjustmentId: Scalars['String']['input'];
  /** Repair Offer ID */
  id: Scalars['String']['input'];
  /** Approval boolean of the repair offer adjustment */
  isApproved: Scalars['Boolean']['input'];
  /** Rejection reason */
  rejectReason?: InputMaybe<Scalars['String']['input']>;
};

export type RepairOfferAppointmentInput = {
  /** Appointment in time zone agnostic ISO format */
  date: Scalars['String']['input'];
  /** If set to TRUE - indicates the appointment as the chosen one at once */
  isAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RepairOfferDurationInput = {
  /** The minimum number of days the repair is going to take. */
  from?: InputMaybe<Scalars['Float']['input']>;
  /** The maximum number of days the repair is going to take. */
  to?: InputMaybe<Scalars['Float']['input']>;
};

export type RepairOfferInfo = {
  __typename?: 'RepairOfferInfo';
  /** Appointments suggested by a bodyshop */
  appointments?: Maybe<Array<AcceptAppointment>>;
  /** Bodyshop assigned to repair offer */
  bodyshop: Bodyshop;
  /** Comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** The date when the repair offer has been created */
  createdAt: Scalars['Date']['output'];
  /** Repair duration */
  durationInDays?: Maybe<Scalars['Float']['output']>;
  /** ID of the repair offer */
  id: Scalars['ID']['output'];
  /** Repair scope */
  scope?: Maybe<Scalars['String']['output']>;
  /** Status of the repair offer */
  status: RepairOfferStatuses;
  /** The date when the repair offer has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type RepairOfferRequest = {
  __typename?: 'RepairOfferRequest';
  /** ID of the Bodyshop which has been requested to provide a repair offer */
  bodyshopId?: Maybe<Scalars['String']['output']>;
  /** Comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** The date when the repair offer request has been created */
  createdAt: Scalars['Date']['output'];
  /** ID of the Damage report for which the repair offer request is created */
  damageReportId: Scalars['String']['output'];
  /** ID of the repair offer */
  id: Scalars['ID']['output'];
  /** ID of the Repair Offer */
  repairOfferId?: Maybe<Scalars['String']['output']>;
  /** The date when the repair offer request has been updated */
  updatedAt: Scalars['Date']['output'];
};

export enum RepairOfferStatuses {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  EXPERT_APPROVAL_REQUIRED = 'EXPERT_APPROVAL_REQUIRED',
  INSPECTION_APPROVED = 'INSPECTION_APPROVED',
  INSPECTION_REQUIRED = 'INSPECTION_REQUIRED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL'
}

export enum ReportFilterSearchKeysType {
  ASSIGNEE = 'ASSIGNEE',
  BODYSHOP = 'BODYSHOP',
  CAR = 'CAR',
  DAMAGE_REPORT = 'DAMAGE_REPORT',
  FLEET = 'FLEET',
  LOCATION = 'LOCATION',
  ROADWORTHY = 'ROADWORTHY',
  STATUS = 'STATUS',
  STEERER = 'STEERER',
  TAGS = 'TAGS'
}

export type ReporterInformationInput = {
  /** Company affiliation information of reporter */
  companyAffiliation?: InputMaybe<CompanyAffiliationTypeEnum>;
  /** Personal number of user with internal company affiliation */
  personalNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ReporterInformationResult = {
  __typename?: 'ReporterInformationResult';
  /** Company affiliation information of reporter */
  companyAffiliation: CompanyAffiliationResult;
  /** Personal number of user with internal company affiliation */
  personalNumber: PersonalNumberResult;
};

export enum ReportsSortBy {
  BODYSHOPNAME = 'BodyshopName',
  CARLICENSEPLATE = 'CarLicensePlate',
  CARMAKE = 'CarMake',
  CARMODEL = 'CarModel',
  CREATEDAT = 'CreatedAt',
  DAMAGENUMBER = 'DamageNumber',
  FLEETNAME = 'FleetName',
  LOCATION = 'Location',
  REPORTTYPE = 'ReportType',
  REPORTEDBY = 'ReportedBy',
  STATUS = 'Status',
  STEERERNAME = 'SteererName'
}

export type RequestAssessorInput = {
  /** Comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage report ID */
  damageReportId: Scalars['String']['input'];
};

export type RequestEgProcessingInput = {
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Comment */
  requestEgProcessingComment?: InputMaybe<Scalars['String']['input']>;
};

export type RequestEgProcessingResult = {
  __typename?: 'RequestEgProcessingResult';
  /** Result of the request EG processing call */
  success: Scalars['Boolean']['output'];
};

export type RequestEstimationOnSiteInput = {
  /** Estimation appointments, a suggested by the bodyshop */
  appointments: Array<RepairOfferAppointmentInput>;
  /** Bodyshop ID */
  bodyshopId?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Repair offer estimation comment */
  estimationComment?: InputMaybe<Scalars['String']['input']>;
  /** If set to TRUE, automatically confirms the appointment and accepts inspection on-site request */
  isAutomaticallyApproved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestRepairOfferInput = {
  /** Should insuranc/leasing approvals be mandatory to proceed */
  approvals?: InputMaybe<PendingApprovalInput>;
  /** Bodyshop ID */
  bodyshopId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Additional comment regarding the request */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
};

export type RequiredServices = {
  __typename?: 'RequiredServices';
  /** Is free pick up / drop off required */
  freePickUpAndDropOff?: Maybe<Scalars['Boolean']['output']>;
  /** ID of the status service history */
  id: Scalars['ID']['output'];
  /** Partial coverage */
  partialCoverage?: Maybe<CarServiceOptions>;
  /** Car Kasko */
  rentalCarKasko?: Maybe<CarServiceOptions>;
  /** Third party damage */
  thirdPartyDamage?: Maybe<CarServiceOptions>;
};

export type RequiredServicesInput = {
  /** Is free pick up / drop off required */
  freePickUpAndDropOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Partial coverage */
  partialCoverage?: InputMaybe<CarServiceOptions>;
  /** Car Kasko */
  rentalCarKasko?: InputMaybe<CarServiceOptions>;
  /** Third party damage */
  thirdPartyDamage?: InputMaybe<CarServiceOptions>;
};

export type ResendInvitationEmailInput = {
  /** User id to (-re)send an invitation to. */
  userId: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  /** User password */
  password: Scalars['String']['input'];
  /** User password confirm */
  passwordConfirm: Scalars['String']['input'];
  /** Reset password token */
  token: Scalars['String']['input'];
};

export type ResetPasswordResult = {
  __typename?: 'ResetPasswordResult';
  /** User configuration */
  configuration?: Maybe<UserConfiguration>;
  /** User creation date */
  creationDate: Scalars['Date']['output'];
  /** Customer name */
  customer?: Maybe<Scalars['String']['output']>;
  /** Token expiration date */
  expiresAt: Scalars['Date']['output'];
  /** Fleet name */
  fleet?: Maybe<Scalars['String']['output']>;
  /** Fleet ID */
  fleetId?: Maybe<Scalars['ID']['output']>;
  /** A fleet product packages */
  fleetPackages?: Maybe<Array<FleetProductPackages>>;
  /** Fleet segments */
  fleetSegments?: Maybe<Array<FleetSegment>>;
  /** Permitted list of operations */
  operations: Array<Scalars['String']['output']>;
  /** Role user */
  role?: Maybe<UserRole>;
  /** User status */
  status?: Maybe<UserStatus>;
  /** Status of the resetting user password */
  success: Scalars['Boolean']['output'];
  /** Tenant information */
  tenant?: Maybe<TenantInfo>;
  /** Auth token */
  token: Scalars['String']['output'];
};

export enum ResizeType {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  THUMBNAIL = 'Thumbnail'
}

/** List of road map status states */
export enum RoadMapStatusState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UNKNOWN = 'UNKNOWN'
}

export type RotateChecklistReportImageInput = {
  /** Damage report Id */
  id: Scalars['String']['input'];
  imageId: Scalars['String']['input'];
  rotationInDegrees: Scalars['Float']['input'];
};

export type RotateDamageReportImageInput = {
  /** Damage report Id */
  id: Scalars['String']['input'];
  imageId: Scalars['String']['input'];
  resizeTypes: Array<ResizeType>;
  rotationInDegrees: Scalars['Float']['input'];
};

export type RuleConfigurationInput = {
  /** Rule match object */
  match: RuleMatchConfigurationInput;
  /** Rule match message */
  message: Scalars['String']['input'];
  /** Rule match message for AR locale */
  messageAr?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for DE locale */
  messageDe?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for EN locale */
  messageEn?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for ES locale */
  messageEs?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for FR locale */
  messageFr?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for HR locale */
  messageHr?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for IT locale */
  messageIt?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for PL locale */
  messagePl?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for RO locale */
  messageRo?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for RU locale */
  messageRu?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for SQ locale */
  messageSq?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for SR locale */
  messageSr?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for TR locale */
  messageTr?: InputMaybe<Scalars['String']['input']>;
  /** Rule match message for UA locale */
  messageUa?: InputMaybe<Scalars['String']['input']>;
  /** Rule name */
  name: Scalars['String']['input'];
  /** Rule name for AR location */
  nameAr?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for DE locale */
  nameDe?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for EN locale */
  nameEn?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for ES location */
  nameEs?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for FR location */
  nameFr?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for HR location */
  nameHr?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for IT location */
  nameIt?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for PL location */
  namePl?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for RO location */
  nameRo?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for RU location */
  nameRu?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for SQ location */
  nameSq?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for SR location */
  nameSr?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for TR location */
  nameTr?: InputMaybe<Scalars['String']['input']>;
  /** Rule name for UA location */
  nameUa?: InputMaybe<Scalars['String']['input']>;
};

export type RuleConfigurationMatchResult = {
  __typename?: 'RuleConfigurationMatchResult';
  /** Data object match configuration */
  data: Scalars['JSONObject']['output'];
  /** The type of the check */
  name?: Maybe<Scalars['String']['output']>;
};

export type RuleConfigurationResult = {
  __typename?: 'RuleConfigurationResult';
  /** Rule configuration match object */
  match: RuleConfigurationMatchResult;
  /** Rule message */
  message: Scalars['String']['output'];
  /** Rule message for AR location */
  messageAr?: Maybe<Scalars['String']['output']>;
  /** Rule message for DE location */
  messageDe?: Maybe<Scalars['String']['output']>;
  /** Rule message for EN location */
  messageEn?: Maybe<Scalars['String']['output']>;
  /** Rule message for ES location */
  messageEs?: Maybe<Scalars['String']['output']>;
  /** Rule message for FR location */
  messageFr?: Maybe<Scalars['String']['output']>;
  /** Rule message for HR location */
  messageHr?: Maybe<Scalars['String']['output']>;
  /** Rule message for IT location */
  messageIt?: Maybe<Scalars['String']['output']>;
  /** Rule message for PL location */
  messagePl?: Maybe<Scalars['String']['output']>;
  /** Rule message for RO location */
  messageRo?: Maybe<Scalars['String']['output']>;
  /** Rule message for RU location */
  messageRu?: Maybe<Scalars['String']['output']>;
  /** Rule message for SQ location */
  messageSq?: Maybe<Scalars['String']['output']>;
  /** Rule message for SR location */
  messageSr?: Maybe<Scalars['String']['output']>;
  /** Rule message for TR location */
  messageTr?: Maybe<Scalars['String']['output']>;
  /** Rule message for UA location */
  messageUa?: Maybe<Scalars['String']['output']>;
  /** Rule name */
  name: Scalars['String']['output'];
};

export type RuleMatchConfigurationInput = {
  /** Check data match object */
  data?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Check step name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum Supported_Display_Groups_For_Notification_Groups {
  CHECKLISTS = 'CHECKLISTS',
  DAMAGE_MANAGEMENT = 'DAMAGE_MANAGEMENT',
  OTHERS = 'OTHERS'
}

export type ScheduleAppointmentsInput = {
  appointments?: InputMaybe<Array<RepairOfferAppointmentInput>>;
  /** Appointment comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** ID of the repair offer */
  id: Scalars['ID']['input'];
  /** Defines the type to set for al the appointments in the payload */
  type: AppointmentType;
};

export type ScheduleCarServiceInput = {
  /** Service provider ID */
  bodyshopId: Scalars['ID']['input'];
  /** A message to service provider */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Current car mileage */
  mileage: Scalars['String']['input'];
  /** Pick up location */
  pickUpLocation?: InputMaybe<Scalars['String']['input']>;
  /** Schedule date */
  scheduledDate: Scalars['Date']['input'];
  /** Service report ID */
  serviceReportId: Scalars['ID']['input'];
};

export type ScreensOptions = {
  __typename?: 'ScreensOptions';
  /** App name */
  appName?: Maybe<Scalars['String']['output']>;
  /** Screens configuration */
  screensConfiguration?: Maybe<Scalars['JSONObject']['output']>;
};

export type SendDamageReportLinkInput = {
  /** Car ID */
  carId?: InputMaybe<Scalars['String']['input']>;
  /** External driver name */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Driver email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Additional message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Driver phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Tenant name */
  tenant?: InputMaybe<Scalars['String']['input']>;
};

export type SendDamageReportLinkResult = {
  __typename?: 'SendDamageReportLinkResult';
  /** Report ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Report link */
  link: Scalars['String']['output'];
  /** Status of the sending damage report link */
  success: Scalars['Boolean']['output'];
};

export type SendDamageReportVerificationLinkInput = {
  /** Damage report ID */
  damageReportId: Scalars['String']['input'];
  /** Driver email */
  driverEmail: Scalars['String']['input'];
  /** External driver name */
  driverName: Scalars['String']['input'];
  /** Additional message */
  message?: InputMaybe<Scalars['String']['input']>;
};

export type SendDamageReportVerificationLinkResult = {
  __typename?: 'SendDamageReportVerificationLinkResult';
  /** Report ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Status of the sending damage report link */
  success: Scalars['Boolean']['output'];
};

export type SendIndicatedDamageReportLinkInput = {
  /** Damage report ID */
  damageReportId: Scalars['String']['input'];
  /** Additional message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Recipient email */
  recipientEmail: Scalars['String']['input'];
  /** External recipient name */
  recipientName: Scalars['String']['input'];
};

export type SendIndicatedDamageReportLinkResult = {
  __typename?: 'SendIndicatedDamageReportLinkResult';
  /** Report ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Status of the sending damage report link */
  success: Scalars['Boolean']['output'];
};

export type SendOtpInput = {
  email: Scalars['String']['input'];
};

export type SendOtpResult = {
  __typename?: 'SendOtpResult';
  delayForNextSending: Scalars['Float']['output'];
  remainingAttempts: Scalars['Float']['output'];
};

export type SendPreInspectionReportLinkInput = {
  /** A list of additional services */
  additionalServices?: InputMaybe<Array<AdditionalServiceInput>>;
  /** Bodyshop id */
  bodyshopId?: InputMaybe<Scalars['String']['input']>;
  /** Comment for the driver */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Documents, that shared with the steerer and driver */
  documents?: InputMaybe<Array<DamageReportSignedDocumentInput>>;
  /** Driver Name */
  driverName: Scalars['String']['input'];
  /** Insurance company id */
  driverType?: InputMaybe<DriverType>;
  /** Driver email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Insurance company id */
  insuranceCompanyId?: InputMaybe<Scalars['String']['input']>;
  /** License Plate */
  licensePlate: Scalars['String']['input'];
  /** Driver phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** DamageReport Id */
  reportId?: InputMaybe<Scalars['String']['input']>;
  /** Steerer id */
  steererId?: InputMaybe<Scalars['String']['input']>;
};

export type SendReportToDmsInput = {
  damageReportId: Scalars['String']['input'];
};

export type SendResetPasswordLinkInput = {
  /** User email */
  email: Scalars['String']['input'];
};

export type SendResetPasswordLinkResult = {
  __typename?: 'SendResetPasswordLinkResult';
  /** Status of the sending reset password link */
  success: Scalars['Boolean']['output'];
};

export type Service = {
  __typename?: 'Service';
  /** Service Interval */
  interval: Scalars['DateInterval']['output'];
  /** Service Name */
  name: Scalars['String']['output'];
};

export type ServiceAppointment = {
  __typename?: 'ServiceAppointment';
  /** Alternative scheduled date */
  alternativeDate?: Maybe<Scalars['Date']['output']>;
  /** Bodyshop comment */
  comment?: Maybe<Scalars['Date']['output']>;
  /** Service scheduled date */
  suggestedDate?: Maybe<Scalars['Date']['output']>;
};

export type ServiceCarInput = {
  /** The date when the service was performed last time */
  lastServiceDate: Scalars['Date']['input'];
  /** The date when the service is need to be performed next time */
  nextServiceDate: Scalars['Date']['input'];
  /** Car VIN */
  vin: Scalars['String']['input'];
};

export enum ServiceCarType {
  INSPECTION = 'INSPECTION'
}

export type ServiceInvoice = {
  __typename?: 'ServiceInvoice';
  /** Invoice address */
  address: Scalars['String']['output'];
  /** Invoice amount */
  amount: Scalars['Float']['output'];
  /** Bodyshop */
  bodyshop: Bodyshop;
  /** Invoice comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** Invoice created date */
  createdAt: Scalars['Date']['output'];
  /** Invoice download url */
  downloadUrl: Scalars['String']['output'];
  /** ID of the invoice */
  id: Scalars['ID']['output'];
  /** Type of the invoice */
  type: ServiceReportInvoiceType;
  /** Invoice updated date */
  updatedAt: Scalars['Date']['output'];
};

export type ServiceInvoiceUploadInput = {
  /** Invoice address */
  address: Scalars['String']['input'];
  /** Invoice amount */
  amount: Scalars['Float']['input'];
  /** Invoice upload comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Service Report ID */
  serviceReportId: Scalars['String']['input'];
  /** Type of the invoice */
  type: ServiceReportInvoiceType;
};

export type ServiceLightsModuleConfiguration = {
  __typename?: 'ServiceLightsModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type ServiceLightsReportInput = {
  /** Car location */
  carLocation?: InputMaybe<Scalars['String']['input']>;
  /** Client number */
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  /** Address */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** City of the driver */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Email of the driver */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of the driver */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Phone of the driver */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** Postcode of the driver */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the images to publish */
  imagesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Current car mileage */
  mileage: Scalars['Float']['input'];
  /** Reporter email */
  reporterEmail?: InputMaybe<Scalars['String']['input']>;
  /** Reporter name */
  reporterName?: InputMaybe<Scalars['String']['input']>;
  /** Damage categories in scope of service lights */
  serviceLights: Array<DamageCategoryType>;
};

export type ServiceReport = {
  __typename?: 'ServiceReport';
  /** Scheduled appointment */
  appointment?: Maybe<ServiceAppointment>;
  /** Service partner */
  bodyshop?: Maybe<Bodyshop>;
  /** Car information */
  car: PortalCar;
  /** Service car pickup location */
  carPickUpLocation?: Maybe<Scalars['String']['output']>;
  /** Service due date */
  dueDate: Scalars['Date']['output'];
  /** Due status. One of "overdue", "due-soon" */
  dueStatus?: Maybe<ServiceReportDueStatus>;
  /** Service Report Identifier */
  id: Scalars['ID']['output'];
  /** Invoices */
  invoices?: Maybe<Array<ServiceInvoice>>;
  /** Car location */
  location?: Maybe<Scalars['String']['output']>;
  /** Service mileage */
  mileage?: Maybe<Scalars['String']['output']>;
  /** Service scheduled comment */
  scheduleComment?: Maybe<Scalars['String']['output']>;
  /** Service scheduled by */
  scheduledBy?: Maybe<Scalars['String']['output']>;
  /** Service scheduled date */
  scheduledDate?: Maybe<Scalars['Date']['output']>;
  /** Service information */
  service: Service;
  /** Service Report Status */
  status: ServiceReportStatus;
  /** Status History */
  statusHistory?: Maybe<Array<StatusServiceReportHistory>>;
};

export enum ServiceReportDueStatus {
  DUE_SOON = 'DUE_SOON',
  OVERDUE = 'OVERDUE'
}

export enum ServiceReportInvoiceType {
  INSPECTION_INVOICE = 'INSPECTION_INVOICE'
}

export type ServiceReportList = {
  __typename?: 'ServiceReportList';
  reports: Array<ServiceReport>;
  /** Total number of service reports */
  total: Scalars['Float']['output'];
};

export enum ServiceReportStatus {
  APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED',
  APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  NEW = 'NEW',
  STARTED = 'STARTED'
}

export type ServiceReportsCountResult = {
  __typename?: 'ServiceReportsCountResult';
  /** The number of service reports */
  all: Scalars['Int']['output'];
  /** The sum of overdue and due soon */
  count: Scalars['Int']['output'];
  /** The number of dou soon */
  dueSoon: Scalars['Int']['output'];
  /** The number of overdue */
  overDue: Scalars['Int']['output'];
};

export enum ServiceReportsSortBy {
  CARLICENSEPLATE = 'CarLicensePlate',
  CARMAKE = 'CarMake',
  CARMODEL = 'CarModel',
  DUEDATE = 'DueDate',
  FLEETNAME = 'FleetName',
  LOCATION = 'Location',
  SCHEDULEDDATE = 'ScheduledDate',
  STATUS = 'Status'
}

export type SetAnylineTokenResultType = {
  __typename?: 'SetAnylineTokenResultType';
  /** Message */
  message: Scalars['String']['output'];
  /** Success */
  success: Scalars['Boolean']['output'];
};

export type SetBodyshopDefaultManagerInput = {
  /** List of managers data */
  data?: InputMaybe<Array<SetBodyshopDefaultManagerItem>>;
};

export type SetBodyshopDefaultManagerItem = {
  /** Bodyshop name */
  bodyshopName: Scalars['String']['input'];
  /** User email */
  email: Scalars['String']['input'];
};

export type SetBodyshopPartnerIdInput = {
  /** Bodyshop "IsFleetProcessing" configuration */
  fleetProcessing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Bodyshop ID */
  id: Scalars['String']['input'];
  /** Damage report ID */
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type SetDamageReportSettledInput = {
  /** A damage report id */
  damageReportId: Scalars['ID']['input'];
  /** A settled reason comment */
  settledReason?: InputMaybe<Scalars['String']['input']>;
};

export type SetFleetDefaultManagerInput = {
  /** User email */
  email: Scalars['String']['input'];
  /** Fleet id */
  fleetId: Scalars['String']['input'];
};

export type SetFleetIsEgReadyInput = {
  /** Fleet name */
  fleetName: Scalars['String']['input'];
};

export type ShareDamageReportLinkInput = {
  /** Share damage report link comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['ID']['input'];
  /** Recipient emails */
  recipientEmails: Array<Scalars['String']['input']>;
};

export type SignUpResult = {
  __typename?: 'SignUpResult';
  /** Status of the reporting */
  success: Scalars['Boolean']['output'];
};

export type SignatureCoordinates = {
  __typename?: 'SignatureCoordinates';
  height: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type SignatureCoordinatesInput = {
  height: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

/** Define signed document type: */
export enum SignedDocumentType {
  ORDER_INFORMATION = 'ORDER_INFORMATION',
  OTHER = 'OTHER',
  REPAIR_COST_COVERAGE = 'REPAIR_COST_COVERAGE',
  SERVICE_AGREEMENT = 'SERVICE_AGREEMENT'
}

export type SmsTemplate = {
  __typename?: 'SmsTemplate';
  contents: Array<SmsTemplateContent>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SmsTemplateContent = {
  __typename?: 'SmsTemplateContent';
  lang: Scalars['String']['output'];
  senderName: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type SmsTemplateContentInput = {
  lang: Scalars['String']['input'];
  senderName: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type StartCarServiceInput = {
  /** Service report ID */
  serviceReportId: Scalars['ID']['input'];
};

export type StartChecklistReportResult = {
  __typename?: 'StartChecklistReportResult';
  /** ID of started checklist report */
  checklistReportId: Scalars['String']['output'];
};

export type StatisticsResult = {
  __typename?: 'StatisticsResult';
  /** Url to file */
  url: Scalars['String']['output'];
};

export type StatusRoadMapItem = {
  __typename?: 'StatusRoadMapItem';
  currentState: RoadMapStatusState;
  /** The date when the history has been created */
  executedAt?: Maybe<Scalars['Date']['output']>;
  label: Scalars['String']['output'];
  /** Status of the damage report */
  status: DamageReportRoadMapStatus;
};

export type StatusServiceReportHistory = {
  __typename?: 'StatusServiceReportHistory';
  /** The date when the history has been created */
  createdAt: Scalars['Date']['output'];
  /** ID of the status service history */
  id: Scalars['ID']['output'];
  /** Status of the service report */
  status: ServiceReportStatus;
  /** The date when the history has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type Steerer = {
  __typename?: 'Steerer';
  /** ID of the steerer */
  id: Scalars['ID']['output'];
  /** Name of the steerer */
  name: Scalars['String']['output'];
  /** Title of the steerer */
  title: Scalars['String']['output'];
};

export type SteererUser = {
  /** User email */
  email: Scalars['String']['input'];
  /** User first name */
  firstName: Scalars['String']['input'];
  /** Is notifications enabled */
  isNotificationsEnabled?: Scalars['Boolean']['input'];
  /** User last name */
  lastName: Scalars['String']['input'];
  /** User password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** User phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** User role */
  role: Scalars['String']['input'];
  /** Trigger invitation email */
  triggerInvitationEmail?: Scalars['Boolean']['input'];
  /** User visibility scope */
  visibilityScope?: InputMaybe<Array<UserVisibilityScope>>;
};

export type SuggestedAssessorOrganisationItem = {
  /** If suggest/remove of AO should be applied to all child fleets */
  applyToChildFleets?: Scalars['Boolean']['input'];
  /** Assessor organisation name */
  assessorOrganisationTitle: Scalars['String']['input'];
};

export type SuggestedBodyshop = {
  __typename?: 'SuggestedBodyshop';
  /** Bodyshop address city */
  addressCity: Scalars['String']['output'];
  /** Bodyshop address house */
  addressHouse: Scalars['String']['output'];
  /** Bodyshop address street */
  addressStreet: Scalars['String']['output'];
  /** Bodyshop address zip */
  addressZip: Scalars['String']['output'];
  /** Bodyshop contact person */
  contactPerson: Scalars['String']['output'];
  /** The date when the bodyshop has been created */
  createdAt: Scalars['Date']['output'];
  /** Bodyshop email */
  email: Scalars['String']['output'];
  /** ID of the bodyshop */
  id: Scalars['ID']['output'];
  /** If the bodyshop is default for damage report location */
  isDefault: Scalars['Boolean']['output'];
  /** Is bodyshpo active */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** If the bodyshop is suggested for damage report location */
  isSuggested: Scalars['Boolean']['output'];
  /** Bodyshop name */
  name: Scalars['String']['output'];
  /** Bodyshop partner ID */
  partnerId?: Maybe<Scalars['String']['output']>;
  /** Bodyshop phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Bodyshop title */
  title: Scalars['String']['output'];
  /** The date when the bodyshop has been updated */
  updatedAt: Scalars['Date']['output'];
};

export type SuggestedBodyshopItem = {
  /** Bodyshop name */
  bodyshopName: Scalars['String']['input'];
  /** If the bodyshop is default for location */
  isDefault: Scalars['Boolean']['input'];
  /** Location name (city) */
  location: Scalars['String']['input'];
  /** Service type */
  serviceType?: BodyShopServiceType;
};

export enum SupportedEntityType {
  CHECKLIST_REPORT = 'CHECKLIST_REPORT'
}

export type SyncDamageReportToCarismaInput = {
  /** damage report ID */
  id: Scalars['String']['input'];
};

export type TelematicReportInput = {
  /** Images for the damage report */
  images: Array<ImageUpload>;
  /** Impact angle */
  impactAngle: Scalars['Float']['input'];
  /** Location */
  location: Scalars['String']['input'];
  /** Damage severity */
  severity: Scalars['String']['input'];
  /** Speed */
  speed: Scalars['Float']['input'];
};

export type Tenant = {
  __typename?: 'Tenant';
  /** ID of the fleet */
  id: Scalars['ID']['output'];
  /** Tenant short name */
  name: Scalars['String']['output'];
  /** Tenant title */
  title: Scalars['String']['output'];
};

export type TenantInfo = {
  __typename?: 'TenantInfo';
  /** ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
};

export enum TenantType {
  ASSESSOR_ORGANISATION = 'ASSESSOR_ORGANISATION',
  BODYSHOP = 'BODYSHOP',
  EUROGARANT = 'EUROGARANT',
  FLEET = 'FLEET',
  OPERATOR = 'OPERATOR',
  STEERER = 'STEERER'
}

export type Theme = {
  __typename?: 'Theme';
  /** Theme logo */
  logo?: Maybe<ThemeLogo>;
  /** Theme properties */
  properties?: Maybe<ThemeProperties>;
};

export enum ThemeAssetScale {
  DESKTOP_X1 = 'DESKTOP_X1',
  DESKTOP_X2 = 'DESKTOP_X2',
  MOBILE_X1 = 'MOBILE_X1',
  MOBILE_X2 = 'MOBILE_X2'
}

export enum ThemeAssetType {
  DRIVERAPPLOGO = 'DriverAppLogo',
  EMAILLOGO = 'EmailLogo',
  FAVICON = 'Favicon'
}

export type ThemeAssetUpload = {
  /** File */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** Asset Scale */
  scale: ThemeAssetScale;
  /** Asset Type */
  type: ThemeAssetType;
  /** Url */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ThemeLogo = {
  __typename?: 'ThemeLogo';
  /** The logo 1x */
  general1x?: Maybe<Scalars['String']['output']>;
  /** The logo 2x */
  general2x?: Maybe<Scalars['String']['output']>;
  /** The logo header 1x */
  header1x?: Maybe<Scalars['String']['output']>;
  /** The logo header 2x */
  header2x?: Maybe<Scalars['String']['output']>;
};

export type ThemeProperties = {
  __typename?: 'ThemeProperties';
  /** The main color */
  primary_color?: Maybe<Scalars['String']['output']>;
};

export type ThemeResult = {
  __typename?: 'ThemeResult';
  /** favicon */
  favicon: Scalars['JSONObject']['output'];
  /** logo */
  logo: Scalars['JSONObject']['output'];
  /** properties */
  properties: Scalars['JSONObject']['output'];
};

export type ThirdPartyModuleConfiguration = {
  __typename?: 'ThirdPartyModuleConfiguration';
  /** Defines automatic fleet approval limit for repair offers */
  approvalLimit?: Maybe<Scalars['Float']['output']>;
  /** Defines if automate repair offer request being used */
  isAutoRequestRepairOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
};

export type TotalDamagesByPeriod = {
  __typename?: 'TotalDamagesByPeriod';
  /** Damage count for the current period */
  currentPeriod: Scalars['Float']['output'];
  /** Damage count for the previous period */
  previousPeriod: Scalars['Float']['output'];
};

export type TotalDamagesByType = {
  __typename?: 'TotalDamagesByType';
  /** Dents count */
  dent: TotalDamagesByPeriod;
  /** Scratches count */
  scratch: TotalDamagesByPeriod;
};

export type TrackingInfo = {
  __typename?: 'TrackingInfo';
  /** Fleet ID */
  fleetId?: Maybe<Scalars['ID']['output']>;
  /** Fleet name */
  fleetName?: Maybe<Scalars['String']['output']>;
  /** Fleet packages */
  packages?: Maybe<Array<FleetProductPackages>>;
  /** Fleet segments */
  segments?: Maybe<Array<FleetSegment>>;
  /** Fleet status */
  status?: Maybe<FleetStatus>;
};

export type UpdateAccidentPoliceReportInput = {
  /** Indicates if the accident has been registered by the police at all */
  isRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of a police officer */
  policeOfficerName?: InputMaybe<Scalars['String']['input']>;
  /** Indicates why the accident was not registered by the police */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** Number of a police report */
  reportNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccidentQuestionsInput = {
  /** Did driver consume alcoholic beverages or intoxicating substances in the last 24 hours before accident? */
  alcoholBeveragesConsumes?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alcohol concetration in case an alcohol test was taken */
  alcoholConcentration?: InputMaybe<Scalars['Float']['input']>;
  /** Was a blood sample taken? */
  bloodSampleTaken?: InputMaybe<Scalars['Boolean']['input']>;
  /** Did driver leave the scene of an accident without permission? */
  leftAccidentScene?: InputMaybe<Scalars['Boolean']['input']>;
  /** Transfer service provider in case if car was transfered to third party */
  transferServiceProvider?: InputMaybe<Scalars['String']['input']>;
  /** A trip type */
  tripPurpose?: InputMaybe<AccidentQuestionsTripTypeEnum>;
  /** Does driver have a valid license? */
  validDriverLicense?: InputMaybe<Scalars['Boolean']['input']>;
  /** Was an alcohold or drug test performed? */
  wasAlcoholDrugTestPerformed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Was car transferred to third party? */
  wasCarTransferredToThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateAccidentReportQuestionsInput = {
  /** Did driver consume alcoholic beverages or intoxicating substances in the last 24 hours before accident? */
  alcoholBeveragesConsumes?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alcohol concetration in case an alcohol test was taken */
  alcoholConcentration?: InputMaybe<Scalars['Float']['input']>;
  /** Did driver leave the scene of an accident without permission? */
  leftAccidentScene?: InputMaybe<Scalars['Boolean']['input']>;
  /** Transfer service provider in case if car was transfered to third party */
  transferServiceProvider?: InputMaybe<Scalars['String']['input']>;
  /** Business or personal trip? */
  tripPurpose?: InputMaybe<AccidentQuestionsTripTypeEnum>;
  /** Does driver have a valid license? */
  validDriverLicense?: InputMaybe<Scalars['Boolean']['input']>;
  /** Was an alcohold or drug test performed? */
  wasAlcoholDrugTestPerformed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Was car transferred to third party? */
  wasCarTransferredToThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateAccidentThirdPartyInput = {
  /** Accident third party comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Accident third party insurance number */
  insurance?: InputMaybe<Scalars['String']['input']>;
  /** Accident third party insurance license plate */
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  /** Accident third party name */
  personName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCarActivationInput = {
  /** List of car IDs to be updated */
  ids: Array<Scalars['ID']['input']>;
  /** Car activation status */
  isActive: Scalars['Boolean']['input'];
};

export type UpdateCarInput = {
  /** Car availability status */
  availability?: InputMaybe<CarAvailabilityStatus>;
  /** Car bodystyle */
  bodyStyle?: InputMaybe<BodyStyle>;
  /** Whether to create a new record if the car is not found */
  create?: InputMaybe<Scalars['Boolean']['input']>;
  /** Car first registration date */
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  /** Car group name */
  groupName?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Next inspection date */
  inspectionInterval?: InputMaybe<Scalars['String']['input']>;
  /** Car insurance */
  insurance?: InputMaybe<CarInsuranceInput>;
  /** Sets car active */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is car shareable */
  isPool?: InputMaybe<Scalars['Boolean']['input']>;
  /** Last inspection date */
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** Car leasing */
  leasing?: InputMaybe<CarLeasingInput>;
  /** Main driver/leagaly responsible user's email. Required for non-pool cars */
  liableUser?: InputMaybe<Scalars['String']['input']>;
  /** Car license plate */
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  /** Car location (city) */
  location?: InputMaybe<Scalars['String']['input']>;
  /** Car make */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Car mileage */
  mileage?: InputMaybe<Scalars['Float']['input']>;
  /** Mileage reported date */
  mileageReportedDate?: InputMaybe<Scalars['Date']['input']>;
  /** Car model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Next inspection date */
  nextInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** Car partner id */
  partnerId?: InputMaybe<Scalars['String']['input']>;
  /** Car settings */
  settings?: InputMaybe<CarSettingsInput>;
  /** Car VIN */
  vin: Scalars['String']['input'];
  /** Warranty expiration date */
  warrantyExpirationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateCarsAvailabilityInput = {
  /** Car availability status */
  availability: CarAvailabilityStatus;
  /** List of car IDs to be updated */
  ids: Array<Scalars['ID']['input']>;
};

export type UpdateCarsInput = {
  /** List of cars to be updated */
  cars: Array<UpdateCarInput>;
  /** Fleet name */
  fleetName: Scalars['String']['input'];
};

export type UpdateChecklistFleetCommentInput = {
  /** Checklist report id */
  checklistReportId: Scalars['String']['input'];
  /** Fleet comment */
  fleetComment: Scalars['String']['input'];
};

export type UpdateChecklistReportInput = {
  /** Checks withing checklist */
  checks?: InputMaybe<Array<ChecklistReportCheckInput>>;
  /** Whether to complete the checklist */
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Terms and conditions agreement */
  isTermsAndConditionAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateChecklistReportResult = {
  __typename?: 'UpdateChecklistReportResult';
  /** Checklist information */
  checklist: ChecklistResult;
};

export type UpdateCommentInput = {
  /** Comment value */
  comment: Scalars['String']['input'];
  /** Damage Report ID */
  damageReportId: Scalars['String']['input'];
  /** Comment ID */
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompletedDamageReportInformationInput = {
  /** Uploaded damage images IDs */
  attachedDamageImages?: InputMaybe<Array<AttachedDamageImage>>;
  /** Damaged car parts */
  carPartsTypes?: InputMaybe<Array<CarPartType>>;
  /** Damage categories types */
  categoryTypes?: InputMaybe<Array<DamageCategoryType>>;
  /** Fleet manager comment */
  fleetComment?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if damage is minor */
  isMinor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Damage report status */
  status?: InputMaybe<DamageReportStatusAllowedForUpdate>;
};

export type UpdateCostInput = {
  /** Amount net */
  amountNet?: InputMaybe<Scalars['Float']['input']>;
  /** Cost comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Cost ID */
  id: Scalars['String']['input'];
  /** Is VAT included */
  isVatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Cost type */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDamageImageInput = {
  /** Damage report id */
  damageReportId: Scalars['String']['input'];
  /** Image file */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** Id of the damage report image that should be removed/replaced */
  removeDamageReportImageId?: InputMaybe<Scalars['String']['input']>;
  /** Type of the new damage report image */
  type?: InputMaybe<DamageReportImageType>;
};

export type UpdateDamageImageResult = {
  __typename?: 'UpdateDamageImageResult';
  /** Full url of the new created image */
  fullUrl?: Maybe<Scalars['String']['output']>;
  /** ID of the new created image */
  id?: Maybe<Scalars['String']['output']>;
  /** Status of operation */
  success: Scalars['Boolean']['output'];
};

export type UpdateDamageInformationInput = {
  accidentResponsibility?: InputMaybe<AccidentResponsibilityCauser>;
  /** Damage address */
  damageAddress?: InputMaybe<Scalars['String']['input']>;
  /** Damage category type */
  damageCategories?: InputMaybe<Array<DamageCategoryType>>;
  /** An accident date and time */
  damageDate?: InputMaybe<Scalars['Date']['input']>;
  /** Damage Description */
  damageDescription?: InputMaybe<Scalars['String']['input']>;
  /** Damage reason comment */
  damageReasonComment?: InputMaybe<Scalars['String']['input']>;
  /** Damage reason note */
  damageReasonNote?: InputMaybe<Scalars['String']['input']>;
  /** Damage reasons */
  damageReasons?: InputMaybe<Array<AllDamageReasons>>;
  /** Description of the fixed damaged object */
  damagedObjectDescription?: InputMaybe<Scalars['String']['input']>;
  /** driver's desired address */
  desiredRepairAddress?: InputMaybe<Scalars['String']['input']>;
  /** Insurance coverage */
  insuranceCoverage?: InputMaybe<DamageReportInsuranceCoverageType>;
  /** comment from steerer */
  steererComment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDamageInsuranceInput = {
  /** Insurance number */
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Results of the insurance broker */
  resultsOfBroker?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDamageReport = {
  /** Car parts */
  carPartsTypes?: InputMaybe<Array<CarPartType>>;
  /** Damage categories types */
  categoryTypes?: InputMaybe<Array<DamageCategoryType>>;
  /** Fleet comment */
  fleetComment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDamageReportImagesInput = {
  /** Damage report Id */
  id: Scalars['String']['input'];
  images: Array<ImagesUpdate>;
};

export type UpdateDamageReportInformationInput = {
  /** Car parts with indicated damages */
  carPartsTypes?: InputMaybe<Array<CarPartType>>;
  /** Damage categories types */
  categoryTypes?: InputMaybe<Array<DamageCategoryType>>;
  /** Damage Description */
  damageDescription?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if damage minor */
  isMinor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDamageReportInput = {
  /** Damage report update */
  damageReport: UpdateDamageReport;
  /** Damage report id */
  damageReportId: Scalars['String']['input'];
};

export type UpdateDamageReportPortalInput = {
  /** answers about driver's condition at the time of the accident */
  accidentQuestionsAnswers?: InputMaybe<UpdateAccidentReportQuestionsInput>;
  /** Accident third party data */
  accidentThirdParty?: InputMaybe<UpdateAccidentThirdPartyInput>;
  /** Selected damage car part types */
  carParts?: InputMaybe<Array<CarPartType>>;
  /** Client number */
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  /** Damage information fields */
  damageInformation?: InputMaybe<UpdateDamageInformationInput>;
  /** Damage insurance fields */
  damageInsurance?: InputMaybe<UpdateDamageInsuranceInput>;
  /** Damage report id */
  damageReportId: Scalars['String']['input'];
  /** Driver address */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** Driver city */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Driver's email */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Drivers's name */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Driver's phone */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** Driver ZIP */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** ExternalId value */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Fleet comment */
  fleetComment?: InputMaybe<Scalars['String']['input']>;
  /** Fleet repair price */
  fleetRepairPrice?: InputMaybe<Scalars['String']['input']>;
  /** Impact information fields */
  impactInfo?: InputMaybe<UpdateImpactInfoInput>;
  /** Injured person information */
  injuredPersonInformation?: InputMaybe<UpdateInjuredPersonInformationInput>;
  /** Tax Deduction */
  inputTaxDeduction?: InputMaybe<Scalars['Boolean']['input']>;
  isDrivable?: InputMaybe<DamageReportDrivableType>;
  /** Indicates if damage minor */
  isMinor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Police report data */
  policeReport?: InputMaybe<UpdateAccidentPoliceReportInput>;
  /** Reporter's email */
  reporterEmail?: InputMaybe<Scalars['String']['input']>;
  /** Additional reporter info fields */
  reporterInformation?: InputMaybe<UpdateReporterInformationInput>;
  /** Reporter's name */
  reporterName?: InputMaybe<Scalars['String']['input']>;
  /** Reporter's phone */
  reporterPhone?: InputMaybe<Scalars['String']['input']>;
  /** Wildlife protection accident data */
  wildlifeProtection?: InputMaybe<DamageReportWildlifeDataInput>;
};

export type UpdateDriverCameraAccessConsentInput = {
  /** Driver camera access consent value */
  isCameraAccessConsent: Scalars['Boolean']['input'];
};

export type UpdateDriverCameraAccessConsentResult = {
  __typename?: 'UpdateDriverCameraAccessConsentResult';
  success: Scalars['Boolean']['output'];
};

export type UpdateEmailTemplateInput = {
  /** Email template content */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** Email template content body */
  contentBody?: InputMaybe<Scalars['String']['input']>;
  /** Email template content title */
  contentTitle?: InputMaybe<Scalars['String']['input']>;
  /** Email template description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email template headline icon */
  headlineIcon?: InputMaybe<GenericTemplateHeadlineIcon>;
  /** Email template headline label */
  headlineLabelText?: InputMaybe<Scalars['String']['input']>;
  /** Email template headline label title */
  headlineTitle?: InputMaybe<Scalars['String']['input']>;
  /** Email template language */
  lang: Language;
  /** Email template name */
  name: EmailTemplateName;
  /** Email template sign off element */
  signoff?: InputMaybe<Scalars['String']['input']>;
  /** Email template subject */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Email template tenant name */
  tenant?: InputMaybe<Scalars['String']['input']>;
  /** Email template title */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFleetConfigurationInput = {
  /** Update for fleet configuration */
  configuration: Scalars['JSONObject']['input'];
  /** Fleet name */
  fleetName: Scalars['String']['input'];
};

export type UpdateImpactInfoInput = {
  /** Impact angle */
  impactAngle?: InputMaybe<Scalars['Float']['input']>;
  /** Impact severity */
  severity?: InputMaybe<Scalars['String']['input']>;
  /** Impact speed */
  speed?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInjuredPersonInformationInput = {
  /** Comment of an injured person accident */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Email of an injured person */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Full name of an injured person */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Insurance company name */
  insuranceCompany?: InputMaybe<Scalars['String']['input']>;
  /** Phone of an injured person */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePersonalNotificationSettingsInput = {
  /** Are all notifications muted (Disabled) */
  areAllNotificationsMuted: Scalars['Boolean']['input'];
  /** Group name */
  groupsSettings?: InputMaybe<PersonalNotificationGroupInput>;
  /** User ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdatePersonalNotificationSettingsResult = {
  __typename?: 'UpdatePersonalNotificationSettingsResult';
  /** Status of the personal notifications update operation */
  success: Scalars['Boolean']['output'];
};

export type UpdateRegistrationData = {
  /** Car's registration company */
  carRegistrationCompany?: InputMaybe<Scalars['String']['input']>;
  /** Address */
  driverAddress?: InputMaybe<Scalars['String']['input']>;
  /** City */
  driverCity?: InputMaybe<Scalars['String']['input']>;
  /** Driver email */
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  /** Driver name */
  driverName?: InputMaybe<Scalars['String']['input']>;
  /** Driver phone */
  driverPhone?: InputMaybe<Scalars['String']['input']>;
  /** ZIP code */
  driverZip?: InputMaybe<Scalars['String']['input']>;
  /** Car's registration date */
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  /** Inspection date */
  inspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** Car's license plate */
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  /** Car VIN code */
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReporterInformationInput = {
  /** Company affiliation information of reporter */
  companyAffiliation?: InputMaybe<Scalars['String']['input']>;
  /** Personal number of user with internal company affiliation */
  personalNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSteererConfigurationInput = {
  /** Update for steerer configuration */
  configuration: Scalars['JSONObject']['input'];
  /** Steerer name */
  steererName: Scalars['String']['input'];
};

export type UpdateSuggestedAssessorOrganisationInput = {
  /** Fleet name */
  fleetName: Scalars['String']['input'];
  /** List of suggested assessor organisations to remove */
  remove?: InputMaybe<Array<SuggestedAssessorOrganisationItem>>;
  /** List of suggested assessor organisations */
  suggest?: InputMaybe<Array<SuggestedAssessorOrganisationItem>>;
};

export type UpdateSuggestedBodyshopInput = {
  /** Fleet name */
  fleetName: Scalars['String']['input'];
  /** List of suggested bodyshops to remove */
  remove?: InputMaybe<Array<BodyshopItem>>;
  /** List of suggested bodyshops */
  suggest?: InputMaybe<Array<SuggestedBodyshopItem>>;
};

export type UpdateUserDetailsInput = {
  /** User email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User organizations */
  organizations?: InputMaybe<Array<CreateUserOrganizations>>;
  /** User role */
  role?: InputMaybe<AllowedCreateUserRoleType>;
  /** ID of the user to update the details for */
  userId: Scalars['String']['input'];
};

export type UpdateUserEnabledStatusInput = {
  /** User ID */
  id: Scalars['ID']['input'];
  /** Boolean value is user enabled */
  isEnabled: Scalars['Boolean']['input'];
};

export type UpdateUserPersonalInfoInput = {
  /** User email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadAssessmentReportInput = {
  /** Comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Damage report id */
  id: Scalars['String']['input'];
  /** Repair cost estimation */
  repairCostEstimation: Scalars['Float']['input'];
};

export type UploadChecklistReportImageResult = {
  __typename?: 'UploadChecklistReportImageResult';
  /** ID of the image */
  id: Scalars['String']['output'];
  /** Checklist image type */
  type: Scalars['String']['output'];
  /** Full url for the image. */
  url: Scalars['String']['output'];
};

export type UploadDamageReportDocumentInput = {
  /** A comment to the uploaded documents */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** A damage report ID */
  id: Scalars['ID']['input'];
};

export type UploadDamageReportImagesInput = {
  /** Damage report Id */
  id: Scalars['String']['input'];
  images: Array<DamageReportImagesUpload>;
};

export type UploadImageResult = {
  __typename?: 'UploadImageResult';
  /** The date an image has been created */
  createdAt: Scalars['Date']['output'];
  /** Full url for the image. */
  fullUrl: Scalars['String']['output'];
  /** ID of the image */
  id: Scalars['String']['output'];
  /** Shows whether it is possible to delete a photo */
  isDeletable: Scalars['Boolean']['output'];
};

export type UserAvailableOrganizationsObject = {
  __typename?: 'UserAvailableOrganizationsObject';
  /** Organization group name */
  group?: Maybe<Scalars['String']['output']>;
  /** Organization location */
  location?: Maybe<Scalars['String']['output']>;
  /** Organization ID: fleet id in case of a fleet and tenant ID otherwise. */
  locationOwningOrganisationId: Scalars['ID']['output'];
  /** Organization name */
  name: Scalars['String']['output'];
};

export type UserAvailableRolesAndOrganizationsOptionsResultObject = {
  __typename?: 'UserAvailableRolesAndOrganizationsOptionsResultObject';
  /** The list of the organizations, which are available for creation for this user. */
  organizations: Array<UserAvailableOrganizationsObject>;
  /** The list of the roles, which are available for creation for this user. */
  roles: Array<UserRole>;
};


export type UserAvailableRolesAndOrganizationsOptionsResultObjectOrganizationsArgs = {
  filters?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserConfiguration = {
  __typename?: 'UserConfiguration';
  /** Whether the user is a bodyshop user */
  isBodyshop: Scalars['Boolean']['output'];
  /** Whether the user's bodyshop has SEND_DOCUMENTS module enabled */
  isBodyshopHasSendDocuments: Scalars['Boolean']['output'];
  /** Whether the user's bodyshop has steerers */
  isBodyshopsHasSteerers: Scalars['Boolean']['output'];
  /** Whether the user has access to many fleets' damage reports */
  isMultiFleet: Scalars['Boolean']['output'];
  /** Whether the user has access to many locations */
  isMultiLocation: Scalars['Boolean']['output'];
  /** Whether the user is an operator */
  isOperator: Scalars['Boolean']['output'];
};

export type UserDetailsOrganizationObject = {
  __typename?: 'UserDetailsOrganizationObject';
  /** Organization group */
  group?: Maybe<Scalars['String']['output']>;
  /** Organization location */
  location?: Maybe<Scalars['String']['output']>;
  /** Fleet ID in case of fleet. Else - tenant ID */
  locationOwningOrganisationId: Scalars['String']['output'];
  /** Organization name */
  name: Scalars['String']['output'];
};

export type UserDetailsType = {
  __typename?: 'UserDetailsType';
  /** User created at */
  createdAt: Scalars['Date']['output'];
  /** User email address */
  email: Scalars['String']['output'];
  /** User first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Fleet */
  fleet?: Maybe<FleetWithConfiguration>;
  /** User full name */
  fullName?: Maybe<Scalars['String']['output']>;
  /** User id */
  id: Scalars['ID']['output'];
  /** Indicates whether use details can be updated. */
  isEditable: Scalars['Boolean']['output'];
  /** If TRUE - a user an access the platform. If FALSE - can't */
  isEnabled: Scalars['Boolean']['output'];
  /** User last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Location */
  location?: Maybe<Array<Scalars['String']['output']>>;
  /** Set of notification settings */
  notificationSettings?: Maybe<PersonalNotificationSettingsResult>;
  /** User organizations list */
  organizations: Array<UserDetailsOrganizationObject>;
  /** User phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** User role */
  role: UserRole;
  /** User status */
  status: UserStatus;
  /** User tenant information */
  tenant: UserTenantObject;
};

export type UserEditResult = {
  __typename?: 'UserEditResult';
  /** Status of the user etails update operation */
  success: Scalars['Boolean']['output'];
};

export type UserEditingOptionsResultObject = {
  __typename?: 'UserEditingOptionsResultObject';
  /** The list of the organizations in scope of user tenant with indication if they are accessible by the user in question. */
  organizations: Array<UserOrganizationWithAccessibilityObject>;
  /** The list of the roles, which are available for creation for this user. */
  roles: Array<UserRole>;
};


export type UserEditingOptionsResultObjectOrganizationsArgs = {
  filters?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserInfoResult = {
  __typename?: 'UserInfoResult';
  /** User configuration */
  configuration?: Maybe<UserConfiguration>;
  /** The date when the user has been created */
  createdAt: Scalars['Date']['output'];
  /** Customer name */
  customer?: Maybe<Scalars['String']['output']>;
  /** User email */
  email: Scalars['String']['output'];
  /** User first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Fleet name */
  fleet?: Maybe<Scalars['String']['output']>;
  /** Fleet ID */
  fleetId?: Maybe<Scalars['ID']['output']>;
  /** Fleet segments */
  fleetSegments?: Maybe<Array<FleetSegment>>;
  /** User full name */
  fullName?: Maybe<Scalars['String']['output']>;
  /** User id */
  id: Scalars['ID']['output'];
  /** Demo mode */
  isDemoMode?: Maybe<Scalars['Boolean']['output']>;
  /** If TRUE - a user an access the platform. If FALSE - can't */
  isEnabled: Scalars['Boolean']['output'];
  /** User last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Location */
  location?: Maybe<Array<Scalars['String']['output']>>;
  /** Set of notification settings */
  notificationSettings?: Maybe<PersonalNotificationSettingsResult>;
  /** Permitted list of operations */
  operations: Array<Scalars['String']['output']>;
  /** User phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** User role */
  role: UserRole;
  /** Tenant information */
  tenant: TenantInfo;
};

export enum UserListFilterSupportedKeys {
  LOCATION = 'LOCATION',
  ORGANIZATION = 'ORGANIZATION',
  ROLE = 'ROLE',
  STATUS = 'STATUS'
}

export type UserListResult = {
  __typename?: 'UserListResult';
  /** Total users number */
  total: Scalars['Float']['output'];
  users: Array<UserTypeObject>;
};

export enum UserListSortBy {
  CREATED_AT = 'CREATED_AT',
  EMAIL = 'EMAIL',
  NAME = 'NAME'
}

export type UserManagementModuleConfiguration = {
  __typename?: 'UserManagementModuleConfiguration';
  /** Whether the module is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Defines if can create new users */
  isUserCreationAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if any user entity updates are available */
  isUserEditAvailable?: Maybe<Scalars['Boolean']['output']>;
};

export type UserOrganizationObject = {
  __typename?: 'UserOrganizationObject';
  /** Organization group */
  group?: Maybe<Scalars['String']['output']>;
  /** Organization name */
  name: Scalars['String']['output'];
};

export type UserOrganizationWithAccessibilityObject = {
  __typename?: 'UserOrganizationWithAccessibilityObject';
  /** Organization group name */
  group?: Maybe<Scalars['String']['output']>;
  /** If TRUE - the user has access to the organisation/location. */
  isAccessible?: Maybe<Scalars['Boolean']['output']>;
  /** Organization location */
  location?: Maybe<Scalars['String']['output']>;
  /** Organization ID: fleet id in case of a fleet and tenant ID otherwise. */
  locationOwningOrganisationId: Scalars['ID']['output'];
  /** Organization name */
  name: Scalars['String']['output'];
};

export enum UserRole {
  ASSESSOR = 'ASSESSOR',
  BODYSHOP_ACCEPTANCE_MASTER = 'BODYSHOP_ACCEPTANCE_MASTER',
  BODYSHOP_CUSTOMER_CARE = 'BODYSHOP_CUSTOMER_CARE',
  BODYSHOP_GROUP_MANAGER = 'BODYSHOP_GROUP_MANAGER',
  BODYSHOP_MANAGER = 'BODYSHOP_MANAGER',
  CAR_DEALER = 'CAR_DEALER',
  DAMAGE_STEERER = 'DAMAGE_STEERER',
  DRIVER = 'DRIVER',
  EXTERNAL_OPERATIONS = 'EXTERNAL_OPERATIONS',
  FLEET_MANAGER = 'FLEET_MANAGER',
  GUEST = 'GUEST',
  LOCAL_FLEET_MANAGER = 'LOCAL_FLEET_MANAGER',
  REGIONAL_FLEET_MANAGER = 'REGIONAL_FLEET_MANAGER',
  RF_ACCESSOR = 'RF_ACCESSOR',
  RF_OPERATIONS = 'RF_OPERATIONS',
  TECHNICAL_EXPERT = 'TECHNICAL_EXPERT'
}

export type UserSession = {
  __typename?: 'UserSession';
  /** User configuration */
  configuration?: Maybe<UserConfiguration>;
  /** User creation date */
  creationDate: Scalars['Date']['output'];
  /** Customer name */
  customer?: Maybe<Scalars['String']['output']>;
  /** Token expiration date */
  expiresAt: Scalars['Date']['output'];
  /** Fleet name */
  fleet?: Maybe<Scalars['String']['output']>;
  /** Fleet ID */
  fleetId?: Maybe<Scalars['ID']['output']>;
  /** A fleet product packages */
  fleetPackages?: Maybe<Array<FleetProductPackages>>;
  /** Fleet segments */
  fleetSegments?: Maybe<Array<FleetSegment>>;
  /** Permitted list of operations */
  operations: Array<Scalars['String']['output']>;
  /** Role user */
  role?: Maybe<UserRole>;
  /** User status */
  status?: Maybe<UserStatus>;
  /** Tenant information */
  tenant?: Maybe<TenantInfo>;
  /** Auth token */
  token: Scalars['String']['output'];
};

export type UserSignInInput = {
  /** User email */
  email: Scalars['String']['input'];
  /** User password */
  password: Scalars['String']['input'];
};

export type UserSignInResult = {
  __typename?: 'UserSignInResult';
  /** User configuration */
  configuration?: Maybe<UserConfiguration>;
  /** User creation date */
  creationDate: Scalars['Date']['output'];
  /** Customer name */
  customer?: Maybe<Scalars['String']['output']>;
  /** Token expiration date */
  expiresAt: Scalars['Date']['output'];
  /** Fleet name */
  fleet?: Maybe<Scalars['String']['output']>;
  /** Fleet ID */
  fleetId?: Maybe<Scalars['ID']['output']>;
  /** A fleet product packages */
  fleetPackages?: Maybe<Array<FleetProductPackages>>;
  /** Fleet segments */
  fleetSegments?: Maybe<Array<FleetSegment>>;
  /** Permitted list of operations */
  operations: Array<Scalars['String']['output']>;
  /** Role user */
  role?: Maybe<UserRole>;
  /** User status */
  status?: Maybe<UserStatus>;
  /** Tenant information */
  tenant?: Maybe<TenantInfo>;
  /** Auth token */
  token: Scalars['String']['output'];
};

export type UserSignOutResult = {
  __typename?: 'UserSignOutResult';
  /** Error */
  error?: Maybe<Scalars['String']['output']>;
  /** Status of mutation */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INVITED = 'INVITED',
  NEW = 'NEW'
}

export type UserTenantObject = {
  __typename?: 'UserTenantObject';
  /** The ID of tenant */
  id: Scalars['ID']['output'];
  /** The name of tenant */
  name: Scalars['String']['output'];
  /** The title of tenant */
  title: Scalars['String']['output'];
  /** The type of tenant */
  type?: Maybe<TenantType>;
};

export type UserTypeObject = {
  __typename?: 'UserTypeObject';
  /** User created at */
  createdAt: Scalars['Date']['output'];
  /** User email address */
  email: Scalars['String']['output'];
  /** User full name */
  fullName: Scalars['String']['output'];
  /** User id */
  id: Scalars['ID']['output'];
  /** Indicates whether use details can be updated. */
  isEditable: Scalars['Boolean']['output'];
  /** User organization */
  organization: UserOrganizationObject;
  /** User role */
  role: UserRole;
  /** User status */
  status: UserStatus;
  /** User visibility scope */
  visibilityScope?: Maybe<Array<VisibilityScopeObject>>;
};

export type UserVisibilityScope = {
  /** Internal fleet ID at partner side */
  fleet?: InputMaybe<Scalars['String']['input']>;
  /** Location */
  location?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyCostsInput = {
  /** Costs ids that should be verified */
  costIds: Array<Scalars['ID']['input']>;
  /** Damage Report ID */
  damageReportId: Scalars['ID']['input'];
};

export type VerifyOtpInput = {
  email: Scalars['String']['input'];
  otp: Scalars['String']['input'];
};

export type VerifyOtpResult = {
  __typename?: 'VerifyOtpResult';
  remainingAttempts: Scalars['Float']['output'];
  success: Scalars['Boolean']['output'];
  /** Return user session info if success is true */
  userSession?: Maybe<UserSession>;
};

export type VisibilityScopeObject = {
  __typename?: 'VisibilityScopeObject';
  /** User's visibility scope location */
  location?: Maybe<Scalars['String']['output']>;
  /** User's visibility scope organization */
  name?: Maybe<Scalars['String']['output']>;
};

export type WelcomeScreenConfiguration = {
  __typename?: 'WelcomeScreenConfiguration';
  /** Enables/disables checkbox of driver's consent to camera access */
  isCameraAccessConsentRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Welcome screen options */
  welcomeMessage?: Maybe<WelcomeScreenTranslatedMessages>;
};

export type WelcomeScreenConfigurationInput = {
  /** Enables/disables checkbox of driver's consent to camera access */
  isCameraAccessConsentRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Welcome screen options */
  welcomeMessage?: InputMaybe<WelcomeScreenTranslatedMessagesInput>;
};

export type WelcomeScreenMessages = {
  __typename?: 'WelcomeScreenMessages';
  /** Introduction message */
  introduction?: Maybe<Scalars['String']['output']>;
  /** Welcome message */
  welcome?: Maybe<Scalars['String']['output']>;
};

export type WelcomeScreenMessagesInput = {
  /** Introduction message */
  introduction?: InputMaybe<Scalars['String']['input']>;
  /** Welcome message */
  welcome?: InputMaybe<Scalars['String']['input']>;
};

export type WelcomeScreenTranslatedMessages = {
  __typename?: 'WelcomeScreenTranslatedMessages';
  /** German welcome messages */
  de?: Maybe<WelcomeScreenMessages>;
  /** English welcome messages */
  en?: Maybe<WelcomeScreenMessages>;
  /** Spanish welcome messages */
  es?: Maybe<WelcomeScreenMessages>;
};

export type WelcomeScreenTranslatedMessagesInput = {
  /** German welcome messages */
  de?: InputMaybe<WelcomeScreenMessagesInput>;
  /** English welcome messages */
  en?: InputMaybe<WelcomeScreenMessagesInput>;
};
