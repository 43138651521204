import { AppFeatureType, DamageReportImageType } from 'src/api/globalTypes';

import { PhotoFlow } from './types';

export const REPAIRFIX_HOME_URL = 'https://motum.eu';

export const ROUTES = {
  // TRIVIAL REPORT
  ONBOARDING: 'onboarding',
  CAR_BODY_TYPES: 'car-body-types',
  PHOTO_ONBOARDING: 'photo-onboarding',
  CAR_IDENTIFICATION: 'car-identification',
  DAMAGE_TYPE: 'damage-type',
  CAR_PARTS: 'car-parts',
  DAMAGE_PHOTOS: 'damage-photos',
  DAMAGE_REASONS: 'damage-reasons',
  ACCIDENT_DAMAGED_OBJECT: 'accident-damaged-object',
  ACCIDENT_WILDLIFE: 'accident-wildlife',
  ACCIDENT_RESPONSIBILITY: 'accident-responsibility',
  ADDITIONAL_INFO: 'additional-info',
  ADDITIONAL_SERVICES: 'additional-services',
  REGISTRATION_DOCUMENT: 'registration-document',
  COCKPIT_PHOTO: 'cockpit-photo',
  DRIVER_INFORMATION: 'driver-information',
  BODYSHOP_DRIVER_INFORMATION: 'bodyshop-driver-information',
  DOCUMENT_PREVIEW: 'documents/:id',
  SCHEDULE_APPOINTMENT: 'schedule-appointment',
  OVERVIEW: 'overview',
  COMPLETED: 'completed',
  LOADING: 'loading',
  CAR_DAMAGES_OVERVIEW: 'car-damages-overview',

  // DAMAGE UPDATE FLOW

  DAMAGE_REPORT_UPDATE: 'damage-report-update',

  // ACCIDEND FLOW
  ACCIDENT: 'accident-with-other-car',
  ACCIDENT_WITHOUT_OTHER_CAR: 'accident-without-other-car',
  ACCIDENT_SCENE: 'accident-scene',
  ACCIDENT_DRIVER_INFORMATION: 'accident-driver-information',
  ACCIDENT_ADDITIONAL_QUESTIONS: 'accident-additional-questions',
  ACCIDENT_POLICE_REPORT: 'accident-police-report',
  ACCIDENT_INJURED_PERSON_INFORMATION: 'accident-injured-person-information',
  ACCIDENT_DOCUMENTS: 'accident-documents',
  ACCIDENT_CAR_CONDITION: 'accident-car-condition',
  SECRECY_OBLIGATION: 'secrecy-obligation',
  ACCIDENT_OVERVIEW: 'accident-overview',
  ACCIDENT_DAMAGE_REPORT_FLOW: 'accident-damage-report-flow',
  ACCIDENT_CAR_OVERVIEW_PHOTOS: 'accident-car-overview-photos',
  OTHER_ACCIDENT_PARTY: 'other-accident-party',
  SIMPLE_ACCIDENT: 'simple-accident',

  // SUB ROUTES
  DAMAGE_HISTORY: `history/:carPartType/:areaName?`,
  DETAILED_DAMAGE_AREA: 'detailed-damage-area/:areaName',

  // LEGAL
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  IMPRESSUM: 'impressum',
  BODYSHOP_TERMS_AND_CONDITIONS: 'bodyshop-conditions-and-terms',
  BODYSHOP_PRIVACY_POLICY: 'bodyshop-privacy',
  CANCELLATION_POLICY: 'cancellation-policy',

  // MENU
  MENU: 'menu',

  // REPORTER TYPE SELECT
  REPORTER_TYPE: 'reporter-type',

  // HOTLINE
  HOTLINE: 'hotline',

  // OTHER
  NOT_FOUND: 'not-found',
  ALREADY_REPORTED: 'already-reported',
  SESSION_EXPIRED: 'session-expired',
  LINK_EXPIRED: 'link-expired',
  REPORTING_SESSION_EXPIRED: 'reporting-session-expired',
  SESSION_LIMIT_EXCEEDED: 'session-limit-exceeded',
  FILE_LIMIT_EXCEEDED: 'file-limit-exceeded',
};

export const PATHS = {
  ROOT: '/',
};

export enum Orientation {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export enum ImageMimeTypes {
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
}

export const SUPPORTED_FORMATS = [
  ImageMimeTypes.IMAGE_JPG,
  ImageMimeTypes.IMAGE_JPEG,
  ImageMimeTypes.IMAGE_PNG,
];

// indicates that session initialized via code
export const IS_CODE_BASE_AUTH = 'hasAuth';

export const CODE_URL_PARAM_KEY = 'code';
export const DAMAGE_CODE_STORAGE_KEY = 'new_app_damage_code';
export const CAR_CODE_STORAGE_KEY = 'new_app_car_code';
export const IS_CAMERA_ACCESS_CONSENT = 'is_camera_access_consent';
export const MANAGER_CODE_KEY = 'manager_code';
export const APP_NAME = 'app_name';
export const SESSION_ID = 'session_id';

export const FLOW_URL_PARAM_KEY = 'flow';
export const FLOW_STORAGE_KEY = 'flow';
export const EXTENDED_PERMISSIONS_STORAGE_KEY = 'extendedPermissions';

export const INIT_PARAM_STORAGE_KEY = 'app_init_params';

export type ImageTypes = DamageReportImageType;
export const ImageTypes = DamageReportImageType;

export const PHOTO_FLOWS = {
  CAR_CONDITION: [ImageTypes.FRONT_LEFT_ANGLE, ImageTypes.REAR_RIGHT_ANGLE],
  DAMAGE_PHOTOS: [ImageTypes.LEFT_DIAGONAL_VIEW, ImageTypes.RIGHT_DIAGONAL_VIEW],
  ADDITIONAL_PHOTOS: [ImageTypes.ADDITIONAL],
  OTHER_PARTY_DRIVER_LICENSE: [ImageTypes.OTHER_PARTY_DRIVER_LICENSE],
  OTHER_PARTY_VEHICLE: [ImageTypes.OTHER_PARTY_VEHICLE],
  INTERIOR: [ImageTypes.INTERIOR_LEFT, ImageTypes.INTERIOR_RIGHT],
  DASHBOARD: [ImageTypes.DASHBOARD],
  POLICE_ACCIDENT_REPORT: [ImageTypes.POLICE_REPORT],
  DAMAGE_DOCUMENTS: [ImageTypes.ACCIDENT_REPORT],
  DAMAGED_OBJECT: [ImageTypes.DAMAGED_OBJECT],
  WILDLIFE_PROTECTION: [ImageTypes.WILDLIFE_PROTECTION_LICENSE_NUMBER],
  REGISTRATION_DOCUMENT: [ImageTypes.REGISTRATION_DOCUMENT],
  ACCIDENT_DOCUMENTS: [ImageTypes.ACCIDENT_DOCUMENT],
  ACCIDENT_SCENE: [ImageTypes.ACCIDENT_SCENE],
  CAR_OVERVIEW: [
    ImageTypes.VEHICLE_DRIVER_SIDE_VIEW,
    ImageTypes.VEHICLE_FRONT_SIDE_VIEW,
    ImageTypes.VEHICLE_REAR_SIDE_VIEW,
    ImageTypes.VEHICLE_PASSENGER_SIDE_VIEW,
  ],
};

export const PHOTO_FLOW_MAX_PHOTO_QUANTITY: Record<PhotoFlow, number> = {
  CAR_CONDITION: 2,
  DAMAGE_PHOTOS: 2,
  ADDITIONAL_PHOTOS: 10,
  OTHER_PARTY_DRIVER_LICENSE: 1,
  OTHER_PARTY_VEHICLE: 3,
  INTERIOR: 2,
  DASHBOARD: 1,
  POLICE_ACCIDENT_REPORT: 4,
  DAMAGE_DOCUMENTS: 4,
  DAMAGED_OBJECT: 10,
  WILDLIFE_PROTECTION: 2,
  REGISTRATION_DOCUMENT: 1,
  ACCIDENT_DOCUMENTS: 10,
  ACCIDENT_SCENE: 2,
  CAR_OVERVIEW: 4,
};

export const backgroundUploadImageTypes = [DamageReportImageType.REAR_SIDE_GENERAL];

export const DOCUMENT_IMAGE_TYPES = [
  DamageReportImageType.ACCIDENT_REPORT,
  DamageReportImageType.REGISTRATION_DOCUMENT,
  DamageReportImageType.ACCIDENT_DOCUMENT,
];

export const DEBOUNCE_TIME_MS = 300;

export const MAX_TEXTAREA_LENGTH_CHARACTERS = 700;

// must correlate with css $layout-max-width
export const VIEWPORT_MAX_WIDTH = 425;

export const MAX_IMAGE_WIDTH = 1024;
export const MAX_DOCUMENT_IMAGE_WIDTH = 2048;

export const DEFAULT_COMPRESSION_RATIO = 0.82;
export const DOCUMENT_IMAGE_COMPRESSION_RATIO = 0.9;

// quality can be an integer between 0 and 1
// https://developer.mozilla.org/ru/docs/Web/API/HTMLCanvasElement/toBlob
export const DEFAULT_IMAGE_QUALITY = 0.8;
export const EMAIL_REG_EXP_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REG_EXP_PATTERN = {
  ONE_CAPITAL_LETTER: /(?=.*[A-Z])/g,
  ONE_NUMBER: /(?=.*[0-9])/g,
  ONE_SPECIAL_CHARACTER: /(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~])/g,
};

export enum WebAppFlows {
  EXTERIOR = 'exterior',
  INTERIOR = 'interior',
  SERVICE_LIGHTS = 'service-lights',
  CHECKLIST = 'checklist',
  ACCIDENT = 'accident',
  DAMAGE_VERIFICATION = 'damage-verification',
  DAMAGE_MODIFY = 'damage-modify',
  MECHANICAL = 'mechanical',
  SIMPLE_ACCIDENT = 'simple-accident',
  EXTERIOR_DAMAGE = 'exterior-damage',
  DAMAGE_REPORT_UPDATE = 'damage-report-update',
  CAR_DAMAGES_OVERVIEW = 'car-damages-overview',
}

export const FlowAppFeatureMapping: Partial<{ [key in WebAppFlows]: AppFeatureType | true }> = {
  [WebAppFlows.EXTERIOR]: AppFeatureType.EXTERIOR_DAMAGE_REPORT,
  [WebAppFlows.INTERIOR]: AppFeatureType.INTERIOR_DAMAGE_REPORT,
  [WebAppFlows.SERVICE_LIGHTS]: AppFeatureType.SERVICE_LIGHTS_REPORT,
  [WebAppFlows.CHECKLIST]: AppFeatureType.CHECKLIST,
  [WebAppFlows.ACCIDENT]: AppFeatureType.ACCIDENT_REPORT,
  [WebAppFlows.DAMAGE_VERIFICATION]: AppFeatureType.SERVICE_LIGHTS_REPORT,
  [WebAppFlows.DAMAGE_MODIFY]: AppFeatureType.CHECKLIST,
  [WebAppFlows.MECHANICAL]: AppFeatureType.MECHANICAL_DAMAGE_REPORT,
  [WebAppFlows.SIMPLE_ACCIDENT]: AppFeatureType.SIMPLE_ACCIDENT_REPORT,
  [WebAppFlows.CAR_DAMAGES_OVERVIEW]: true,
  [WebAppFlows.DAMAGE_REPORT_UPDATE]: true,
};

export const YELLOW_COLOR = '#eead00';
export const RED_COLOR = '#ed523b';
export const RED_VALUE = 'red';
export const YELLOW_VALUE = 'yellow';

export const DATE_TIME_FORMAT = 'dd.MM.yyyy, HH:mm';

export enum ERROR_CODES {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export const ACTIVITY_EVENTS = [
  'load',
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
  'click',
  'scroll',
];

export const BUILD_ID_UPDATED_EVENT = 'build-id-updated';

export const INPUT_NUMERIC_PATTERN = '[0-9]*';

export const POC_NAVIGATION_TEMP_KEY = 'poc_navigation';

export const WIZARD_FLOW_NAME = {
  ACCIDENT: 'accident',
  ACCIDENT_ENVIRONMENT: 'accidentEnvironment',
  THEFT_VANDALISM: 'theftVandalism',
} as const;
export type WIZARD_FLOW_NAME = (typeof WIZARD_FLOW_NAME)[keyof typeof WIZARD_FLOW_NAME];

export const WIZARD_FLOW_PATH = {
  ACCIDENT_FLOW: 'accident-flow',
  ACCIDENT_ENVIRONMENT_FLOW: 'accident-environment-flow',
  THEFT_VANDALISM: 'theft-vandalism-flow',
};

export const CANCELABLE_SAGAS = {
  CAMERA_SAGA: 'cameraSaga',
} as const;

export const MODAL_ROOT_EL = document.getElementById('modal') as HTMLDivElement;
