import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';

import { ReportsSortBy } from 'src/api/globalTypes';

import {
  addNextPageDamages,
  resetDamageList,
  resetDamageListFilters,
  setDamageFilterStatusData,
  setDamageListData,
  setDamageListFilters,
  updateDamageListItem,
} from './actions';
import {
  DamageListFilters,
  DamageListFormFields,
  DamageListReducer,
  DamageReducer,
  DamageReportListItem,
  DamageListFilter,
} from '../../../types';

export const initialState: DamageReducer = {
  damageList: {
    isLoading: false,
    error: null,
    data: [],
    total: null,
    filters: {
      page: 1,
      sortBy: ReportsSortBy.STATUS,
      carId: '',
      [DamageListFormFields.STATUS]: [],
      [DamageListFormFields.TYPE]: [],
      [DamageListFormFields.LICENSE_PLATE]: '',
    },
    filterData: {
      damageStatusFilter: {
        isLoading: false,
        error: null,
        data: [],
      },
    },
  },
};

export const damageReducer = createReducer(initialState, {
  [setDamageListData.type]: (
    state: DamageReducer,
    action: PayloadAction<Partial<DamageListReducer>>,
  ) => {
    state.damageList = merge(state.damageList, action.payload);
  },
  [resetDamageList.type]: (state: DamageReducer) => {
    state.damageList.data = initialState.damageList.data;
  },
  [resetDamageListFilters.type]: (state: DamageReducer) => {
    state.damageList.filters = { ...initialState.damageList.filters };
  },
  [addNextPageDamages.type]: (
    state: DamageReducer,
    action: PayloadAction<Pick<DamageListReducer, 'total' | 'data' | 'filters'>>,
  ) => {
    const { data, total, filters } = action.payload;
    const updatedData = uniqBy([...state.damageList.data, ...data], 'id');
    const dataToMerge = {
      data: updatedData,
      total,
      filters,
    };

    state.damageList = merge(state.damageList, dataToMerge);
  },
  [setDamageListFilters.type]: (state: DamageReducer, action: PayloadAction<DamageListFilters>) => {
    state.damageList.filters = { ...state.damageList.filters, ...action.payload };
  },
  [updateDamageListItem.type]: (
    state: DamageReducer,
    action: PayloadAction<Partial<DamageReportListItem>>,
  ) => {
    const { id, damageInformation, carParts, ...restData } = action.payload;
    const itemToUpdate = state.damageList.data.find(item => item.id === id);

    if (itemToUpdate) {
      const updateItem = {
        ...itemToUpdate,
        ...restData,
        ...(damageInformation && { damageInformation }),
        ...(carParts && { carParts }),
      };

      const updatedList = state.damageList.data.map(item => {
        if (item.id === id) {
          return updateItem;
        }

        return item;
      });

      state.damageList.data = updatedList;
    }
  },
  [setDamageFilterStatusData.type]: (
    state: DamageReducer,
    action: PayloadAction<DamageListFilter>,
  ) => {
    state.damageList.filterData.damageStatusFilter = {
      ...state.damageList.filterData.damageStatusFilter,
      ...action.payload,
    };
  },
});
